<div class="">

  <label id="lbl-{{dtaQuestions.name}}" class="lbl lbl-question" >{{dtaQuestions.name}}</label>

  <div class="">
    <input type="email" class="presenter" id="{{dtaQuestions.name}}" name="{{dtaQuestions.name}}" placeholder="correo@ejemplo.com" value="{{dtaQuestions.answers}}">
  </div>

</div>


