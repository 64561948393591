import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { AlarmsService } from '../../../services/alarms.service';
import { AlarmFull } from '../../../models/alarm';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-alarm-view',
  templateUrl: './alarm-view.component.html',
  styleUrls: ['./alarm-view.component.css']
})
export class AlarmViewComponent implements OnInit {

  alarm: AlarmFull;
  logical_medical: String;

  constructor(
    private alarmsService: AlarmsService,
    private loadingService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private snackBar: MatSnackBar,
    private userService: UsersService
  ) {
    this.userService.setOnCreatePatient(false);
    this.loadingService.updateSpinnerState(true);
    this.activatedRoute.params.subscribe(
      params => this.alarmsService.show(params.id).subscribe(
        response => {
          this.loadingService.updateSpinnerState(false);
          this.alarm = response.resp![0];
          console.log(this.alarm);
        },
        error => {
          this.loadingService.updateSpinnerState(false);
          this.snackBar.open('Ocurrió un problema al cargar la alarma','ENTENDIDO',{ duration: 5000 });
        }
      )
    )
  }

  ngOnInit(): void {
    this.logical_medical = localStorage.getItem('logical_medical');
  }

  back(){
    this.location.back();
  }

  isSelected(option, options){
    return options && options.indexOf(option) != -1;
  }

  isQuestionSelected(question): boolean{
    return this.alarm.keywords.includes(question.keyword);
  }

  setCalification(type: string){
    this.calificationSelected = type;
  }

  calificationSelected = null;
  calificationComment = '';

  isCalificationSelected(type: string): boolean{
    return type == this.calificationSelected; 
  }

  save(){
    this.loadingService.updateSpinnerState(true);
    if(!this.calificationSelected) return this.snackBar.open('Debe seleccionar una valoración para esta alarma.','ENTENDIDO',{duration: 5000});
    this.alarmsService.update({
      status: 'Resuelta',
      calification: this.calificationSelected,
      comment: this.calificationComment,
      checked_date: new Date().valueOf()
    },this.alarm.id)
        .subscribe(
          response => {
            this.location.back();
            this.snackBar.open('Alarma Resuelta!', 'ENTENDIDO', {duration: 5000});
            this.loadingService.updateSpinnerState(false);
          }, error => {
            this.loadingService.updateSpinnerState(false);
            this.snackBar.open('Ha ocurrido un error al resolver alarma.', 'ENTENDIDO', {duration: 5000});
            console.log(error);
          }
        );
  }

}
