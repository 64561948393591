import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-external-authentication',
  templateUrl: './external-authentication.component.html',
  styleUrls: ['./external-authentication.component.css']
})
export class ExternalAuthenticationComponent {

  user: String;
  pass: String;
  id_customer: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  )
  {
    this.activatedRoute.params.subscribe(
      params => {
        this.user = params.user;
        this.pass = params.pass;
        this.id_customer = params.id_customer;
        
        this.authService.externalSignIn(this.user,this.pass,this.id_customer)
            .subscribe(
              (result: any) => {
                console.log(result);
                if(result.msg == 'Authorized'){
                  console.log(result);
                  this.createSession(result);
                }
                this.router.navigateByUrl('/rutaQueNoExiste'); //Artificio para que redireccione a la pagina que corresponda
              }
            );
      }
    );
  }

  createSession(user: any): void {
    localStorage.setItem('isMobile', 'yes');
    localStorage.setItem('id', user.id.toString());
    localStorage.setItem('name', user.name);
    localStorage.setItem('role', user.role);
    localStorage.setItem('email', user.email);
    localStorage.setItem('token', user.token);
    localStorage.setItem('intPhoneCodes', user.intPhoneCodes);
    localStorage.setItem('customer_id',user.idCustomer);
    localStorage.setItem('id_stablishment',user.id_stablishment);
  }

}
