

<!-- <div style="position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center; z-index: -9;">
 
    <div style="position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center; top: 32%; z-index: 1">
        <qrcode [qrdata]="myAngularxQrCode" [width]="widthQR" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
</div> -->


<!-- escritorio ui -->

<div *ngIf="uiDesktop" scroll=no>
    <div class="logoSmartPassport">
        <img style="width: 14%;" src="/assets/img/LogoSmartPassport.png" >    
    </div>
    
    <div *ngIf="myAngularxQrCode != ''" class="QrDiv">
        <qrcode  [qrdata]="myAngularxQrCode" [width]="widthQR" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    
    <div class="textDiv">
        <p style="font-size: 25px; color: rgb(255, 255, 255);">Escanéa el código QR con tu celular</p>
    </div>
    <mat-card (click)="reloadPage()"  class="RegenerarDiv"  >
        <h3 style="color: #053890;">Volver a consultar</h3>
    </mat-card>
    
    <div  style="z-index: -2; border-radius: 5mm;">
        <img style="width: 100%;  border-radius: 5mm;" src="/assets/img/QRBackGround.png" >
    </div>
</div>
<!-- Mobiles ui -->
<div *ngIf="!uiDesktop" scroll=no>
    <div class="logoSmartPassportMobile" >
        <img style="width: 54%;" src="/assets/img/LogoSmartPassport.png" >    
    </div>

    <div *ngIf="myAngularxQrCode != ''"  class="QrDivMobile">
        <qrcode  [qrdata]="myAngularxQrCode" [width]="widthQR" [errorCorrectionLevel]="'M'"></qrcode>
    </div>

    <mat-card (click)="reloadPage()"  class="RegenerarDivMovile">
        <h3 style="color: #053890;">Volver a consultar</h3>
    </mat-card>
    <div style="background: #053890; width: 100%; height: 100%; position: fixed; z-index: 10; margin-left: -5;"></div>
    
</div>

<div *ngIf="!uiDesktop" class="barMobile" scroll=no (click)="regresar()" [routerLink]="['/list-respondent-form']">    
    <p style="color: #053890;">Regresar</p>
</div>




  






<!-- <div *ngFor="let item of data">
     {{item.name}}
     <span [ngSwitch]="item.type">
     <p *ngSwitchCase="'Radio'">
        <mat-radio-group  *ngFor="let r of item.items">
            {{r.Q}}
            <mat-radio-button value = "r.Q"></mat-radio-button>    
        </mat-radio-group>
     </p>
     <p *ngSwitchCase="'Textarea'">
        <mat-form-field class="example-form-field">
            <mat-label></mat-label>
            <input matInput type="text" >         
        </mat-form-field>
     </p>
     <p *ngSwitchCase="'Dynamic'"> 
        <mat-radio-group  *ngFor="let r of item.items">
            {{r.Q}}
            <mat-radio-button value = "r.Q">
                <mat-form-field class="example-full-width">
                    <mat-label>Ingresar texto</mat-label>
                    <textarea matInput placeholder="Escribir aquí"></textarea>
                </mat-form-field>  
            </mat-radio-button>  
        </mat-radio-group>
     </p>
     </span>     
</div> -->
