import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';

@Injectable({
  providedIn: 'root'
})
export class CustomersFormsService {

  constructor( private http: HttpClient ) { }

  getCustForms(): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.custFrm}`);
  }

  getByIdCustForm(id): Observable<any> {
      return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getCustFrm}/${id}`);
  }

  getByIdCustHash(hash): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getDtaFrmHash}/${hash}`);
}

  createCustForm(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtCustFrm}`, body, {headers: headers});
  }
  createPeriodic(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.createPeriodic}`, body, {headers: headers});
  }

  updatePeriodic(data): Observable<any> {    
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(`${globalData.urls.rootURI}${globalData.urls.updCustFrm}`, body, {headers: headers});
  }
  
}
