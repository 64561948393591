import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FomrsService } from '../../services/fomrs.service';
import { Form } from '../../models/forms.model';

@Component({
  selector: 'app-preview-forms',
  templateUrl: './preview-forms.component.html',
  styleUrls: ['./preview-forms.component.css']
})
export class PreviewFormsComponent implements OnInit {

  public formData: Form;

  constructor(private formsServices: FomrsService, private rutaActiva: ActivatedRoute) {
    // this.getFormData();
  }

  ngOnInit(): void {
    const { id } = this.rutaActiva.snapshot.params;
    this.formsServices.getByIdForms(id).subscribe(
      response => {
        if (response.correct) {
          this.formData = response.resp[0];
        }
        //console.log(this.formData);
      },
      error => {
        //console.log(error);
      }
    );
  }

  getFormData = async () => {
    // const { id } = await this.rutaActiva.snapshot.params;
  }

}
