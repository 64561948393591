import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlarmSimple } from '../models/alarm';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';

@Injectable({
  providedIn: 'root'
})
export class AlarmsService {
  
  headers: any = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient
  ) { }

  register(alarm: AlarmSimple): Observable<any>{
    return this.http.post(`${globalData.urls.rootURI}alarms/`,alarm,this.headers);
  }
  
  show(id: Number): Observable<any>{
    return this.http.get(`${globalData.urls.rootURI}alarms/${id}`);
  }

  list(): Observable<any>{
    return this.http.get(`${globalData.urls.rootURI}alarms/specialist_id/${localStorage.getItem('id')}`);
  }
  
  update(updates: any, id: Number): Observable<any>{
    return this.http.put(`${globalData.urls.rootURI}alarms/${id}`,updates,this.headers);
  }

}
