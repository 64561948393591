import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  spinnerSubscription: Subscription;

  constructor(
    private spinnerService: SpinnerService
  ) {
    this.spinnerSubscription = this.spinnerService.updates.subscribe(
      (isActive: boolean) => this.isLoading = isActive
    );
  }

  ngOnDestroy(): void {
    this.spinnerSubscription.unsubscribe();
  }

  ngOnInit(): void {
  }

}
