import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { UsersService } from './services/users.service';
import { Subscription } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { SwUpdate } from '@angular/service-worker';
import { SpinnerService } from './services/spinner.service';
import { GeneralService } from './services/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  public dataSesion: any;
  public session: boolean;
  public urlActual: any;
  promptEvent

  routeFlagSubscription: Subscription;
  isOnPatient: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UsersService,
    private messaging: AngularFireMessaging,
    private generalService: GeneralService,
    private spinnerService: SpinnerService
  )
  {
  
    this.routeFlagSubscription = this.userService.routeFlag.subscribe(
      value => {
        this.isOnPatient = value;
      }, error => console.log(error)
    );
    this.generalService.loggin.subscribe(
      () => {
        this.loggedIn();
      }
    );
    
  }


    
    ngOnDestroy(): void {
      this.routeFlagSubscription.unsubscribe();
    }
    
    ngOnInit(): void {
      this.loggedIn();
    // window.addEventListener ('beforeinstallprompt', event => { 
    //   this.promptEvent = event; 
    //   console.log('this.prompt',this.promptEvent)
    // });

    // this.swUpdate.versionUpdates.subscribe(
    //   update => window.location.reload(),
    //   error => console.log(error)
    // );

    this.requestPermission();
  }

  ngAfterContentInit(): void {
    this.loggedIn();
  }

  loggedIn(): void {
    this.session = this.authService.loggedIn();
    this.urlActual = window.location.pathname;
  
  }

  requestPermission(){
    this.messaging.requestPermission.subscribe(
      result => {
        localStorage.setItem('notifications',result);
        this.listenNotifications();
      },error => console.log(error)
    );
  }

  listenNotifications(){
    if(localStorage.getItem('notifications') == 'granted'){
      this.messaging.messages.subscribe(
        message => {
          console.log(message);
        },error => console.log(error)
      );
    }
  }

}
