<div *ngIf="dataSesion.logical_medical !='preconsult'">
    <p class="titel_form">Tablero General</p>


<div *ngIf="dataSesion.logical_medical =='urology'">
    <p *ngIf="tab!='Citas'" id="txtHelpZoom" class="">Información de pacientes</p>
</div>
<div *ngIf="dataSesion.logical_medical !='urology' ">
    <p *ngIf="tab!='Citas'" id="txtHelpZoom" class="">Para agendar cita Zoom haga clic en el nombre del paciente</p>
</div>

<section id="tabs" class="project-tab" >
    <div class="">
        <div class="">
            <div class="" >
                <mat-tab-group *ngIf="dataSesion.logical_medical !='urology'" (selectedTabChange)="showTab($event)">
                    <mat-tab label="Pacientes por ver">
                        <div class="tab-pane fade show active tab" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <ag-grid-angular style="width: 100%; height: 500px;  overflow: scroll " class="ag-theme-alpine" [rowData]="dataFilter" [columnDefs]="headersFilter" rowSelection="multiple" [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate"></ag-grid-angular>
                        </div>
                    </mat-tab>
                    <mat-tab label="Citas">
                        <div class="tab-pane fade show tab" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <ag-grid-angular style="width: 100%; height: 550px;  overflow: scroll" class="ag-theme-alpine" [rowData]="dataMeeting" [columnDefs]="headersMeeting" rowSelection="multiple"></ag-grid-angular>
                        </div>
                    </mat-tab>
                    <mat-tab label="Reagendar">
                        <div class="tab-pane fade show tab" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <ag-grid-angular style="width: 100%; height: 550px;  overflow: scroll" class="ag-theme-alpine" [rowData]="dataReschedule" [columnDefs]="headersReschedule" rowSelection="multiple" [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate"></ag-grid-angular>
                        </div>
                    </mat-tab>
                    <mat-tab label="Diagnosticado">
                        <div class="tab-pane fade show tab" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <ag-grid-angular style="width: 100%; height: 550px;  overflow: scroll" class="ag-theme-alpine" [rowData]="dataDiagnosed" [columnDefs]="headersDiagnosed" rowSelection="multiple" [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate"></ag-grid-angular>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <mat-tab-group *ngIf="dataSesion.logical_medical == 'urology'" (selectedTabChange)="showTab($event)">
                    <mat-tab label="Pendientes IPSS">
                        <div class="tab-pane fade show active tab" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <ag-grid-angular style="width: 100%; height: 500px;  overflow: scroll " class="ag-theme-alpine" [rowData]="dataFilterOnhold" [columnDefs]="headerEspecialistFirstTab" rowSelection="multiple" [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate"></ag-grid-angular>
                        </div>
                    </mat-tab>
                    <mat-tab label="Pendientes flujometría">
                        <div class="tab-pane fade show active tab" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <ag-grid-angular style="width: 100%; height: 500px;  overflow: scroll " class="ag-theme-alpine" [rowData]="dataFilterAnswered" [columnDefs]="headerEspecialistRespondent" rowSelection="multiple" [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate"></ag-grid-angular>
                        </div>
                    </mat-tab>
                    <mat-tab label="Pendientes diagnosticar">
                        <div class="tab-pane fade show tab" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <ag-grid-angular style="width: 100%; height: 550px;  overflow: scroll" class="ag-theme-alpine" [rowData]="dataDiagnosticRespondent" [columnDefs]="headerDiagnostedRespondent" rowSelection="multiple"></ag-grid-angular>
                        </div>
                    </mat-tab> 
                    <mat-tab label="Usabilidad">
                        <div class="tab-pane fade show tab" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <ag-grid-angular style="width: 100%; height: 550px;  overflow: scroll" class="ag-theme-alpine" [rowData]="dataUsabalityRespondent" [columnDefs]="headerUsability" rowSelection="multiple"></ag-grid-angular>
                        </div>
                    </mat-tab>                      
                </mat-tab-group> 
            </div>
        </div>

    </div>
</section>
</div>