import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Console } from 'console';
import { Router } from '@angular/router';
import { MeetingService } from '../../../services/meeting.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-meetings',
  templateUrl: './modal-meetings.component.html',
  styleUrls: ['./modal-meetings.component.css']
})
export class ModalMeetingsComponent implements OnInit {
  @Output() OpenMdlMeeting = new EventEmitter();
  @Output() CloseMdlMeeting = new EventEmitter();
  @Output() SendMeeting = new EventEmitter();
  @Output() SendMeetingRescheduled = new EventEmitter();
  
  @Output() CloseMdlConfMeeting = new EventEmitter();

  public dataConfMeet: any;

  public meetingForm: any;
  public mdlMeeting: any;
  public Sendbtn =false;
  public msgError = false;
  public minDate
  public maxDate;
  public minHour;
  public btnReag ;
  public hourDate;
  public url;

  constructor(private modalService: NgbModal,private router: Router, private meetingService: MeetingService,    
    public dialogRef: MatDialogRef <ModalMeetingsComponent>,@Inject(MAT_DIALOG_DATA)public message:string) {
      dialogRef.disableClose = true;

       var MyDateMAx = new Date();
       var MyDateString;       
       MyDateMAx.setDate(MyDateMAx.getDate() + 3);       
       MyDateString = MyDateMAx.getFullYear() + '-' + ('0' + (MyDateMAx.getMonth()+1)).slice(-2) + '-' + ('0' + MyDateMAx.getDate()).slice(-2);

       var MyDateMin = new Date();
       var MyDateStringMin;
       
       MyDateMin.setDate(MyDateMin.getDate() +0);      
       MyDateStringMin =  MyDateMin.getFullYear() + '-' + ('0' + (MyDateMin.getMonth()+1)).slice(-2) + '-' +('0' + MyDateMin.getDate()).slice(-2);
       
       var time = new Date();
       var hour = time.getHours() + ':' + time.getMinutes();

    this.minHour = hour
    this.minDate = MyDateStringMin;
    this.maxDate = MyDateString;    

    ////console.log('this.maxDate',hour)
    this.meetingForm = {
      date:  null,
      time: null,
      appointment: 'zoom'
    } 
  }
  
  ngOnInit(): void {
    // //console.log('this.msgError',this.msgError)       
     this.update();  
     this.url = window.location.href;  
  }
  
  closeDialog(){
    this.dialogRef.close();
  }

  update(){
    setTimeout(()=>{  
      if(this.meetingService.TabData=='1'){
        this.btnReag = false;
      }
      if(this.meetingService.TabData=='Reagendar'){
        this.btnReag = true;
      }    
      if(this.meetingService.TabData=='error'){
        this.msgError = true;
        this.Sendbtn = false;
      }        
     this.update(); 
    
    }, 1000);   
  }

  sendMeeting(event): void {    
    //console.log('this.msgError',this.msgError);     
    if (this.meetingForm.date != null && this.meetingForm.time != null  && this.meetingForm.appointment != null) {
      this.Sendbtn = true; 
      //console.log(this.meetingForm.appointment)
      this.dialogRef.close(this.meetingForm );
    } else {
      document.getElementById('msgform').innerHTML = 'Todos los datos son requeridos';
    }
    //console.log('this.meetingForm',this.meetingForm);
  }

  sendMeetingRescheduled(event): void {        
    //console.log('ReagendamientoBtn')    
    if (this.meetingForm.date != null && this.meetingForm.time != null  && this.meetingForm.appointment != null) {
      this.Sendbtn=true; 
      //console.log(this.meetingForm.appointment ) 
      this.dialogRef.close(this.meetingForm );
    } else {
      document.getElementById('msgform').innerHTML = 'Todos los datos son requeridos';
    }
  }

  arreglar(){
    let TExto= this.meetingForm.date
    let textoMoificado = TExto.slice(3);
    //console.log('this.msgError',textoMoificado)     
  }

  closeMdlConfMeeting(): void {  
    this.CloseMdlConfMeeting.emit();
  }  
}
