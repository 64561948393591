<div class="page-content">
    <div class="header">
        <h2>Alarmas Formularios Pacientes</h2>
    </div>
    
    <mat-tab-group mat-stretch-tabs>
        <mat-tab label="PENDIENTES">
            <div class="card-list" *ngIf="this.pendingAlarms.length">
                <app-alarm-card [alarm]="alarm" *ngFor="let alarm of pendingAlarms"></app-alarm-card>
            </div>
            <div class="message-view" *ngIf="!this.pendingAlarms.length">
                <h2>No tiene alarmas por Atender!</h2>
            </div>
        </mat-tab>
    
        <mat-tab label="RESUELTAS">
            <div class="card-list">
                <app-alarm-card [alarm]="alarm" *ngFor="let alarm of resolvedAlarms"></app-alarm-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>