import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.css']
})
export class ModalMessageComponent implements OnInit {
  @Input() public titleMessage: string;
  @Input() public message: string;
  @Output() CloseMdlMessage = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  closeMdlMessage(): void {
    this.CloseMdlMessage.emit();
  }

}
