import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  loggin: EventEmitter<void> = new EventEmitter();

  constructor() { }

  refreshLoginState(){
    this.loggin.emit();
  }

}
