import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { AuthService } from '../../services/auth.service';
import { ChartType, ChartOptions } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { Console } from 'console';
import { element } from 'protractor';
import { RespondentFormsService } from '../../services/respondent-forms.service';




@Component({
  selector: 'app-patient-consults',
  templateUrl: './patient-consults.component.html',
  styleUrls: ['./patient-consults.component.css']
})
export class PatientConsultsComponent implements OnInit {
  public CustomerID;
  public dataSesion: any;
  public clinicsData:any ;
  public barChartLabels;
  public selected;
  public normalData:any;
  public dataFilter:any;
  public data = [];
  public dataForms;
  public userDataFIlter;
 type='PieChart';
 title = 'Pacientes';
 columnNames = ['Establecimiento', 'Porcentaje'];
 options = {
  backgroundColor: '#f8f9fa',
  is3D: true
};

width = 500;
height =300;


  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];
 
  

  constructor(private userService:UsersService,private authService: AuthService, private respondentFormsService: RespondentFormsService) {
    this.dataSesion = this.authService.getDataSesion();
    
   }

  ngOnInit(): void {        
    let arrayStablishments= [];
    this.CustomerID =this.dataSesion.customerId;
    this.GetDataUsers(this.CustomerID);
    this.clinicsData =  JSON.parse(localStorage.getItem('clinics'));
    //////console.log('clinicas',this.clinicsData.clinics); 
    for(let i=0; i <this.clinicsData.clinics.length;i++){
      //////console.log('recorrido',this.clinicsData.clinics[i].name);
      arrayStablishments.push(this.clinicsData.clinics[i].name);
    }
    this.barChartLabels = arrayStablishments;
    //////console.log('clinicas push',this.barChartLabels); 
  }
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
};

  
  public barChartType = 'bar';
  public barChartLegend = true;
  
  public barChartData;
  public async GetDataUsers(id){
  await  this.userService.getGetUsersDiagnosed(id).subscribe(res=>{
      //////console.log('respuedta get data user only for diagnosed',res);
      this.normalData= res.resp;
      this.getRespForms();    

    });
  }

  



  consultPatient(data){
    //////console.log('busqueda de pacientes',data);
    if(data!= 'todos'){     
      let filter = this.userDataFIlter.filter(element=>element.id_stablishment==data);
      filter.map(function(dato){
        //////console.log(' this.dataFilter', dato);
        let clinics=JSON.parse(localStorage.getItem('clinics'));

        for(let i=0; i <clinics.clinics.length;i++){
          const element= clinics.clinics[i];
          if(element.code== dato.id_stablishment){              
            dato['name_stablishment']=element.name;
          }
        }
        
        return dato;
      });
      this.dataFilter = filter;
      //////console.log(' this.dataFilter', this.dataFilter);
    }else{
       // this.dataFilter      
      let filter = this.userDataFIlter;
      filter.map(function(dato){
        //////console.log(' this.dataFilter', dato);
        let clinics=JSON.parse(localStorage.getItem('clinics'));

        for(let i=0; i <clinics.clinics.length;i++){
          const element= clinics.clinics[i];
          if(element.code== dato.id_stablishment){              
            dato['name_stablishment']=element.name;
          }
        }        
        return dato;
      });
      this.dataFilter = filter;
      //////console.log(' this.dataFilter', this.dataFilter);    
    
    }
  }

  getRespForms(): void {
    this.respondentFormsService.getRespForms(localStorage.getItem('customer_id')).subscribe(
      resp => {
        if (resp.correct) {                 
             this.dataForms = resp.resp.filter(element => element.customer_id == localStorage.getItem('customer_id') && element.status == "diagnosed" );
             //////console.log('dataforms',this.dataForms);       
             let filterByOnlyPatients =this.normalData.filter(element=>element.role=="respondent");
             let dataUsersDiagnoseds = filterByOnlyPatients.filter(element =>  element.user_id  ==  this.dataForms.respondents_id)
             //////console.log('dataUsersDiagnoseds',dataUsersDiagnoseds);
             this.userDataFIlter =  dataUsersDiagnoseds;             
             let filter01 =   dataUsersDiagnoseds.filter(element => element.id_stablishment == '01' && element.user_id == this.dataForms.respondents_id );
             let filter02 =   dataUsersDiagnoseds.filter(element => element.id_stablishment == '02'&& element.user_id == this.dataForms.respondents_id);
             let filter03 =   dataUsersDiagnoseds.filter(element => element.id_stablishment == '03'&& element.user_id == this.dataForms.respondents_id);
             //////console.log('respuedta get data filter01',filter01.length);
             //////console.log('respuedta get data filter02',filter02.length);
             //////console.log('respuedta get data filter03',filter03.length);           
                 this.data  = [
           [this.barChartLabels[0], filter01.length] ,[this.barChartLabels[1],filter02.length],[this.barChartLabels[2],filter03.length ],
          ];          
        }
      },
      error => {
        //////console.log(error);
      }
    );
  }


  


}
