<div *ngIf="session && urlActual != '/login'; then wrapperBlock else loginBlock"></div>

<app-loading-screen></app-loading-screen>

<ng-template #wrapperBlock>
  <div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content -->
    <div id="content" [ngClass]="{'content-new-patient': isOnPatient}">
      <app-navbar></app-navbar>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #loginBlock>
  <router-outlet></router-outlet>
</ng-template>


