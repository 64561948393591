import { Component, OnInit } from '@angular/core';
import { SettingFomrsService } from '../../services/settings-fomrs.service';
import { SettingForm } from '../../models/setting_form.model';

@Component({
  selector: 'app-list-setting-forms',
  templateUrl: './list-setting-forms.component.html',
  styleUrls: ['./list-setting-forms.component.css']
})
export class ListSettingFormsComponent implements OnInit {

  public setFormData: SettingForm[];

  constructor(private settingFomrsService: SettingFomrsService) { }

  ngOnInit(): void {
    this.settingFomrsService.getSetForms().subscribe(
      response => {
        if (response.correct) {
          this.setFormData = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

}
