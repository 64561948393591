import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FomrsService } from 'src/app/services/fomrs.service';

@Component({
  selector: 'app-audio-data-view',
  templateUrl: './audio-data-view.component.html',
  styleUrls: ['./audio-data-view.component.css']
})
export class AudioDataViewComponent {
  chartTitle: string = 'Onda de sonido';
  audioList: any[];

  constructor(
    private formsService: FomrsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formsService.getAudioData(data.name).subscribe(
      (result: any) => {
        if(result.correct){
          this.audioList = result.resp;

          

        }
      },error => {
        console.log(error);
      }
      )
  }
    
  selectedAudioData: any;
  
  selectAudio(audioData){
    this.selectedAudioData = audioData;
    this.simplificada[0].data = this.selectedAudioData.simple_full_envelope;
    this.fullLabels = new Array(this.selectedAudioData.simple_full_envelope.length).fill('');
    
    // this.simplificada[1].data = this.selectedAudioData.simple_micturition_envelope;

    this.simplificada[2].data = this.simplificada[0].data.map(value => value * -1);
    // this.simplificada[3].data = this.simplificada[1].data.map(value => value * -1);
  }

  simplificada = [
    {
      data: [],
      label: 'Audio completo',
      backgroundColor: 'rgba(0,255,0,.25)',
      borderColor: 'rgba(0,0,0,0)',
      pointBackgroundColor: 'rgba(0,0,0,0)',
      pointBorderColor: 'rgba(0,0,0,0)',
      pointHoverBackgroundColor: 'rgba(0,0,0,0)',
      pointHoverBorderColor: 'rgba(0,0,0,0)',
      fill: 'origin',
    },
    // {
    //   data: [],
    //   label: 'Audico micción',
    //   backgroundColor: 'rgba(0,0,255,.25)',
    //   borderColor: 'rgba(0,0,0,0)',
    //   pointBackgroundColor: 'rgba(0,0,0,0)',
    //   pointBorderColor: 'rgba(0,0,0,0)',
    //   pointHoverBackgroundColor: 'rgba(0,0,0,0)',
    //   pointHoverBorderColor: 'rgba(0,0,0,0)',
    //   fill: 'origin',
    // },
    {
      data: [],
      label: 'Audio completo Inverso',
      backgroundColor: 'rgba(0,255,0,.25)',
      borderColor: 'rgba(0,0,0,0)',
      pointBackgroundColor: 'rgba(0,0,0,0)',
      pointBorderColor: 'rgba(0,0,0,0)',
      pointHoverBackgroundColor: 'rgba(0,0,0,0)',
      pointHoverBorderColor: 'rgba(0,0,0,0)',
      fill: 'origin',
    },
    // {
    //   data: [],
    //   label: 'Audico micción Inverso',
    //   backgroundColor: 'rgba(0,0,255,.25)',
    //   borderColor: 'rgba(0,0,0,0)',
    //   pointBackgroundColor: 'rgba(0,0,0,0)',
    //   pointBorderColor: 'rgba(0,0,0,0)',
    //   pointHoverBackgroundColor: 'rgba(0,0,0,0)',
    //   pointHoverBorderColor: 'rgba(0,0,0,0)',
    //   fill: 'origin',
    // }
  ];
  
  fullLabels = [];

  roundFloat(float){
    return Math.round(float * 100) / 100;
  };

  lineChartOptions = {
    title: {display: true, text: "Gráfico del audio", fontSize: 18},
    animation: {
      duration: 0 // general animation time
    },
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, 
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)"
        },
      }],
      yAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)"
        }
      }]
    },
    legend: { display: true, position: 'right' },
  };

}
