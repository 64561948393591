<mat-card [ngClass]="{'resuelta': this.alarm?.status == 'Resuelta'}" [class]="this.alarm?.calification">

    <div class="header-alarm-view">
        <div class="info-patient-alert">
            <h2>{{this.alarm?.pat_name != 'undefined' ? this.alarm?.pat_name : this.alarm?.pat_name_code}}</h2>
            <p>{{this.alarm?.date | date : 'dd/MM/YYYY HH:mm'}}</p>
        </div>
    
        <div class="detail-section-alarm">
            <div class="keywords-alarm">{{this.alarm?.keywords}}</div>
            <div class="form-name-alarm">Formulario {{this.alarm?.form_name}}</div>
            <div class="forms-type">({{this.alarm?.prev_form_data ? 'Formularios Consecutivos' : 'Formulario Único/Primero'}})</div>
        </div>
    
        <div class="action-section-alarm">
            <button *ngIf="logical_medical != 'surgiai'" mat-button [matMenuTriggerFor]="menu"><mat-icon>phone_enabled</mat-icon></button>
            <mat-menu #menu="matMenu">
                <a [href]="'tel:'+ alarm?.pat_phone_code + alarm?.pat_phone" mat-menu-item>{{alarm?.pat_phone_code}}{{alarm?.pat_phone}}</a>
            </mat-menu>
            <button mat-icon-button (click)="back()"><mat-icon>close</mat-icon></button>
        </div>
    </div>
    
    <div class="forms-view" [ngClass]="{'historic': this.alarm?.prev_form_data}">
        <div *ngIf="this.alarm?.prev_form_data">
            <div *ngFor="let section of alarm?.prev_form_data">
                <h2>{{section.name}}</h2>
                <div *ngFor="let pages of section.pages">
        
                    <div class="question" [ngClass]="{'marked-question': isQuestionSelected(question)}" *ngFor="let question of pages">
                        <div *ngIf="question.type == 'Radio'">
                            <h3 class="question-title">{{question.id}}. {{question.name}}</h3>
        
                            <div *ngFor="let option of question.items">
                                <input class="radio-dyn-form" [checked]="isSelected(option, question.answers)" type="radio" [disabled]="!isSelected(option, question.answers)"> {{option}}
                            </div>
                        </div>
                    </div>
        
                </div>
            </div>
        </div>
        <div *ngFor="let section of alarm?.form_data">
            <h2>{{section.name}}</h2>
            <div *ngFor="let pages of section.pages">
    
                <div class="question" [ngClass]="{'marked-question': isQuestionSelected(question)}" *ngFor="let question of pages">
                    <div *ngIf="question.type == 'Radio'">
                        <h3 class="question-title">{{question.id}}. {{question.name}}</h3>
    
                        <div *ngFor="let option of question.items">
                            <input class="radio-dyn-form" [checked]="isSelected(option, question.answers)" type="radio" [disabled]="!isSelected(option, question.answers)"> {{option}}
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div *ngIf="this.alarm?.status != 'Resuelta'" class="calification-section">
        <h2>Valoración de la Alerta</h2>
    
        <div class="calification-botonera">
            <button class="dissatisfied" mat-raised-button (click)="setCalification('DESFAVORABLE')" [ngClass]="{'no-calification-selected': !isCalificationSelected('DESFAVORABLE')}" ><mat-icon>sentiment_dissatisfied</mat-icon> DESFAVORABLE</button>
            <button class="neutral" mat-raised-button (click)="setCalification('EVOLUCIÓN TÓRPIDA')" [ngClass]="{'no-calification-selected': !isCalificationSelected('EVOLUCIÓN TÓRPIDA')}" ><mat-icon>sentiment_neutral</mat-icon> EVOLUCIÓN TÓRPIDA</button>
            <button class="satisfied" mat-raised-button (click)="setCalification('FAVORABLE')" [ngClass]="{'no-calification-selected': !isCalificationSelected('FAVORABLE')}" ><mat-icon>sentiment_satisfied</mat-icon> FAVORABLE</button>
        </div>
        <mat-form-field class="comment-input" appearance="outline">
            <mat-label>Comentario</mat-label>
            <textarea [(ngModel)]="this.calificationComment" matInput></textarea>
        </mat-form-field>
    </div>

    <div *ngIf="this.alarm?.status == 'Resuelta'" class="calification-section-resolved">
        <h3>Valoración de la Alerta: <span [class]="this.alarm.calification">{{this.alarm.calification}}</span></h3>
        <p *ngIf="this.alarm.comment">{{this.alarm.comment}}</p>
        <small>Alarma Resuelta: {{this.alarm.checked_date | date: 'dd/MM/YYY'}}</small>
    </div>


    <mat-divider *ngIf="this.alarm?.status != 'Resuelta'"></mat-divider>
    <div *ngIf="this.alarm?.status != 'Resuelta'" class="action-buttons flex">
        <div class="fill-remaining-space"></div>
        <button class="primary-button" (click)="save()" mat-raised-button>GUARDAR</button>
    </div>

</mat-card>