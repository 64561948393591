<button type="button" class="close" aria-label="Close" (click)="closeModal()" style=" position: absolute;
margin-left: auto;
margin-right: auto;
left: 20%;
right: 0%;
text-align: center; z-index: 99999999999;">
    <span aria-hidden="true">&times;</span>
 </button>

<div class="container-fluid">
    <div style="background: #E6E6E600; z-index: 999; opacity: 0.3;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center; width: 400px; height: 500px; border-radius: 5mm;">
    </div>

    <div class="container-fluid">
        <div style="position: absolute; width: 950px; margin-top: -1%; height: 500px;background: #FFFFFF00; z-index: 999999; opacity: 0.3; margin-left: -4%;">
        </div>

        <div class="row">

            <div class="card col-sm-12">

                <div class="overflow-auto card-body">
                    <p class="titel_form">{{respondentForm.name}}</p>

                    <form class="respondent_form" id="editRespondentForm" #editRespondentForm="ngForm" (ngSubmit)="onSubmit()">

                        <section *ngFor="let segment of structure; let i = index;">

                            <div *ngFor="let page of segment.pages; let j = index;">

                                <div *ngIf="i == 0 && j == 0; then firstDisplay else followingDisplay"></div>

                                <ng-template #firstDisplay>
                                    <div class="tab tab0">
                                        <p class="desc_form">{{respondentForm.description}}</p>
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>

                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question" [index]="j"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <!-- <app-checkbox [dtaQuestions]="question"></app-checkbox> -->
                                                        <div class="">

                                                            <label id="lbl-{{question.name}}" class="lbl lbl-question" >{{question.name}}</label>
                                                          
                                                              <label class="container" *ngFor="let item of question.items;">
                                                                <label class="form-check-label check_label" for="{{question.name}}">{{item}}</label>
                                                                <input type="checkbox" class="presenter" name="{{question.name}}" id="{{question.name}}-{{item}}" value="{{item}}">
                                                                <span class="checkmark"></span>
                                                              </label>
                                                          
                                                          
                                                          </div>
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'">
                                                        <app-file [dtaQuestions]="question"></app-file>
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">                                                        
                                                      
                                                        <app-text-number [dtaQuestions]="question" [index]="j"></app-text-number>
                                                  
                                                
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>

                                                </ul>

                                            </li>

                                        </ul>

                                    </div>

                                </ng-template>

                                <ng-template #followingDisplay>
                                    <div class="tab">
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>

                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <app-checkbox [dtaQuestions]="question"></app-checkbox>                                                 
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'">
                                                        <app-file [dtaQuestions]="question"></app-file>
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">                                                        
                                                       
                                                            <app-text-number [dtaQuestions]="question" [index]="j"></app-text-number>
                                                      
                                                    
                                              
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>
                                                </ul>

                                            </li>

                                        </ul>

                                    </div>
                                </ng-template>

                            </div>

                        </section>

                        <div style="text-align:center;margin-top:40px;">
                            <span class="step" *ngFor="let pag of pages;"></span>
                        </div>

                    </form>
                </div>              

            </div>

 

            <!-- <div class="card col-sm-12">
                <div class="form-group">
                    <label for="presumption" style="padding-top: 20px;">Resultado:</label>
                    <h1 class="display-2" style="margin: auto;">{{respondentForm.result_patient}}</h1>

                </div>
            </div> -->
         


        </div>
        <div>
            <h1>
                {{respondentForm.total_weight_patient}}
            </h1>
        </div>

    </div>
</div>