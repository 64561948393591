import { Directive, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appFormBuilder]'
})
export class FormBuilderDirective {

  @Input() appFormBuilder: any;
  @Output() question: any;


  constructor( ) {




  }

}
