<h2 mat-dialog-title>Resultados de audio del paciente {{data.name}}</h2>

<mat-dialog-content>

    <mat-spinner *ngIf="!audioList" class="audio-loading"></mat-spinner>

    <div *ngIf="audioList?.length">

        <table class="audio-table">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Fecha</th>
                    <th>Acción</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let audioData of audioList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{audioData.audio_name}}</td>
                    <td><button mat-raised-button (click)="selectAudio(audioData)">Ver</button></td>
                </tr>
            </tbody>
        </table>

        <mat-divider></mat-divider>

        <div *ngIf="selectedAudioData" class="mt-3">
            <div class="row">
                <div class="col-4 audio-property">
                    <span>Calidad del Audio</span>
                    <div [ngSwitch]="selectedAudioData.audio_quality">{{selectedAudioData.audio_quality}} - 
                        <span *ngSwitchCase="4">Buena</span>
                        <span *ngSwitchCase="3">Final de la micción no es exacto</span>
                        <span *ngSwitchCase="2">Inicio de la micción no es exacto</span>
                        <span *ngSwitchCase="1">Ni el inicio ni el final de la micción son exactos</span>
                    </div>
                </div>
                <div class="col-4 audio-property">
                    <span>Clasificación del Audio</span>
                    <div>{{selectedAudioData.sound}}</div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-4 audio-property">
                    <span>Inicio de la micción</span>
                    <div>{{roundFloat(selectedAudioData.micturition_start)}}s</div>
                </div>
                <div class="col-4 audio-property">
                    <span>Fin de la micción</span>
                    <div>{{roundFloat(selectedAudioData.micturition_end)}}s</div>
                </div>
                <div class="col-4 audio-property">
                    <span>Duración de la micción</span>
                    <div>{{roundFloat(selectedAudioData.micturition_time)}}s</div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <canvas baseChart class="chart"
                
                [datasets]="simplificada"
                [labels]="fullLabels"
                [options]="lineChartOptions"
                chartType="line">
            </canvas>
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>CERRAR</button>
</mat-dialog-actions>
