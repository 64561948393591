import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { globalData } from '../../global/global_data';
// import { element } from 'protractor';
import { SpinnerService } from '../../services/spinner.service';
import { LoaderComponent } from '../_presenters/loader/loader.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomersFormsService } from '../../services/customers-forms.service';
import { AuthService } from 'src/app/services/auth.service';
import { FomrsService } from 'src/app/services/fomrs.service';

@Component({
  selector: 'app-edit-respondent-form',
  templateUrl: './edit-respondent-form.component.html',
  styleUrls: ['./edit-respondent-form.component.css']
})
export class EditRespondentFormComponent implements OnInit {
  public dataSesion: any;
  public idForm: any;
  private form: any;
  private currentTab: number;
  private tabs: any;
  private answer: any = '';
  private labels: any;
  private answered: any;
  private missing: any;
  public maxFileSize: number;
  public allowedExt: any;
  private dfh_id: number;
  public pages: any;
  public respondentForm;
  public structure: any;
  public segments: string[];
  public modalFarewell: any;
  public file: any;
  public weights = [];
  public dataBlockChain : any;
  public hashByBlock = null;
  public created_on:any;
  public resultWeight = 1;
  public weightValuesByCustomer: any;
  public mathFormByCustomer: any;
  public arrayWeight:any;
  public structureWithAnswers
  public valueIntTemp
  public structureAditional;
  public typeRequest;
  public answerOnhold = false;
  public secondTabResp=false;
  public firstTabResp;
  public urlPdf;
  public disbaleTableView=true;  
  public actualPage = 0 ;
  public usablityEdit ;
  public initialStatus;
  public initialSecundaryStructure;
  public  actualPatient: String;

  

  @HostListener('change', ['$event'])
  onChange(event: any): void {
    console.log('onChangeEventNameTarget',event.target.name);     
    console.log('onChangeEventValue',event.target.value);
    // console.log('onChangeEventValueTEst',event);   -
    this.structure.map((dato) => {
      ////console.log('DatosMapeados',dato)
     for (let id = 0; id < dato.pages.length; id++){
       let data = dato.pages[id]  
       let filterByData = data.filter(element => element.type == 'Datepicker' ||  element.type == 'Number'||element.type == 'Radio'|| element.type=='File');   
       console.log('entre al for',filterByData);      
      //  //console.log('FirsFilter', filterByData );      
       for (let i = 0; i < filterByData.length; i++) {
         //console.log('tipos',filterByData)
         switch(filterByData[i].type){
           case 'Number':
            if( filterByData[i].name == event.target.name){         
              filterByData[i].answers = event.target.value;            
           }
             break;
            case 'Datepicker':
              console.log('entro al datapicker'); 
              if( filterByData[i].name == event.target.name){      
                filterByData[i].answers = [event.target.value];
                dato.respondentDate = new Date().getTime();

             }
             break;
             case 'File':
              console.log('FILE');
              console.log(filterByData[i].name);
              console.log(event.target.name);
              if( filterByData[i].name == event.target.name){
                console.log(this.urlPdf);      
                filterByData[i].answers = this.urlPdf;
                                
             }
             break;
             case 'Radio':
              if( filterByData[i].name == event.target.name){ 
                //console.log('para ver como detectar si hay valor o no',filterByData[i])
                if(filterByData[i].answers != undefined){
                  //console.log('else',filterByData[i].answers);                 
                  filterByData[i].answers[0] = event.target.value;            
                }else{
                  filterByData[i].answers = [event.target.value];              
                }            
             }
             break;
         }
       }  
     
 
      let filterByFile = data.filter(element =>  element.type == 'File'); 
      ////console.log('filterByFile',filterByFile)
     }
     return dato;
    });
    this.structureWithAnswers = this.structure;
    this.respondentForm.structure =this.structureWithAnswers;
    console.log('estructura -------',this.structure)

  }

  @HostListener('click', ['$event'])
  onClick(event: any): void { 
    //funcion para 'calcs'
    // this.idDetector
    // console.log('click event',event)
    

    this.structure.map(function(dato){
      ////console.log('DatosMapeados',dato)
     for (let id = 0; id < dato.pages.length; id++){
       let data = dato.pages[id]  
       let filterByData = data.filter(element => element.type == 'Radio');     

      
       //console.log('FirsFilter', filterByData );      
       for (let i = 0; i < filterByData.length; i++) {    
         switch(filterByData[i].type){         
         
             case 'Radio':
              if( filterByData[i].name == event.target.name){                
                if(filterByData[i].answers != undefined){
                  // //console.log('else',filterByData[i].answers);                 
                  filterByData[i].answers[0] = event.target.value;            
                }else{
                  filterByData[i].answers = [event.target.value];                
               
                }        
               
              
             }
             break; 
         }

       }  
     
 
      let filterByFile = data.filter(element =>  element.type == 'File'); 
      ////console.log('filterByFile',filterByFile)
     }
     return dato;
    }); 

  }
  
  constructor(
    private respondentFormsService: RespondentFormsService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private customerService: CustomersFormsService,
    private authService: AuthService,
    private formService:FomrsService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService
  ) {
    this.dataSesion = this.authService.getDataSesion();            
    this.form = document.getElementById('editRespondentForm');
    this.tabs = document.getElementsByClassName('tab');
    this.maxFileSize = globalData.sizeFile;
    this.allowedExt = globalData.allowedExt;
    this.pages = [];
    this.currentTab = 0;
    this.respondentForm = {
      dfh_id: null,
      name: null,
      description: null,
      customer_id: null,
      structure: null,
      // answered_check: false,
      respondents_id: null,
      custf_id: null,
      created_on: null,
      diagnostic_detail: null,
      status:null
     
    };
    this.dataBlockChain = {
      idb:null, // dfh_id
      field1: null,
      field2:null, // Peso
      field3:null, //Resultado (postivo - negativo)
      field4:'', // vacio
      creation_date:null     // fecha del sistema
    }
   
    this.answered = [];
    this.labels = [];
    this.missing = [];
    this.dfh_id = 0;
  }

  ngOnInit(): void { 
    this.actualPatient = localStorage.getItem('patientSelected');
    if(localStorage.getItem('pendienteFlujo')=='true'){
      localStorage.setItem('pdfReady','false');
    }else{
      localStorage.setItem('pdfReady','true');
    }
    this.usablityEdit =localStorage.getItem('usabilityEdit');
    this.dataBlockChain['field3'] = 'Negativo';
    this.actualPage=0; 
    //console.log('page actual',this.actualPage)  
    localStorage.setItem('EditMode','true');
    const { id } = this.rutaActiva.snapshot.params;        
    this.getByIdUsrRespForm(id);
    // this.getByIdRespForm(id);
    this.validateSessionApp();
    
    let Time = new Date
    var date = new Date();            
    let dayMap;
    let monthMap = date.getMonth()+1;
    let monthM;

    if(monthMap  > 12){
      monthMap  = 12;
    }
    if (monthMap   >= 10){
      monthM =  monthMap;
    }else{
      monthM =  '0' + monthMap;
    }
    if(date.getDate()>10){
      dayMap =  Time.getDate()
    }else{
      dayMap = '0' + Time.getDate()
    }                     
    this.created_on= date.getFullYear() + '-' + monthM + '-' + dayMap
    
    this.getDataCustomer();
    //console.log('this.dataSesion',this.dataSesion)
    if(this.dataSesion.role=='respondent'){      
      this.typeRequest = 'generic'
    }else{ 
      this.typeRequest = 'specialist'      
    }
    //console.log('typeRequest',this.typeRequest)
    this.spinnerService.updateSpinnerState(false);
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }

  getByIdUsrRespForm(id): any {
    this.respondentFormsService.getByIdUsrRespForm(id).subscribe(
      resp => {
        // Toma el único formulario del paciente id en esta versión
        if (resp) {
          const dfh_id = resp.resp[0].dfh_id;
          this.getByIdRespForm(dfh_id);
          this.idForm = dfh_id.toString();
        }
      },
      error => {
        ////console.log(error);
      }
    );
  }

  getByIdRespForm(id): any {
    this.respondentFormsService.getByIdRespForm(id).subscribe(
      resp => {
        
        if (resp) {
          this.respondentForm = resp.resp[0];
          console.log('----------this respondewntForm---------', this.respondentForm)
         
          // this.structure = JSON.parse(this.respondentForm.structure);
         
          // filtrar por canal y rol 
          if(this.dataSesion.logical_medical== "urology" &&  localStorage.getItem('EditDoctor')=='true'){
            if(this.dataSesion.role == 'doctor'){
              let data = this.respondentForm.structure;
              let dataFilterByRol = data.filter(element => element.role == 'respondent');
              let aditionalData = data.filter(element => element.role == 'specialist');
              if( this.usablityEdit == 'true'){
                          console.log('usabilityEdit', data);
                this.structure = this.respondentForm.structure
              } else{
                 this.structure = aditionalData;
              this.structureAditional =  dataFilterByRol;
              console.log('this structure', data);
              }           
             

            }else{
              let data = this.respondentForm.structure;
              let dataFilterByRol = data.filter(element => element.role == 'respondent');
              let aditionalData = data.filter(element => element.role == 'specialist');            
              this.structure = dataFilterByRol;
              this.structureAditional = aditionalData; 
              
            }

          }else{
            if(this.dataSesion.logical_medical== "urology" &&  localStorage.getItem('EditDoctor') == 'firstTab'){
              let data = this.respondentForm.structure;
              let dataFilterByRol = data.filter(element => element.role == 'respondent');
              let aditionalData = data.filter(element => element.role == 'specialist');            
              this.structure = dataFilterByRol;
              this.structureAditional = aditionalData; 
              //console.log('dataCanal5', data);                                         
            }else{
              if(this.dataSesion.logical_medical== "urology" && this.dataSesion.role == 'respondent'){
              let data = this.respondentForm.structure;
              let dataFilterByRol = data.filter(element => element.role == 'respondent');
              let aditionalData = data.filter(element => element.role == 'specialist');            
              this.structure = dataFilterByRol;
              this.structureAditional = aditionalData; 
              console.log('structureAditiona', data);
                    
              }else{              
                  this.structure = this.respondentForm.structure;        
              }
            
            }
            
            
          }
          // this.structure = this.respondentForm.structure;
       
          ////console.log('respuesta getByIdRespForm',this.structure )
          this.contTabs();
          if(this.respondentForm.status=='onhold/onhold'){
            //console.log('esta en modo de responden el doctor');
            this.answerOnhold= true;
          }
          if(this.respondentForm.status=='answered/onhold'){
            this.secondTabResp = true;            
          }
          if(this.respondentForm.status=='onhold/answered'){
            console.log('onhold/answered' );
            this.initialStatus = this.respondentForm.status;
            this.initialSecundaryStructure = this.structureAditional;
            console.log(' this.initialSecundaryStructureg', this.initialSecundaryStructure);
            // this.firstTabResp = true;            
          }
        }
        console.log('this.structure',this.structure); 
      },
      error => {
        ////console.log(error);
      }
    );
 
  }

  contTabs(): void {
    const structure: any = this.structure;
    structure.forEach(seg => {
      seg.pages.forEach(e => {
        this.pages.push(e);
      });
   });
  }

  /* Presentación */

  showTab(n): void {
    this.tabs[n].style.display = 'block';
    if (n === 0) {
      document.getElementById('prevBtn').style.display = 'none';
    } else {
      document.getElementById('prevBtn').style.display = 'inline';
    }
    if (n === (this.tabs.length - 1)) {
      document.getElementById('nextBtn').innerHTML = 'Enviar';

      // document.getElementById('prevBtn').style.display = 'none';
      // document.getElementById('nextBtn').style.display = 'none';
    } else {
      document.getElementById('nextBtn').innerHTML = 'Siguiente';
    }
    this.fixStepIndicator(n);
  }

  highlightMissing(): void {
    this.missing.forEach(element => {
      const lbl = document.getElementById(element);
      lbl.classList.remove('lbl');
      lbl.style.color = "#dc3545";
      const messageContainer = document.getElementById('messageContainer');
      const loginMessage = document.getElementById('loginMessage');
      messageContainer.classList.remove("undisplay");
      loginMessage.innerHTML = 'Las preguntas resaltadas son obligatorias';
    });
  }

  upTop(): void {
    window.scrollTo(0, 0);
  }

  unDisplay(): void {
    const messageContainer = document.getElementById('messageContainer');
    messageContainer.classList.add('undisplay');
  }

  nextPrev(n): boolean {
    console.log(`this.currentTab: ${this.currentTab}`);
 
    console.log('click en next',n);
    console.log('this.tabs.length',this.tabs.length);
    console.log('this.currentTab ',this.currentTab);

    if (this.currentTab < (this.tabs.length - 1) ) {
      if (n === 1 && !this.validateForm()  && this.dataSesion.role != 'specialist') {
        console.log('entra en el if')
        this.highlightMissing();
        return false;
      } else {
        console.log('entro al else')
        this.unDisplay();
        this.tabs[this.currentTab].style.display = 'none';
        this.currentTab = this.currentTab + n;
        this.actualPage=this.currentTab+1;
        console.log('this.actualPage',this.actualPage)
        this.showTab(this.currentTab);
      }
    }
    //pagina final
     else {
      if (n === 1 && !this.validateForm()  && this.dataSesion.role != 'specialist') {
        this.highlightMissing();
        console.log('entro al if final')
        return false;
      }else{
        if(this.validateFileMng()){
          console.log('entro al else final')
          this.tabs[this.currentTab].style.display = 'none';
          this.onSubmit();
          document.getElementById('prevBtn').style.display = 'none';
          document.getElementById('nextBtn').style.display = 'none';
          // this.crtSettingForm.reset();
          this.currentTab = 0;
        }
       

      }

     
    }

  
    this.upTop();
  }

  labelsRequire(tabs): any {
    const labels = tabs[this.currentTab].getElementsByClassName('lbl-question');
    const countLbl = Object.keys(labels).length;
    let lblsRequire = [];
    for (let i = 0; i < countLbl; i++) {
      if (labels[i].attributes['required'] !== undefined) {
        const required = labels[i].attributes['required'].value;
        lblsRequire.push(labels[i].id);
      }
    }
    return lblsRequire;
  }

  missingLbl(): any {
    let missing = [];
    this.labels.forEach(item => {
      const exists = this.answered.find(element => element === item);
      if (exists === undefined) {
        missing.push(item);
      }
    });
    return missing;
  }

  validateForm(): boolean {
    let valid = true;
    let i;

    const tabs = document.getElementsByClassName('tab');
    const inputs = tabs[this.currentTab].getElementsByTagName('input');
    const textareas = tabs[this.currentTab].getElementsByTagName('textarea');

    this.labels = this.labelsRequire(tabs);
    this.missing = this.missingLbl();

    if (this.missing.length > 0 ) {
      valid = false;
    }
      return valid;
  }
  validateFileMng(){
    console.log('enntro a validateFileMng')
    let valid = true;
    if(this.dataSesion.logical_medical == 'urology'){
      if(localStorage.getItem('pdfReady')=='false'){
        valid = false;
        alert('Se debe subir un archivo pdf');      
      }
    }
  

 

    return valid;
  }

  fixStepIndicator(n): void {
    const x = document.getElementsByClassName('step');
    let i;

    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(' active', '');
    }

    x[n].className += ' active';
  }

  answeredAdd(arr: any, item: any): any {
    return arr.filter(e => e !== item);
  }

  addRmvAnswered(data): void {  
    if (data.answered) {
      const exists = this.answered.find(element => element === `lbl-${data.question}`);
      if (exists === undefined) {
        this.answered.push(`lbl-${data.question}`);
      }
    } else {
      this.answered = this.answeredAdd(this.answered, `lbl-${data.question}`);
    }
  }

  /* Parametrización (Pasar a Middleware) */
  fileBinaryConverter(answerNum): void {
    const file = this.form[answerNum].files[0];
    if (file !== undefined) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = `${reader.result}`;
        sessionStorage.setItem('result', result);
      };
      reader.readAsDataURL(file);
    } else {
      sessionStorage.setItem('result', '');
    }

  }

  answerBuilder(lengthQ, answerNum): any {
    let arrAux: any = [];
    for (let i = 0; i < lengthQ; i++) {
      if (this.form[answerNum + i].checked) {
        const item = this.form[answerNum + i].value;
        arrAux.push(item);
      }
    }
    return arrAux;
  }
  Loader(): void {
    const dialogRef = this.dialog.open(LoaderComponent,{
      width:'350px',
      data:'Cargando',
      disableClose: true
    });
  }
  LoaderClose(): void {
    const dialogRef = this.dialog.closeAll();
  }

  updateRespForm(): void {
    this.Loader();
    this.disbaleTableView=false;        
    let array = []; 
    let dataMap =   this.structure.map(function(data){   
      ////console.log('datosMapeados ######Paso1##########',data)   
      for(let id = 0; id < data.pages.length; id++){
        let dato = data.pages[id];
        ////console.log('datoMap ######Paso2##########',dato);
      //   // let filterByData = dato.filter(element => element.)      
        for(let i = 0; i < dato.length; i++) {             
          let dataAnswerExtract = dato[i].answers;
          let items =   dato[i].items;
          let weight =   dato[i].weight; 
          if(dataAnswerExtract != undefined){
            //console.log('dataAnswerExtract ######Paso3##########', dataAnswerExtract);
            //console.log('dataAnswerItems ######Paso3.1##########', items);
            //console.log('dataAnswerWeight ######Paso3.2##########', weight);
            // let findIndex = items.findIndex(element => element == dataAnswerExtract);//Extraer index de cada respuesta
            // //console.log('FIndIndex ######Paso4#########', findIndex);
            for(let id = 0; id < dataAnswerExtract.length; id++) {
              let dataAnswer = dataAnswerExtract[id];
                //console.log('######Paso4#########', dataAnswer);
                let findIndex = items.findIndex(element => element == dataAnswer);//Extraer index de cada respuesta
                //console.log('FIndIndex ######Paso4.2#########', findIndex);
              if(weight[findIndex] != undefined){
              ////console.log('Peso extraido ######Paso5#########', weight[findIndex]);                              
                if(findIndex != -1){
                  ////console.log('Se extrae index',findIndex);
                  array.push(parseInt(weight[findIndex]));
                  
                  //console.log('Datos en array', array);  
                  // this.resultWeight = 1                    
                }else{
                ////console.log('No se extrae index');
              }                   
            }
           }          
          }      
        }       
      }   
      // ////console.log('Estructura',this.structure);    
      return data;  
    });
    //console.log('**********dataForm',dataMap)

    let total=0;
    for(let i of array) total+=i;
    //console.log('*********',total);
   // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Cálculo de peso final >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
   this.resultWeight = total  *  parseFloat( this.mathFormByCustomer); 
   console.log('*********totalPesos',this.resultWeight);
    if(this.respondentForm.name != 'IPSS'){
      if( this.resultWeight >= this.weightValuesByCustomer.Aproved.init && this.resultWeight <= this.weightValuesByCustomer.Aproved.end ){
        // Positivo  
        //console.log('Positivo');
        this.dataBlockChain['field3'] = 'Positivo';
        this.respondentForm['result_patient'] = 'Positivo';
      }
      else if ( this.resultWeight >= this.weightValuesByCustomer.Reject.init && this.resultWeight <= this.weightValuesByCustomer.Reject.end){
        // Negativo
        //console.log('Negativo'); 
        this.dataBlockChain['field3'] = 'Negativo';
        this.respondentForm['result_patient'] = 'Negativo';
      } else if (this.resultWeight >= this.weightValuesByCustomer.Reject.init){
        //console.log('Negativo'); 
        this.dataBlockChain['field3'] = 'Negativo';
        this.respondentForm['result_patient'] = 'Negativo';      
      }
    }

    if(this.respondentForm.name == 'IPSS'){
      let dataa = this.structure;
      let array =[];
      let id8Value;
      dataa.map((dato) => {
        let filterByRolPatient = dataa.filter(element => element.role== "respondent")
        console.log('DatosMapeados',filterByRolPatient);        
        if(filterByRolPatient && filterByRolPatient.length){
          for (let id = 0; id < filterByRolPatient[0].pages.length; id++){
            let data = filterByRolPatient[0].pages[id];         
            //console.log('for de pages',data);
            for(let i=0;i < data.length; i++){
              let id8answers = data[7].answers[0];         
              let indexAnswers = data[i];    
              let dataAnswerExtract =indexAnswers.answers;       
              let items = indexAnswers.items
              let weight =    indexAnswers.weight;
              //saco el valor de id8
              let finIndex7= data[7].items.findIndex(element=>element==id8answers);
              //console.log('finIndex7',finIndex7)
              id8Value = data[7].weight[finIndex7];
              for(let id = 0; id < dataAnswerExtract.length; id++) {
                let dataAnswer = dataAnswerExtract[id];
                let findByIndex = items.findIndex(element=>element == dataAnswer );
                //console.log('respuestaIndex', findByIndex);
                if(weight[findByIndex] != undefined && findByIndex != -1){         
                                    
                      array.push(parseInt(weight[findByIndex]));                    
                      //console.log('Datos en array', array);       
                                    
                }
              }      
            
            }
          //  let filterByData = data.filter(element => element.type == 'Number'|| element.type == 'Email' || element.type == 'Text'|| element.type == 'Textarea' );             
  
          }
          let total=0;
          for(let i of array) total+=i;  
          
          let val8=parseInt(id8Value);
          console.log('VALOR 8: ', val8);
          console.log('VALOR TOTAL: ', total);
          let totoal=total - val8;
          //console.log('*********totalPesos',this.resultWeight);
          this.respondentForm['result_patient'] = totoal + '-' + id8Value;
        }

      
        return dato;
      });   
    }
    


   
    
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Enviar datos a Blockchain >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // this.dataBlockChain['idb'] =  'FD_'+ this.idForm ;
    // this.dataBlockChain['field1'] = JSON.stringify(this.structure);
    // this.dataBlockChain['field2'] = this.resultWeight.toString();    
    // this.dataBlockChain['field4'] = '';
    // this.dataBlockChain['creation_date'] = this.created_on;   
    // if(this.dataSesion.logical_medical=='urology'){
    //   this.respondentForm['result_patient'] = null;  
    // } 
    // console.log('datos enviado a dataBlockChain',this.dataBlockChain)            
    // this.respondentFormsService.SendDataBlockChain(this.dataBlockChain).subscribe(resp => {
    //   //console.log('respuestaAlastria',resp);
    //   this.hashByBlock = resp.hash;
    //   // ////console.log('hash______',resp.hash)
    //   if(this.hashByBlock != null){
    //     this.saveForm(this.hashByBlock);       
    //   }     
    // },err => console.log(':::::::::::::::::::::::error::::::::::::::::', err));
    this.saveForm(this.hashByBlock);   

    


  
    // limpiar formulario
  }

  saveForm(hash){
    console.log('entro al saveForm')
    this.respondentForm['weight'] = this.resultWeight; // aqui va el resultado
    this.respondentForm['hash'] = hash;
    // this.respondentForm['answered_check']  
    //console.log('se guarda',this.respondentForm);
    if(this.dataSesion.logical_medical== "urology" &&  localStorage.getItem('EditDoctor')=='true' && this.usablityEdit != 'true'){
      switch(this.dataSesion.role){
        case'respondent':
        this.respondentForm.structure.push(this.structureAditional[0]);
        break;
        case'doctor':
        this.respondentForm.structure.unshift(this.structureAditional[0]);
                              
        if(  this.secondTabResp==true){
          this.respondentForm.status = 'answered/answered'
        }else{
          this.respondentForm.status = 'onhold/answered'
        }
     
        break;
        case'specialist':
        this.respondentForm.structure.unshift(this.structureAditional[0]);
        if(  this.secondTabResp==true){
          this.respondentForm.status = 'answered/answered'
        }else{
          this.respondentForm.status = 'onhold/answered'
        }
        break;
      }      
    }
    if(this.dataSesion.logical_medical== "urology" &&  localStorage.getItem('EditDoctor')=='firstTab' && this.usablityEdit != 'true'){
      switch(this.dataSesion.role){
        case'respondent':
        this.respondentForm.structure.push(this.structureAditional[0]);
        break;
        case'doctor':
        this.respondentForm.structure.push(this.structureAditional[0]);
        if( localStorage.getItem('EditDoctor')=='true'){
          if(this.initialStatus == 'onhold/answered'){
            this.respondentForm.status = 'answered/answered'
          }else{
            this.respondentForm.status = 'answered/onhold'
          }          
        }      
        break;
        case'specialist':
        this.respondentForm.structure.unshift(this.structureAditional[0]);
        if(  this.firstTabResp==true){
          this.respondentForm.status = 'answered/answered'
        }else{
          this.respondentForm.status = 'answered/onhold'
        }
        break;
      }      
    }
    if( this.usablityEdit == 'true'){
      console.log('prueba***')
    }
    //console.log('datos dadasdasdasd',this.dataSesion.role,this.answerOnhold)
    if(this.answerOnhold == true && this.dataSesion.role=='respondent' && this.structureAditional){
      console.log('adentro ',this.dataSesion.role,this.answerOnhold);
      console.log(`ADITIONAL DATA: ${this.structureAditional}`);
      this.respondentForm.structure.push(this.structureAditional[0]);

    }

    // if(this.answerOnhold == true){
    //   this.respondentForm.status == 'answered/onhold'
    // } 

    

    console.log('llego un paso antes a actualizar el formulario')
    console.log('this.respondentForm',this.respondentForm,this.typeRequest);
    if(this.initialStatus == 'onhold/answered'  && localStorage.getItem('EditDoctor')!='firstTab' ){
      this.respondentForm.status = 'answered/answered';
      this.respondentForm.structure.push(this.initialSecundaryStructure[0]);
    }
    this.respondentFormsService.updateRespForm(this.respondentForm,this.typeRequest).subscribe(
      resp => {
        console.log('resp',resp)
        if (resp.correct) {
          this.disbaleTableView=false;                              
          this.openModalFrw(); 
          this.LoaderClose();             
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  validateFile(size, type): boolean {
    let valid = true;

    if (size > this.maxFileSize) {
      valid = false;
    }

    const fileExt = this.allowedExt.find(element => element === type);
    if (fileExt === undefined) {
      valid = false;
    }
    return valid;
  }



  fileMessage(event): void{
    const fileMessage: any = document.getElementById('fileMessage');
    fileMessage.innerHTML = event.msg;
  }

  uploadFile(formData): void {
    
    this.respondentFormsService.uploadFile(formData).subscribe(
      resp => {

        console.log('uploadFile',resp);
        this.urlPdf = resp.resp
        if (resp.correct) {
          this.structure.map(function(dato){
            //////console.log('DatosMapeados',dato)
           for (let id = 0; id < dato.pages.length; id++){
             let data = dato.pages[id]  
             let filterByData = data.filter(element => element.type=='File');       
            
             //console.log('FirsFilter', filterByData );      
             for (let i = 0; i < filterByData.length; i++) {
               //console.log('tipos',filterByData)
               switch(filterByData[i].type){                
                   case 'File':                        
                      filterByData[i].answers = this.urlPdf;                  
                   break;

               }
      
             }  
            }});
           
        
          
          // this.openModalFrw();
        }
      },
      error => {
        ////console.log(error);
      }
    );
    // limpiar formulario
  }

  onSubmit(): any {
    console.log('entro al onSubmit')
    let answerNum: number = 0;
    this.form = document.getElementById('editRespondentForm');
    const formLength = this.form.length;

    this.structure.forEach( async (est: any, sI) => {
      est.pages.forEach((pg, pI) => {
        pg.forEach( async (q, qI)=> {
          let arrAux: any = [];
          let lengthQ: number = 1;
          let found: boolean = false;
          let i = 0;

          while (!found) {
            if (this.form[i].name === q.name) {
              answerNum = i;
              found = true;
            }
            i++;
          }

          switch (q.type) {
            case 'Radio':
            case 'Checkbox':
                lengthQ = q.items.length;
                arrAux = this.answerBuilder(lengthQ, answerNum);
              break;
            case 'File':
                /* Converted to binary format */
                // await this.fileBinaryConverter(answerNum);
                // this.answer = sessionStorage.getItem('result');

                /* Default format */
                const form = this.respondentForm;
                const file = this.form[answerNum].files[0];
                const typeFile = file.type.split('/')[1];
                const valid = this.validateFile(file.size, typeFile);

                if (valid) {
                  //console.log('esta en el swich de file');
                  this.answer = `${form.dfh_id}-${form.customer_id}-${form.respondents_id}.${typeFile}`;
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append('route', this.answer);
                  this.uploadFile(formData);
                  arrAux.push(this.answer);
                }
              break;
            case 'Date':
              this.answer = this.form[answerNum].value;
                arrAux.push(this.answer);
              break;
            default:
                this.answer = this.form[answerNum].value;
                arrAux.push(this.answer);
              break;
          }

          this.structure[sI].pages[pI][qI]['answers'] = arrAux;

        });
      });
    });

    this.respondentForm.structure = this.structure;
    if(this.dataSesion.role == 'doctor'){
      if(this.answerOnhold == true){
        this.respondentForm['status'] = 'answered/onhold';        
      }else{
        this.respondentForm['status'] = 'reviewed';
      }
      
    }else{
      if(this.dataSesion.logical_medical== "urology" && this.dataSesion.role == 'respondent'){
        this.respondentForm['status'] = 'answered/onhold';        
      }else{
        this.respondentForm['status'] = 'answered';
      }
      
    }  
    console.log('llega hasta el final')  

    this.updateRespForm();

  }

  openModalFrw(): void {
    this.modalFarewell = document.getElementById('modalFarewell');
    this.modalFarewell.style.display = 'block';
  }

  closeModalFrw(): void {
    this.modalFarewell = document.getElementById('modalFarewell');
    this.modalFarewell.style.display = 'none';
    if(this.dataSesion.role == 'doctor'){
      this.router.navigate(['medical-dashboard']);
    }else{
      this.router.navigate(['list-respondent-form']);
    }

    
  }

  getDataCustomer(){
    this.customerService.getByIdCustForm(this.dataSesion.customerId).subscribe(res=>{     
      console.log('res.resp[0].weight_values.values_weith', res.resp[0]);    
      this.weightValuesByCustomer = res.resp[0].weight_values.values_weith;
      //console.log('res.resp[0].weight_values.values_weith',  res.resp[0].form_weight);    
      this.mathFormByCustomer = res.resp[0].form_weight;
    }),err =>{} ////console.log('error en getDataCUstomer',err)
  }

 
  formBUilder(value){   
 
    //console.log('evento formBUilder');

    //BUscar la funcion correspondiente
    switch(value[0]){
      case'Edad':
      this.edadFormula(value);
      break;
      case'IMC':
      this.imcCalculator(value);     
      break;
      case'IMC Resultado':
      this.imcCalculator2(value);     
      break;
      case'Riesgo':
      this.Riesgo_hipertension(value);     
      break;
      
      // case'':
      // this.edadFOrmula(value);
      // break;
    }
  }
    //funciones para calcular 
    edadFormula(value){
      console.log('edadFormula',value);
      console.log('Parametros de busqueda',value[1]);    
      for (let page=0;page < this.structure.length;page++){
        if(this.actualPage == page){
          let dato = this.structure[page];
          console.log('Se trabajara con la page:',dato); 
           for (let id = 0; id < dato.pages.length; id++){           
            let dataPages = dato.pages[0];  
            console.log('primer recorrido', dataPages );
            let paramSearch = value[1];
            let paramSearchClean = paramSearch.replace("id-", "");
            paramSearchClean.toString(); 
            let filterByPAramsSearch = dataPages.filter(element => element.id==  paramSearchClean);       
            console.log('segundo', filterByPAramsSearch );
              
            //  //logica de calculo
             if(filterByPAramsSearch[0].answers != undefined){
              filterByPAramsSearch[0].style  = '';
              //se transforma el resultado guardado en un valor interpretable
              let cleanDate = filterByPAramsSearch[0].answers.slice(0,-6) //2021-07-16
              console.log('cleanDate',cleanDate);
              //formula
              let Time = new Date;
              let total= Time.getFullYear() - cleanDate;
              console.log('Edad',total);  
              //buscamos donde guardar la respuesta en la structure      
              let edadSearchForm =  dataPages.filter(element => element.name==  value[0]+': '); 
              console.log('se guardara en:', edadSearchForm )
              edadSearchForm[0].answers =   total.toString();
             }else{
              filterByPAramsSearch[0].style  = 'error';                       
               alert('Para completar el cálculo debe completar todos los campos requeridos');
             }             
        }}
      
      }

  }


  //calcular imc 
  imcCalculator(value){
    console.log('FormulaData',value);
    console.log('Parametros de busqueda',value[1],value[2]);
    let paramSearch1 = value[1];
    let paramSearchClean = paramSearch1.replace("id-", "");
    paramSearchClean.toString();
    let paramSearch2 = value[2];
    let paramSearchClean2 = paramSearch2.replace("id-", "");
    paramSearchClean2.toString();
    
    this.structure.map(function(dato){
      console.log('this.structure',dato);      
      for (let id = 0; id < dato.pages.length; id++){   
        let dataPages = dato.pages[0];  
        console.log('primer recorrido', dataPages );
        let filterByPAramsSearch = dataPages.filter(element => element.id==  paramSearchClean);       
        console.log('segundo', filterByPAramsSearch );
        let filterByPAramsSearch2 = dataPages.filter(element => element.id==  paramSearchClean2);       
        console.log('segundo', filterByPAramsSearch2 );

        if(filterByPAramsSearch2.length ==0){
          console.log('no se encontro valores');
        }else{
          //logica de calculo
         if(filterByPAramsSearch[0].answers != undefined && filterByPAramsSearch2[0].answers != undefined){
          filterByPAramsSearch[0].style  = '';
          filterByPAramsSearch2[0].style  = '';
          //se transforma el resultado guardado en un valor interpretable
          let Peso = filterByPAramsSearch[0].answers;
          let Talla = filterByPAramsSearch2[0].answers; //2021-07-16
          console.log('Peso y talla ',Peso,Talla);
          //formula
          let total = Peso/Math.pow(Talla,2);      
          console.log('Total',total);
          //buscamos donde guardar la respuesta en la structure      
          let IDMSearchForm =  dataPages.filter(element => element.name==  value[0]+': '); 
          console.log('se guardara en:', IDMSearchForm );
          if(total< 25){
            IDMSearchForm[0].answers =   ["< 25"];
          }
          if(total> 25 && total< 30 ){
            IDMSearchForm[0].answers =   ["25 - 30"];
          }
          if(total> 30){
            IDMSearchForm[0].answers =   ["> 30"];
          }
        
          // total.toString();
         }else{
          filterByPAramsSearch[0].style  = 'error';
          filterByPAramsSearch2[0].style  = 'error';                      

           alert('Para completar el cálculo debe completar todos los campos requeridos');
         } 
        }
        
  
     
      }      

     return dato;
    });

  } 
  imcCalculator2(value){
    console.log('FormulaData',value);
    console.log('Parametros de busqueda',value[1],value[2]);
    let paramSearch1 = value[1];
    let paramSearchClean = paramSearch1.replace("id-", "");
    paramSearchClean.toString();
    let paramSearch2 = value[2];
    let paramSearchClean2 = paramSearch2.replace("id-", "");
    paramSearchClean2.toString();

   
    for (let page=0;page < this.structure.length;page++){
      console.log('entra al for')
      if(this.actualPage-1 == page){
        let dato = this.structure[page];
        console.log('Se trabajara con la page:',dato); 
          for (let id = 0; id < dato.pages.length; id++){   
        let dataPages = dato.pages[id];  
        console.log('primer recorrido', dataPages );
        let filterByPAramsSearch = dataPages.filter(element => element.id==  paramSearchClean);       
        console.log('segundo', filterByPAramsSearch );
        let filterByPAramsSearch2 = dataPages.filter(element => element.id==  paramSearchClean2);       
        console.log('segundo', filterByPAramsSearch2 );
        
        if(filterByPAramsSearch2.length ==0){
          console.log('no se encontro valores');
        }else{
           //logica de calculo
         if(filterByPAramsSearch[0].answers != undefined && filterByPAramsSearch2[0].answers != undefined){
          filterByPAramsSearch[0].style  = '';
          filterByPAramsSearch2[0].style  = '';
          //se transforma el resultado guardado en un valor interpretable
          let Peso = filterByPAramsSearch[0].answers;
          let Talla = filterByPAramsSearch2[0].answers; //2021-07-16
          console.log('Peso y talla ',Peso,Talla);
          //formula
          let total =  Math.round(Peso/Math.pow(Talla,2));     
          console.log('Total',total);
          //buscamos donde guardar la respuesta en la structure      
          let IDMSearchForm =  dataPages.filter(element => element.name==  value[0]+': '); 
          console.log('se guardara en:', IDMSearchForm );
          IDMSearchForm[0].answers =   total.toString();
        
          // total.toString();     
          


         }else{
          filterByPAramsSearch[0].style  = 'error';
          filterByPAramsSearch2[0].style  = 'error';                      

           alert('Para completar el cálculo debe completar todos los campos requeridos');
         }            
        }
         
     
      }  
      }else{
        let dato = this.structure[0];
        console.log('Se trabajara con la page:',dato); 
          for (let id = 0; id < dato.pages.length; id++){   
        let dataPages = dato.pages[id];  
        console.log('primer recorrido', dataPages );
        let filterByPAramsSearch = dataPages.filter(element => element.id==  paramSearchClean);       
        console.log('segundo', filterByPAramsSearch );
        let filterByPAramsSearch2 = dataPages.filter(element => element.id==  paramSearchClean2);       
        console.log('segundo', filterByPAramsSearch2 );

        if(filterByPAramsSearch2.length ==0){
          console.log('no se encontro valores');
        }else{
                   //logica de calculo
         if(filterByPAramsSearch[0].answers != undefined && filterByPAramsSearch2[0].answers != undefined){
          filterByPAramsSearch[0].style  = '';
          filterByPAramsSearch2[0].style  = '';
          //se transforma el resultado guardado en un valor interpretable
          let Peso = filterByPAramsSearch[0].answers;
          let Talla = filterByPAramsSearch2[0].answers; //2021-07-16
          console.log('Peso y talla ',Peso,Talla);
          //formula
          let total =  Math.round(Peso/Math.pow(Talla,2));     
          console.log('Total',total);
          //buscamos donde guardar la respuesta en la structure      
          let IDMSearchForm =  dataPages.filter(element => element.name==  value[0]+': '); 
          console.log('se guardara en:', IDMSearchForm );
          IDMSearchForm[0].answers =   total.toString();
        
          // total.toString();      
          


         }else{
          filterByPAramsSearch[0].style  = 'error';
          filterByPAramsSearch2[0].style  = 'error';                      

           alert('Para completar el cálculo debe completar todos los campos requeridos');
         }
        }
        
   
     
      }
      }
    }
    
    // this.structure[8].map(function(dato){
    //   //console.log('this.structure',dato);
        

    //  return dato;
    // });

  }
  Riesgo_hipertension(value){
    let weightArray= [];
    //console.log('FormulaData',value);
    //console.log('Parametros de busqueda',value[1],value[2]);
    let paramSearch1 = value[1];
    let paramSearchClean = paramSearch1.replace("idws-", "");
    let answePrimaryQuestion ;
    //paramSearchClean
    let paramSearch2 = value[2];
    let paramSearchClean2 = paramSearch2.replace("idws-", "");
    //console.log('Parametros de busquedaSearch',paramSearchClean,paramSearchClean2);
    //paramSearchClean2   
    for (let page=0;page < this.structure.length;page++){
      if(this.actualPage-1 == page){
        let dato = this.structure[page];
        //console.log('Se trabajara con la page:',dato); 
        for (let id = 0; id < dato.pages.length; id++){   
              let dataPages = dato.pages[id]; 
              //console.log('primer recorrido', dataPages);
              let filterbyCorrectValues = dataPages.filter(element => parseInt(element.id) >=parseInt(paramSearchClean) && parseInt(element.id) <=parseInt(paramSearchClean2));
              answePrimaryQuestion = dataPages.filter(element => element.class == 'Calc' );
              //console.log('answePrimaryQuestion', answePrimaryQuestion);
              //console.log('respuesta filterbyCorrectValues', filterbyCorrectValues);
              for(let i=0;i < filterbyCorrectValues.length;i++){
                //console.log('valor for',filterbyCorrectValues[i]);
                let valueFor = filterbyCorrectValues[i];
                let answers  = valueFor.answers
                if(filterbyCorrectValues[i].answers != undefined){
                  filterbyCorrectValues[i].style  = '';
                  let findIndex = valueFor.items.findIndex(element=>element==answers);
                  //console.log('FindIndex',findIndex);
                  let weight =  valueFor.weight[findIndex];  
                  //console.log('Valor de peso por pregunta',weight);
                  weightArray.push(parseInt(weight));    
                }else{
                  filterbyCorrectValues[i].style  = 'error';            
                }
              }    
            }  
            
            //console.log('Array de pesos',weightArray);
            let sum=0;
            let total = 0;
            for(let i of weightArray) sum+=i;
            total = Math.round(sum/weightArray.length) ;
            //console.log('total',total);
            let answerTotal;
            switch(total){
              case total=1:
                answerTotal = '1. Bajo';
              break;
              case total=2:
                answerTotal = '2. Intermedio';
              break;
              case total=3:
                answerTotal = '3. Alto';
              break;
            }
            answePrimaryQuestion[0].answers= [answerTotal];
      }
    }
  }
     //METODO PARA BUSCAR LA PÁGINA ESPECIFICA EN LA QUE SE A CLICK
    //  selectPage(completePag,actualPagStructure){
    //    console.log('select completePag', completePag);
    //    console.log('select actualPagStructure', actualPagStructure);
    //    for(let i = 0; i<completePag.length;i++){
    //      if(completePag[i] == actualPagStructure){
    //        console.log('página seleccionada por index:', i);
    //        this.actualPage =i
    //        this.showTab(i);
    //        this.unDisplay();
    //      }
    //    }      
    // }

}
