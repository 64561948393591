import { Component, OnInit,HostListener } from '@angular/core';
import { CustomersFormsService } from '../../services/customers-forms.service';
import { CustomerForm } from '../../models/customer_form.model';
import { MatDialog } from '@angular/material/dialog';
import { ModalEditPereodicFormsComponent } from '../_presenters/modal-edit-pereodic-forms/modal-edit-pereodic-forms.component';

@Component({
  selector: 'app-list-customer-forms',
  templateUrl: './list-customer-forms.component.html',
  styleUrls: ['./list-customer-forms.component.css']
})
export class ListCustomerFormsComponent implements OnInit {

  public custFormData: CustomerForm[];
  public dataStructure;

  @HostListener('click', ['$event'])
  onClick(event: any): void { 
    //console.log('event',event)

  }

  constructor(private customersFormsService: CustomersFormsService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCustForms();
    this.validateSessionApp();
  }

  getCustForms(): void {
    this.customersFormsService.getCustForms().subscribe(
      response => {
        if (response.correct) {
          this.custFormData = response.resp;
          this.dataStructure = JSON.stringify(response.resp)
          //console.log(this.dataStructure)
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }

  openModalPereodicForms(data): void {
    const dialogRef = this.dialog.open(ModalEditPereodicFormsComponent,{
      width:'800px',
      data:data     
    });    
    dialogRef.afterClosed().subscribe(res=>{       
      if(res){    
        //console.log(res.userName);     
      }
    })    
   }
}
