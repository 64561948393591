import { Component, OnInit } from '@angular/core';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { RespondentForm } from '../../models//respondent_form.model';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import {MatTableDataSource} from '@angular/material/table';
import { CustomersFormsService } from '../../services/customers-forms.service';
import { time } from 'console';
import { element } from 'protractor';
import { MatDialog } from '@angular/material/dialog';
import { ModalAlertComponent } from '../_presenters/modal-alert/modal-alert.component';
import { LoaderComponent } from '../_presenters/loader/loader.component';
import { ModalPreviewFormRespondentComponent } from '../_presenters/modal-preview-form-respondent/modal-preview-form-respondent.component';
import { FormRespodentComponent } from '../form-respodent/form-respodent.component';
import * as moment from 'moment';
import { SpinnerService } from '../../services/spinner.service';
import { GeneralService } from '../../services/general.service';



export interface PeriodicElement {
  Paciente: string;
  Formulario: string;
  Fecha: number;
  Acciones: string;
}
@Component({
  selector: 'app-list-respondent-forms',
  templateUrl: './list-respondent-forms.component.html',
  styleUrls: ['./list-respondent-forms.component.css']
})

export class ListRespondentFormsComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);

  public dataSesion: any;
  public respForms: RespondentForm[];
  public title: string;
  public userName: string;
  public fecha: string;
  public dataSend : any;
  public header:any;
  public customerForm: any;
  public respondentForms: any;
  public formToSendData:any
  public dataFormonhold: any;
  public dataFormRespondent: any;
  public filterAndChangeCreateOnhold:any;
  public filterAndChangeCreateRespondent:any

  constructor(
    private respondentFormsService: RespondentFormsService,
    private authService: AuthService,
    private router: Router,
    private userServi: UsersService,
    private customerService: CustomersFormsService,
    private spinnerService: SpinnerService,
    private generalService: GeneralService,
    public dialog: MatDialog
    ) 
  {

    if(localStorage.getItem('logical_medical') == 'surgiai'){
      this.router.navigate(['/list-specialist-forms'])
    }
    
    localStorage.setItem('MyQrPage','false');
    this.dataSesion = this.authService.getDataSesion();
    console.log('this.dataSesion',this.dataSesion);
    this.userName = localStorage.getItem('name');
    localStorage.setItem('EditMode','false');
    
    this.dataSend = {
      patient: 1003484092,
      json: {
        
      }
    }   

    this.formToSendData= {
      nameForm: null,//'hola',
      description:  null,//'hola',
      customer_id: null,//2,
      created_on : null,
      // strStruc:  null,//'hola',
      structure: null,//'hola',
      answered_check : null,
      respondents_id: null,//229,
      custf_id: null,//3,
      status: 'onhold' ,
      specialist: null               
    }
  }

  ngOnInit(): void {
    console.log('datos de usuario',this.dataSesion)
    this.generalService.refreshLoginState();
    if(this.dataSesion.role =='specialist'&& this.dataSesion.logical_medical !=' urology'){
      console.log('entro a if')
      this.getRespodentFormsByCustomer()
    }else{
      this.getRespForms();
    }
    
    this.validateSessionApp();
    if(this.router.url=='list-respondent-form'){
      
    }else{
      this.router.navigate(['/list-respondent-form']);
    }
    this.getDataCustomer();
    this.spinnerService.updateSpinnerState(false);
  }

  openSAlert(): void {
    const dialogRef = this.dialog.open( LoaderComponent,{
      width:'350px',
      data:'Generando nuevos formularios...',
      disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{      

    })    
   }

  previewFormRespondentDialog(id): void {
   const dialogRef = this.dialog.open(FormRespodentComponent,{
     width:'1000px',height:'500px',
     data:id,
     // disableClose: false
   });    
   dialogRef.afterClosed().subscribe(res=>{})    
  }
 getRespodentFormsByCustomer(){
   this.respondentFormsService.getRespForms(this.dataSesion.customerId).subscribe(
    resp => {
      console.log('respuesta forms',resp.resp)
      this.respondentForms = resp.resp
      if (resp.correct) {
        switch (this.dataSesion.role) {
        
          case 'specialist':
              this.respForms = resp.resp.filter(element => element.specialist_id ==this.dataSesion.id);
              console.log('this.respForms',this.respForms)
              let dataFilter = this.respForms.filter(element => element.status =='answered');
              this.dataFormRespondent = this.respForms;
              console.log('this.respFormsaadsadasdasd',this.dataFormRespondent)
              this.filterAndChangeCreateRespondent = this.dataFormRespondent.map(data=>{
                console.log('data Map', data);                                      
                let dataMap =data;
                const d: Date = new Date();
                d.setTime(dataMap.created_on);
                const n = d.toLocaleDateString();
                console.log('data Map fecha respondent', n);
                dataMap.created_on=n    
                return dataMap;          
              }
              );
              console.log('data Map', this.filterAndChangeCreateRespondent);
            break;
        }
      }
    },
    error => {
      console.log(error);
    }
   );
 }

  getRespForms(): void {// switch previo, paginar, si el
    this.respondentFormsService.getAllDataByIdUsrRespForm(this.dataSesion.id).subscribe(
      resp => {
        console.log('respuesta forms',resp.resp)
        this.respondentForms = resp.resp
        if (resp.correct) {
          switch (this.dataSesion.role) {
            case 'admin':
            case 'customer':
            case 'doctor':
              break
            case 'specialist':
              console.log('entro a specialist');
              this.respForms = resp.resp;
              this.dataFormonhold =  this.respForms;
            break;
            case 'patient':
            case 'respondent':
                this.respForms = resp.resp.filter((item) => {
                  return item.respondents_id === this.dataSesion.id;
                });
                console.log('this.respForms',this.respForms)
                let dataFilter = this.respForms.filter(element => element.status =='answered' || element.status =='diagnosed' || element.status == 'aforementioned'|| element.status == 'expired'|| element.status == 'answered/onhold'||element.status == 'rescheduled');
                this.dataFormRespondent = dataFilter;
                let dataOnhold = this.respForms.filter(element => element.status =='onhold' ||  element.status == 'onhold/onhold' || element.status == 'onhold/answered');
                this.dataFormonhold =  dataOnhold;
                console.log('dataFormonhold ',this.dataFormonhold);
                this.filterAndChangeCreateRespondent = this.dataFormRespondent.map(data=>{
                  console.log('data Map', data.activation_date);                                      
                  let dataMap = data;
                  const fechaParsed: Date = new Date(dataMap.activation_date * 1000);
                  console.log('data Map fecha respondent', fechaParsed);
                  dataMap.activation_date = fechaParsed; 

                  return dataMap;          
                }
                );
                console.log('data Map', this.filterAndChangeCreateRespondent); 

                this.filterAndChangeCreateOnhold = this.dataFormonhold.map(data=>{
                  console.log('data Map onhold', data);                                      
                  let dataMap =data;
                  const fechaParsed: Date = new Date(dataMap.activation_date * 1000);
                  console.log('data Map', fechaParsed); 
                  dataMap.activation_date = fechaParsed;    
                  return dataMap;                  
                }
                );


              break;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }
  prueba(){
    this.userServi.dataSend(this.dataSend).subscribe(res=>{
      console.log('resuesta alastria',res)
    })
  }

  getDataCustomer(){
    this.customerService.getByIdCustForm(this.dataSesion.customerId).subscribe(res=>{ // poner id de canal this.dataSesion.customerId
      // console.log('Parse periodic',res.resp[0].periodic);
      // console.log('Customer_forms', res.resp)  
      console.log('*******************primero ingresa al getDataCustomer*****************') 
      this.customerForm = res.resp;
      if(this.dataSesion.logical_medical!='urology' && this.dataSesion.role =='respondent'){
        // this.createFormByPeriodic()
        // this.createFormByMultiPeriodic();
      }
  
    }),err => console.log('error en getDataCUstomer',err)
  }

  // createFormByPeriodic(){
  //   console.log('*******************segundo ingresa al createFormByPeriodic*****************') 
  //   let periodicData : any;
  //   // periodicData = JSON.parse(this.customerForm.periodic);
  //   let Time = new Date;
  //   let monthDate = Time.getMonth() +1;
  //   let month;
  //   let day;
  //   var days = ['domingo','lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']; //se usara luego para comparar los dias a crear un formulario 
  //   var d = new Date();
  //   var dayName = days[d.getDay()];  
  //   console.log('dia',dayName)  
  //   if(monthDate  > 12){
  //     monthDate = 12;
  //   }
  //   if (monthDate  >= 10){
  //     month =  monthDate;
  //   }else{
  //     month =  '0' + monthDate;
  //   }
  //   if(Time.getDate()>=10){
  //     day =  Time.getDate()
  //   }else{
  //     day = '0' + Time.getDate()
  //   }
  //   let fullDate = Time.getFullYear() + '-' + month + '-' + day;
  //   let fullDate2 = day + '/' + monthDate + '/' + Time.getFullYear()
  //   let fullDate3 = monthDate + '/' + day + '/' + Time.getFullYear()
  //   let fullDate4 = day + '/' + month + '/' + Time.getFullYear()
  //   parseInt(fullDate);
  //   console.log('Fecha actual', fullDate);

  //   //filtrar unicamente los formularios que cumplen las caracteristicas de periodic
  //   let formToCreate = this.customerForm.map(data => {
  //     console.log('*******************TERCERO *****************') 
  //     let d = data;
  //     let periodicData = JSON.parse(d.periodic);
  //     console.log('customer_forms.periodic',periodicData);      
  //     console.log('customer_forms-mapeado',d);
  //     //se filtra los formularios de customer_forms que esten dentro de la fecha establecida por periodic

  //     let  exist = false;      
  //     for (let i = 0; i <this.respForms.length; i++) {
       
  //       if(this.respForms[i].name ==d.name){
  //         exist = true
  //       }
        
  //     } 
    
  //     let filterForms = periodicData.filter(element => fullDate >= element.dateInit && fullDate <= element.dateEnd && element.validatedPeriod ==false && element.validatedDays ==false && exist == false);   
  //     console.log('*******************CUARTO *****************') 
  //     console.log('filtro', filterForms);            
  //     if( filterForms  != undefined && filterForms.length > 0  ){
  //       if(filterForms[0].oneTime==true){
  //         console.log('*******************fILTRO UNA SOLA VEZ *****************'); 
  //         console.log('entro a una sola vez');
  //         //verficar si exite formulario, caso contrario lo crea
  //         //comprobar si tiene answered check en treu o false
  //         let filterBool = this.respForms.filter(element => element.answered_check==true);         

  //         console.log('formulario filtrado por fecha actual <<<<<unico>>>>', filterBool);
  //         console.log('Fecha 2', fullDate2);
  //         // Descarte por fecha final
  //         // let filterByCreateOn = formFilter.filter(element => element.created_on == fullDate2 || element.created_on == fullDate3 || element.created_on == fullDate4  && element.name == d.name && element.answered_check == true);
  //         console.log('formulario filtrado por fecha actual2', filterBool);
  //         if ( filterBool.length  ){
  //           console.log('El formulario existe');
  //         }else{
  //             //verficar si exite el formulario creado donde el usuario, caso contrario lo crea    
  //             console.log('Proceso de creación de formulario');    
         
  //          if(this.respondentForms != undefined && this.respondentForms.created_on != fullDate){// establecer método para comprobar
  //           console.log('Creando nuevo formulario un nuevo formulario ');            
         
  //           let arrayStruc = JSON.stringify( d.structure);              
  //           this.formToSendData.nameForm = d.name;
  //           this.formToSendData.description = d.description;
  //           this.formToSendData.customer_id = d.customer_id;
  //           this.formToSendData.created_on = fullDate;
  //           this.formToSendData.structure = JSON.parse(arrayStruc);
  //           this.formToSendData.respondents_id = this.dataSesion.id; //usuario logeado
  //           this.formToSendData.custf_id =d.custf_id; //id de documento en form_data   
  //           this.formToSendData.specialist = 0; 
  //           this.formToSendData.answered_check = true   
  //           this.authService.createForm(this.formToSendData).subscribe(resp => {
  //             this.openSAlert();
  //             setTimeout(()=>{               
  //               location.reload();}, 3000); 
  //             console.log('respuesta create form',resp);
  //           },erro =>console.log('error al crear formulario',erro));          
  //          }
  //         }

  //       } 
  //     }

 
  //   });
  // }


  // createFormByMultiPeriodic(){
  //   console.log('*******************segundo ingresa al createFormByPeriodic*****************') 
  //   let periodicData : any;
  //   // periodicData = JSON.parse(this.customerForm.periodic);
  //   let Time = new Date;
  //   let monthDate = Time.getMonth() +1;
  //   let month;
  //   let day;
  //   var days = ['domingo','lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']; //se usara luego para comparar los dias a crear un formulario 
  //   var d = new Date();
  //   var dayName = days[d.getDay()];  
  //   console.log('dia',dayName)  
  //   if(monthDate  > 12){
  //     monthDate = 12;
  //   }
  //   if (monthDate  >= 10){
  //     month =  monthDate;
  //   }else{
  //     month =  '0' + monthDate;
  //   }
  //   if(Time.getDate()>=10){
  //     day =  Time.getDate();
  //   }else{
  //     day =  Time.getDate();
  //   }
  //   let fullDate = Time.getFullYear() + '-' + month + '-' + day;
  //   let fullDate2 = day + '/' + monthDate + '/' + Time.getFullYear();
  //   let fullDate3 = monthDate + '/' + day + '/' + Time.getFullYear();
  //   let fullDate4 = day + '/' + month + '/' + Time.getFullYear();
  //   parseInt(fullDate);
  //   console.log('Fecha actual', fullDate);

  //   //filtrar unicamente los formularios que cumplen las caracteristicas de periodic
  //   console.log('formularios',this.customerForm); 
  //   let formToCreate = this.customerForm.map(data => {
  //     console.log('*******************TERCERO *****************'); 
  //     let d = data;
  //     let periodicData = JSON.parse(d.periodic);
  //     console.log('customer_forms.periodic',periodicData);      
  //     console.log('customer_forms-mapeado',d);
  //     //se filtra los formularios de customer_forms que esten dentro de la fecha establecida por periodic


  //     let filterForms = periodicData.filter(element => fullDate >= element.dateInit && fullDate <= element.dateEnd);   
  //     console.log('*******************cUARTO *****************'); 
  //     console.log('filtro', filterForms);   
        
  //     if( filterForms  != undefined && filterForms.length > 0 && filterForms[0].validatedDays){        
  //         let filterBool = this.respForms.filter(element => element.answered_check==false);
  //         let formFilter = filterBool.filter(element =>{           
  //           var timestamp = parseInt(element.created_on);
  //           var date = new Date(timestamp);       
  //           // element.description == d.description;     
  //           let dayMap;
  //           let monthMap = date.getMonth()+1;
  //           let monthM;
  //           if(monthMap  > 12){
  //             monthMap  = 12;
  //           }
  //           if (monthMap   >= 10){
  //             monthM =  monthMap;
  //           }else{
  //             monthM =  '0' + monthMap;
  //           }
  //           if(date.getDate()>10){
  //             dayMap =  Time.getDate()
  //           }else{
  //             dayMap =   Time.getDate()
  //           }                     
  //           let dateEnd = date.getFullYear() + '-' + monthM + '-' + dayMap
  //           return dateEnd;
  //           // console.log('dateEnd', dateEnd);
                            
  //           // == fullDate
  //         } );
  //         console.log('formulario filtrado por fecha actual', formFilter.length);
          

  //         console.log('******************* no unico *****************') 
  //         let dtaID = filterForms[0].days
  //         let id ;
  //         switch(dayName){
  //           case 'lunes':
  //             id = 1
  //             break;
  //           case 'martes':
  //             id = 2
  //             break
  //           case 'miercoles':
  //             id = 3
  //             break;
  //           case 'jueves':
  //             id = 4
  //             break
  //           case 'viernes':
  //             id = 5
  //             break;
  //           case 'sabado':
  //             id = 6
  //             break;
  //           case 'domingo':
  //             id = 7
  //             break;
  //         }
  //         console.log('daysArrayFiltradoPorDia',dtaID[id].check)// se verifica que el dia actual tenga marcado en true o false      
  //         if( dtaID[id].check == true ){             
  //          console.log('data forms', this.respondentForms);
  //          console.log('this.respForms', this.respForms);
  //           // let formFilter = this.respForms.filter(element => element.created_on == fullDate && element.description == d.description);
  //           console.log('formulario filtrado por fecha actual case 2', formFilter.length);
  //           let filterByCreateOn = formFilter.filter(element => element.created_on == fullDate2  || element.created_on == fullDate3 || element.created_on == fullDate4 && element.name == d.name && element.answered_check == false);
  //           console.log('***********filterByCreateOn********', filterByCreateOn); 

  //           if ( filterByCreateOn.length ){
  //             console.log('El formulario existe');
  //           }else{         
  //            if(this.respondentForms != undefined && this.respondentForms.created_on != fullDate){// establecer método para comprobar
  //             console.log('Creando nuevo formulario un nuevo formulario');             
  //             let arrayStruc = JSON.stringify( d.structure);              
  //             this.formToSendData.nameForm = d.name;
  //             this.formToSendData.description = d.description;
  //             this.formToSendData.customer_id = d.customer_id;
  //             this.formToSendData.created_on = fullDate
  //             this.formToSendData.structure = JSON.parse(arrayStruc);
  //             this.formToSendData.respondents_id = this.dataSesion.id //usuario logeado
  //             this.formToSendData.custf_id = d.custf_id //id de documento en form_data,
  //             this.formToSendData.specialist = 0;
  //             this.formToSendData.answered_check = false              
  //             this.authService.createForm(this.formToSendData).subscribe(resp => {
  //               this.openSAlert();
  //               setTimeout(()=>{                  
  //                 location.reload();}, 3000);                  
  //               console.log('respuesta create form',resp)
  //             },erro =>console.log('error al crear formulario',erro));          
  //            }
  //           }              
  //         }    
        
  //     }else if( filterForms[0].validatedPeriod && filterForms[0].validatedDateForm != 0){
  //       console.log('logica de periodo');
  //       const periodicInit = filterForms[0].period[0];
  //       const periodicEnd = filterForms[0].period[filterForms[0].period.length - 1];   
  //       const idForToConsult =  filterForms[0].validatedDateForm;     
     
        
  //       this.respondentFormsService.getAllDataByIdUsrRespForm(this.dataSesion.id).subscribe((res)=>{
  //         console.log('res',res.resp);
  //         const dtaForm = res.resp;
  //       let getFormByIdValidateForm = dtaForm.filter(element => parseInt(element.custf_id) == idForToConsult);
  //         if(getFormByIdValidateForm.length >0){
  //           console.log('getFormByIdValidateForm',getFormByIdValidateForm);
  //           /*Extraer fecha para la valid */        
  //         let todayTimestamp = new Date();
  //         console.log(' todayTimestamp ', todayTimestamp );
  //         let dateFromForm = new Date(getFormByIdValidateForm[0].structure[0].respondentDate * 1)   
  //         console.log('dateFromForm',dateFromForm);       
  //         var a = moment(todayTimestamp);//now
  //         var b = moment(dateFromForm);
  //         const totalHour = a.diff(b, 'hours'); 
  //         console.log(a.diff(b, 'hours'));          
  //         /** Se valida si la hora  entra en el rango de periodicidad y que el formulario a crear ya no este creado*/ 
  //         let  exist = false;
  //         let formsAnswereds = false;
  //         for (let i = 0; i < dtaForm.length; i++) {
  //           if(dtaForm[i].name ==d.name){
  //             exist = true
  //           } 
            
  //           if(dtaForm[i].status == 'onhold'){
  //             formsAnswereds =false;
  //             break;
  //           }else{
  //             formsAnswereds =true;
  //           }
            
  //         }       
         
  //           if(totalHour >= periodicInit && totalHour <= periodicEnd && !exist && formsAnswereds){
  //             /**Se crea el formulario */
  //             this.formToSendData.nameForm = d.name;
  //             this.formToSendData.description = d.description;
  //             this.formToSendData.customer_id = d.customer_id;
  //             this.formToSendData.created_on = fullDate
  //             this.formToSendData.structure = d.structure;
  //             this.formToSendData.respondents_id = this.dataSesion.id //usuario logeado
  //             this.formToSendData.custf_id = d.custf_id //id de documento en form_data,
  //             this.formToSendData.specialist = 0;
  //             this.formToSendData.answered_check = false              
  //             this.authService.createForm(this.formToSendData).subscribe(resp => {
  //               this.openSAlert();
  //               setTimeout(()=>{                  
  //                 location.reload();}, 3000);                  
  //               console.log('respuesta create form',resp)
  //             },erro =>console.log('error al crear formulario',erro));      
  //           }          
  //         }
  //       })

  //     }
 
  //   });
  // }

}
