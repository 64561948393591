<p class="titel_form">Crear formulario cliente:</p>

<form #crtCustForm="ngForm">

  <div class="form-group">
    <label for="customer_id">Cliente</label>
    <select class="" id="customer_id" name="customer_id" #customer_id="ngModel" [(ngModel)]="customerForm.customer_id" placeholder="Cliente...">
      <option disabled></option>
      <option *ngFor="let cust of customers" [value]="cust.usr_id">{{cust.name}}</option>
    </select>
  </div>

  <div class="form-group">
    <label for="form_id">Formulario</label>
    <select class="" id="form_id" name="form_id" #form_id="ngModel" [(ngModel)]="customerForm.form_id" placeholder="Formulario...">
      <option *ngFor="let form of forms" [value]="form.form_id">{{form.name}}</option>
    </select>
  </div>

  <div class="form-group">
    <label for="setting_id">Configuración</label>
    <select class="" id="setting_id" name="setting_id" #setting_id="ngModel" [(ngModel)]="customerForm.setting_id" placeholder="Configuración...">
      <option *ngFor="let sett of settings" [value]="sett.cfc_id">{{sett.name}}</option>
    </select>
  </div>

  <div class="form-group row">
    <div class="col-sm-10">
      <button type="submit" class="btn btn-primary" (click)="onSubmit()">Guardar</button>
    </div>
  </div>

</form>
