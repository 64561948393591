<p class="titel_form">Configuración formulario:</p>

<form #crtSettingForm="ngForm" >

  <div class="tab">

    <p class="titel_tap">Identificación:</p>

    <div class="form-group">
      <label for="name">Nombre:</label>
      <input type="text" class="" id="name" name="name" #name="ngModel" [(ngModel)]="setFormData.name" placeholder="Nombre">
    </div>

    <div class="form-group">
      <label for="description">Descripción:</label>
      <textarea class="" id="description" name="description" #description="ngModel" [(ngModel)]="setFormData.description" rows="3"></textarea>
    </div>

    <div class="form-group">
      <label for="form_id">Formulario</label>
      <select class="" id="form_id" name="form_id" #form_id="ngModel" [(ngModel)]="setFormData.form_id" placeholder="Formulario...">
        <option *ngFor="let form of formsData" [value]="form.form_id">{{form.name}}</option>
      </select>
    </div>

  </div>

  <div class="tab">

    <p class="titel_tap">Secuencia de segmentos:</p>

    <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let segment of segments" cdkDrag>{{segment}}</div>
    </div>

  </div>

  <div class="tab">

    <p class="titel_tap">Preguntas por página:</p>

    <div *ngIf="showQuest" >

      <ul>
        <li *ngFor="let page of selectedForm.structure.pages; let i = index">
          <div class="form-group">
            <label for="">Página {{i+1}}:</label>

            <select class="q_slc" id="q_slc_{{i}}" #q_slc_{{i}}="ngModel" ngModel name="q_slc_{{i}}"multiple>
              <option value="{{i}},{{j}}" *ngFor="let quest of selectedForm.structure.questions; let j = index" (click)="validateQuestion(i,j)">{{quest.name}}</option>
            </select>

          </div>
        </li>
      </ul>

    </div>

  </div>

  <div class="tab">

    <p class="titel_tap">Paginas por segmento:</p>

    <div *ngIf="showQuest" >

      <ul>
        <li *ngFor="let segment of segments; let i = index">
          <div class="form-group">
            <label for="">Segmento {{i+1}}:</label>

            <select class="pg_slc" id="pg_slc_{{i}}" #q_slc{{i}}="ngModel" ngModel name="pg_slc_{{i}}" multiple>
              <option value="{{i}},{{j}}" *ngFor="let page of selectedForm.structure.pages; let j = index" (click)="validatePage(i,j)">Página {{j+1}}</option>
            </select>

          </div>
        </li>
      </ul>

    </div>

  </div>

  <div class="tab">

    <p class="titel_tap">Rol por segmento:</p>

    <div *ngIf="showQuest" >

      <ul>
        <li *ngFor="let segment of segments; let i = index">
          <div class="form-group">
            <label for="">Segmento {{i+1}}:</label>

            <select class="r_slc" id="r_slc_{{i}}" #r_slc_{{i}}="ngModel" ngModel name="r_slc_{{i}}">
              <option value="{{i}},{{j}}" *ngFor="let rol of roles; let j = index" >{{rol.param}}</option>
            </select>

          </div>
        </li>
      </ul>

    </div>

  </div>

  <div style="overflow:auto;">
    <div style="float:right;">
      <button class="btn btn-secondary" type="button" id="prevBtn" (click)="nextPrev(-1)">Previous</button>
      <button class="btn btn-success" type="button" id="nextBtn" (click)="nextPrev(1)">Next</button>
    </div>
  </div>

  <!-- Circles which indicates the steps of the form: -->
  <div style="text-align:center;margin-top:40px;">
    <span class="step"></span>
    <span class="step"></span>
    <span class="step"></span>
    <span class="step"></span>
    <span class="step"></span>
  </div>

</form>
