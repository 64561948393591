import { Component, OnInit } from '@angular/core';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { RespondentForm } from '../../models//respondent_form.model';
import { RespondentsService } from '../../services/respondents.service';
import { Users } from '../../models/users.model';
import { CustomersFormsService } from '../../services/customers-forms.service';
import { CustomerForm } from '../../models/customer_form.model';

@Component({
  selector: 'app-create-respondent-form',
  templateUrl: './create-respondent-form.component.html',
  styleUrls: ['./create-respondent-form.component.css']
})
export class CreateRespondentFormComponent implements OnInit {

  public respForms: RespondentForm;
  public respondents: Users[];
  public customerForms: CustomerForm[];

  constructor(
    private respondentFormsService: RespondentFormsService,
    private respondentsService: RespondentsService,
    private customersFormsService: CustomersFormsService) {
    this.respForms = {
      dfh_id: null,
      name: null,
      description: null,
      customer_id: null,
      structure: null,
      answered_check: null,
      respondents_id: null,
      custf_id: null,
      created_on: null,
      diagnostic_detail: null,
      status: null
    };
  }

  ngOnInit(): void {
    this.getRespondents();
    this.getCustForms();
  }

  createRespForm(): void {
    this.respondentFormsService.createRespForm(this.respForms).subscribe(
      response => {
        if (response.correct) {
          //console.log(response);
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  getRespondents(): void {
    this.respondentsService.getRespondents().subscribe(
      response => {
        if (response.correct) {
          this.respondents = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  getCustForms(): void {
    this.customersFormsService.getCustForms().subscribe(
      response => {
        if (response.correct) {
          this.customerForms = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  onSubmit(): any {
    const id = document.getElementById('custf_id')['value'];
    this.respForms.name = this.customerForms[id].name;
    this.respForms.description = this.customerForms[id].description;
    this.respForms.customer_id = this.customerForms[id].customer_id;
    this.respForms.custf_id = this.customerForms[id].form_id;
    this.respForms.structure = this.customerForms[id].structure;

    this.createRespForm();
  }

}
