import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor( private http: HttpClient ) { }

  public routeFlag: EventEmitter<boolean> = new EventEmitter<boolean>();

  setOnCreatePatient(value){
    this.routeFlag.emit(value);
  }

  getUsers(customer_id): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.users}/${customer_id}`);
  }
  getGetUsersDiagnosed(customer_id): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.usrDiagnosed}/${customer_id}`);
  }
  dataSend(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.dataSend}`, body, {headers: headers});
  }
  getByIdUser(id): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getUsr}/${id}`);
  }
  createUser(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtUsr}`, body, {headers: headers});
  }
  updateStatusUsr(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(`${globalData.urls.rootURI}${globalData.urls.updUsr}`, body, {headers: headers});
  }
  updateTokensUser(id,tokens): Observable<any> {
    const body: any = JSON.stringify({
      id,
      type: 'tokens',
      dataset: JSON.stringify(tokens)
    });
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(`${globalData.urls.rootURI}${globalData.urls.updUsr}`, body, {headers: headers});
  }
  setDataValidationPassport(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtPassValidation}`, body, {headers: headers});
  }  
  getPatientByName(name,id_customer,id_stablishment): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.searchtUsr}/${name}/${id_customer}/${id_stablishment}`);
  }
  getPatientByClinicHistory(name,id_customer,id_stablishment): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.searchUsrByCH}/${name}/${id_customer}/${id_stablishment}`);
  }
}
