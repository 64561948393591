<div class="page-content">
    
    <div *ngIf="dataSesion.logical_medical != 'urology' && dataSesion.logical_medical != 'preconsult' ">
        <p class="text-center" style="color: #5288ec; font-size: 14px;">Ingresar paciente</p>  
        <div *ngIf="idNumber==''" class="bg-danger p-10 text-white"> Ingrese el número de identificación del Usuario</div>
      
        <div class="form__group field">
          <label for="usr" class="form__label">{{title_text}}</label>
          <input *ngIf="channel != 2" type="number" [(ngModel)]="idNumber" class="form__field" placeholder="Ingresar número de cédula" name="usr" id='usr'  required />
          <input *ngIf="channel == 2" type="number" [(ngModel)]="idNumber" class="form__field" placeholder="Ingresar Número de Paciente" name="usr" id='usr'  required />    
        </div>
    
        <div class="form__group field"  *ngIf="dataSesion.logical_medical!='cardiology'">
          <label for="usr" class="form__label">Crear formulario </label>
          <mat-form-field appearance="fill">
            <mat-label>Formularios</mat-label>
            <mat-select [(ngModel)]="idStablihsmntCardiology">
              <mat-option  *ngFor="let item of forms" [value]="item">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> 
    
    
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="dataSesion.logical_medical!='cardiology' && dataSesion.logical_medical != 'preconsult'">
          <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="  verifyUserUca()">Enviar</button>
      </div>
    
    
        <div *ngIf="dataSesion.logical_medical=='cardiology'">
          <div class="form__group field"  *ngIf="createFormFrame == 1" >
            <label for="usr" class="form__label">Crear formulario</label>
            <mat-form-field appearance="fill">
              <mat-label>Formularios</mat-label>
              <mat-select [(ngModel)]="idStablihsmntCardiology">
                <mat-option  *ngFor="let item of formsCardiology" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>   
          </div>
      
          <div class="form__group field"  *ngIf="createFormFrame == 2" >
            <label for="usr" class="form__label">Crear formulario</label>
            <mat-form-field appearance="fill">
              <mat-label>Formularios</mat-label>
              <mat-select [(ngModel)]="idStablihsmntCardiology">
                <mat-option  *ngFor="let item of aditionalForm" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>   
          </div>
        </div>
      
    
      
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="dataSesion.logical_medical=='cardiology'">
            <button  *ngIf="frame == 0 && generalBoolBTN" mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="verifyUserCardiology(1)">Seleccionar formulario</button>
            <button  *ngIf="frame == 1 && generalBoolBTN" mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="verifyUserCardiology(2)">Enviar</button>
            <button  *ngIf="frame == 2 && generalBoolBTN" mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="verifyUserCardiology(3)">Crear</button>
    
            
        </div>
    
        <div *ngIf="createFormFrame ==3">
          <h2>Procesando...</h2>
        </div>
        
    </div>
    
    <div *ngIf="dataSesion.logical_medical == 'urology' ||  dataSesion.logical_medical == 'preconsult'" >
        
        <h2 style="color: #00669A;">Ingresar paciente</h2>
        
        <h3>Datos Generales:</h3>
        <div class="row">
          <div class="col-md-6">
            <label>Centro Médico</label>
            <div class="form-field">
              <mat-select [disabled]="disabledBTN" [(ngModel)]="selected">
                <mat-option *ngFor="let item of clinicsData" [value]="item.code" (click)="chooseStablishment(item.name)">
                {{item.name}}
                </mat-option>
              </mat-select>
            </div>
            
          </div>
          <div class="col-md-6">
              <label>Número de historia clínica</label>
              <div>
                  <input class="form-field" type="text" [disabled]="disabledBTN" [(ngModel)]="clinicHistoryText">
              </div>
          </div>

          <div class="col-md-6">
              <label>Nombres del paciente</label>
              <div>
                  <input class="form-field" type="text" [disabled]="disabledBTN" [(ngModel)]="patName"> 
              </div>
          </div>
          <div class="col-md-6">
              <label>Apellidos del paciente</label>
              <div>
                  <input class="form-field" type="text" [disabled]="disabledBTN" [(ngModel)]="patLastName"> 
              </div>
          </div>
  
          <div class="col-md-6">
              <label>Correo Electrónico</label>
              <div>
                  <input class="form-field" type="email" [disabled]="disabledBTN" [(ngModel)]="mailUserSender">
              </div>
          </div>
          
          <div class="col-md-6">
            <label>Teléfono</label>
            <div class="phone-form-field">
              <div class="form-field select">
                <mat-select [(ngModel)]="this.authForm.intPhoneCodes">
                  <mat-option *ngFor="let code of codes" [value]="code.code">{{code.code_country}} <small>{{'+'+code.code}}</small></mat-option>
                </mat-select>
              </div>
              <input class="form-field" type="tel" [(ngModel)]="this.authForm.phone">
            </div>
          </div>
        </div>

        <h3>Periodicidad de Formularios para el Paciente:</h3>
        <div class="row">
            <div class="col-md-6 peridiocity-select">
                <input type="radio" [(ngModel)]="periodic" name="periodic" id="normal" value="normal">
                <label for="normal">Normal</label>
            </div>
            <div class="col-md-6 peridiocity-select">
                <input type="radio" (click)="generateCustomForm()" [(ngModel)]="periodic" name="periodic" id="custom" value="customized">
                <label for="custom">Personalizado</label>
            </div>
        </div>

        <div class="row" *ngIf="this.periodic != 'customized' && this.filterFormByIdStablisment.length">
            <div class="col-md-12">
                <table class="periodicity-preview">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Periodicidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let form of this.filterFormByIdStablisment; let i = index">
                            <td class="first-column">{{form.name}}</td>
                            <td>
                              {{form.periodic[0].validatedPeriod ? 'Periódico' : 'Una sola vez'}}
                              <div *ngIf="form.periodic[periodicLevel[this.periodic]].validatedPeriod">
                                <div *ngFor="let period of form.periodic[periodicLevel[this.periodic]].period; let i = index"><b>{{i+1}}.</b> {{period | frequency}}</div>
                              </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row" *ngIf="this.periodic == 'customized' && this.filterFormByIdStablisment.length">
          <div class="col-md-12">
              <table class="periodicity-preview">
                  <thead>
                      <tr>
                          <th>Nombre</th>
                          <th>Periodicidad</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let form of this.filterFormByIdStablisment; let i = index">
                          <td class="first-column">{{form.name}}</td>
                          <td>
                            {{form.periodic[0].validatedPeriod ? 'Periódico' : 'Una sola vez'}}
                            <div *ngIf="form.periodic[0].validatedPeriod">
                              <div class="periodFormField" *ngFor="let periodGroup of customPeriodArray['id-'+form.custf_id].period; let j = index">
                                <input class="form-field" [(ngModel)]="periodGroup.amount" type="number">
                                <div class="form-field select">
                                  <mat-select [(ngModel)]="periodGroup.unit">
                                    <mat-option value="hours">Horas</mat-option>
                                    <mat-option value="days">Días</mat-option>
                                    <mat-option value="months">Meses</mat-option>
                                  </mat-select>
                                </div>
                                <button type="button" mat-icon-button (click)="deletePeriod(j,form.custf_id)"><mat-icon>close</mat-icon></button>
                              </div>
                              <button mat-flat-button type="button" (click)="addPeriod(form.custf_id)"><mat-icon>add</mat-icon> AGREGAR PERIODO</button>
                            </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
        
        
        <!-- <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="createUSer()">Grabar</button>
        </div> -->
        <div style="display: flex; justify-content: end;" *ngIf="!msgDone">
          <button *ngIf="btnCreate" mat-raised-button style="background: #00669A; color: #fff;" type="button" (click)="verifyUser()">Continuar</button>
        </div>
    
    
        <!-- <div *ngIf="msgDone" class="card-subtitle" style=" background: rgb(204, 223, 247); border-radius: 2mm; padding: 2%; margin-top: 5%;">
          <div class="footer">
            <p class="text-center" style="color: #5288ec; font-size: 14px;">!Atención!</p>      
          </div>
          Usuario creado satisfactoriamente, las credenciales de acceso fueron enviadas a : {{dataSesion.mail}} y {{mailUserSender}}
        </div>
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" style="margin-top: 5%;" *ngIf="msgDone">
          <button mat-raised-button style="background: #4EAAD8; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="closeModal()">Cerrar</button>
       </div> -->
        
      </div>    

</div>
