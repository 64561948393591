import { Component, OnInit } from '@angular/core';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { AuthService } from '../../services/auth.service';
import { element } from 'protractor';
import { CustomersFormsService } from '../../services/customers-forms.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmDataComponent } from '../_presenters/modal-confirm-data/modal-confirm-data.component';
import { ModalSelectMyQRComponent } from '../_presenters/modal-select-my-qr/modal-select-my-qr.component';
import { ModalAlertComponent } from '../_presenters/modal-alert/modal-alert.component';

@Component({
  selector: 'app-my-qr',
  templateUrl: './my-qr.component.html',
  styleUrls: ['./my-qr.component.css']
})
export class MyQrComponent implements OnInit {
  public myAngularxQrCode = '';
  public widthQR = 350 //200 para escritorio
  public dataSesion;
  public data;
  public UIMobil = false;
  public uiDesktop = false;
  constructor(private respondentFormsService: RespondentFormsService,
              private authService: AuthService,
              private customerForm: CustomersFormsService,
              public dialog: MatDialog) {

                localStorage.setItem('MyQrPage','true');
                if (screen.width <=768){
                  this.uiDesktop= false;
                  this.widthQR = 350;
                } else{
                  this.uiDesktop= true;
                  this.widthQR = 200;
                }
    this.dataSesion = this.authService.getDataSesion(); 
    
   }

  ngOnInit(): void {
    localStorage.setItem('InMyQr','true');      
    this.openSelectForm();
  
    // this. getdataCustForm(1);
  }
  openSelectForm(): void {
    const dialogRef = this.dialog.open(ModalSelectMyQRComponent,{
      width:'350px',
      data:'Confirmar cita',
      disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{       
      if(res){    
        console.log('openSectData',res); 
        this.getRespondentForm(res);        
      }
    })    
   }

   openAlertForm(): void {
    const dialogRef = this.dialog.open(ModalAlertComponent,{
      width:'350px',
      data:'No se ha contestado ningún formulario, complete uno y vuelva intentar',
      disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{
      location.reload();  
    
    })    
   }


  getRespondentForm(res){
   
    // this.respondentFormsService.getByIdUsrRespForm(this.dataSesion.id).subscribe(resp => {
    //   console.log('respuesta getRespondentForm', resp); 
    //   // let datafilter =       
    //   // let lastRecord = resp.resp[length-1];     
    // });
    this.respondentFormsService.getAllDataByIdUsrRespForm(this.dataSesion.id).subscribe(resp =>{
      console.log('respuesta getRespondentForm', resp);
      let data = resp.resp; 
      let formsFInd = data.find(element => element.name==res);
      if(formsFInd.hash_patient == null){
        this.openAlertForm()
      }
      console.log('respuesta getRespondentForm2', formsFInd);
       this.myAngularxQrCode = formsFInd.hash_patient.toString();
       console.log('datos qr',this.myAngularxQrCode);
    });
  }


  generarQR(){
    // console.log('lastRecord', lastRecord.hash_patient);
    // this.myAngularxQrCode = lastRecord.hash_patient.toString();
  }
  regresar(){
    localStorage.setItem('MyQrPage','false');    
  }
  
  reloadPage(){
    location.reload();
  }
}
