




<mat-toolbar style="background: #00669A;" >
    <div  type="button"  style="color: #00669A; background: rgb(255, 255, 255); border-radius: 50mm; width: 3%;"  [routerLink]="['/']">
      <span style="margin-left: 20%; margin-top: 20%;" routerLinkActive="router-link-active"  class="material-icons">
        keyboard_backspace
        </span> 
    </div>
    <div type="button" [routerLink]="['/medical-dashboard']" style="margin-left: 1%; color: rgb(255, 255, 255);">
      Regresar 
    </div>
  
  </mat-toolbar>


<section id="tabs" class="project-tab">
    <div class="">
        <div class="">
            <div class="">
                <mat-tab-group (selectedTabChange)="showTab($event)">        
                    <mat-tab label="Finalizados">
                        <div class="tab-pane fade show tab" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <ag-grid-angular style="width: 100%; height: 550px;  overflow: scroll" class="ag-theme-alpine" [rowData]="dataDiagnosed" [columnDefs]="headersDiagnosed" rowSelection="multiple" [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate"></ag-grid-angular>
                        </div>
                    </mat-tab>
                </mat-tab-group> 
            </div>
        </div>

    </div>
</section>
