<div style="position: fixed; margin-left: -10%; z-index: -5; margin-top: -10%;">
  <img style="width: 105.5%;" src="assets/img/backgroundTeam.jpg" >
</div>
<mat-toolbar style="background: #00669A;" >
  <div  type="button"  style="color: #00669A; background: rgb(255, 255, 255); border-radius: 50mm; width: 3%;"  [routerLink]="['/']">
    <span style="margin-left: 20%; margin-top: 20%;" routerLinkActive="router-link-active"  class="material-icons">
      keyboard_backspace
      </span> 
  </div>
  <div type="button" [routerLink]="['/']" style="margin-left: 1%; color: rgb(255, 255, 255);">
    Regresar al tablero médico
  </div>

</mat-toolbar>

<div  style="overflow: auto; height: 50%;">
  <mat-grid-list  cols="2" rowHeight="20:10">
    <mat-grid-tile >     
      <div class="user-card-body card">
        <div class="card-body">
          <div style="width: 100%;" class="top-card text-center">
            <img style="width: 150px;" src="assets/team.jpg" class="img-responsive" alt="">      
          </div>
          <div class="card-contain text-center p-t-40">
            <h5 class="text-capitalize p-b-10">Isabel Jiménez Ruiz</h5>
            <p class="text-muted">Médico urgencias hospital La Salud Cádiz</p>
          </div>   
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>     
      <div class="user-card-body card">
        <div class="card-body">
          <div style="width: 100%;" class="top-card text-center">
            <img style="width: 150px;" src="assets/team.jpg" class="img-responsive" alt="">      
          </div>
          <div class="card-contain text-center p-t-40">
            <h5 class="text-capitalize p-b-10">Diego Fernández Martínez</h5>
            <p class="text-muted">Médico dispositivo de apoyo Bahía de Cádiz</p><br>
            <p class="text-muted">Médico urgencias/UCE Hospital La Salud.</p>
          </div>   
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>     
      <div class="user-card-body card">
        <div class="card-body">
          <div style="width: 100%;" class="top-card text-center">
            <img style="width: 150px;" src="assets/team.jpg" class="img-responsive" alt="">      
          </div>
          <div class="card-contain text-center p-t-40">
            <h5 class="text-capitalize p-b-10">Marcos Moreno Chaparro Sanchez</h5>
            <p class="text-muted">Médico de emergencias extrahospitalarias 061.</p><br>
            <p class="text-muted">Médico de urgencias/UCE Hospital la Salud.</p>
          </div>   
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>     
      <div class="user-card-body card">
        <div class="card-body">
          <div style="width: 100%;" class="top-card text-center">
            <img style="width: 150px;" src="assets/team.jpg" class="img-responsive" alt="">      
          </div>
          <div class="card-contain text-center p-t-40">
            <h5 class="text-capitalize p-b-10">María Vallo Arjonilla</h5>
            <p class="text-muted">Médico dispositivo de apoyo Bahía de Cádiz.</p><br>
            <p class="text-muted">Médico de urgencias/UCE Hospital la Salud.</p>
          </div>   
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>     
      <div class="user-card-body card">
        <div class="card-body">
          <div style="width: 100%;" class="top-card text-center">
            <img style="width: 150px;" src="assets/team.jpg" class="img-responsive" alt="">      
          </div>
          <div class="card-contain text-center p-t-40">
            <h5 class="text-capitalize p-b-10">Nazareth Romero Ruiz</h5>
            <p class="text-muted">Médico urgencias y UCE hospital La Salud</p>
          </div>   
        </div>
      </div>
    </mat-grid-tile>      
  </mat-grid-list>
</div>
  

   


