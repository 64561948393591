import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { element } from 'protractor';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { CustomersFormsService } from '../../services/customers-forms.service';
import { AuthService } from '../../services/auth.service';



@Component({
  selector: 'app-guard-page',
  templateUrl: './guard-page.component.html',
  styleUrls: ['./guard-page.component.css']
})
export class GuardPageComponent implements OnInit {

  
  public dataUser :any;
  public list = false;
  public callData : any;
  public dataSesion;
  public iconShow = '';
  public dataCustomer;
  public message;
  public diagnosticText;
  public created_on;
  public cedula;
  public dataValidation;
  public QRExpired = false;
  public camScan = true;
  
  constructor(private userService: UsersService, private respondentService:RespondentFormsService,private customerService: CustomersFormsService, private authService: AuthService) {
  
    this.dataSesion = this.authService.getDataSesion();   
    this.dataValidation = {      
      id_user_control:null,
      id_user_validate:null,
      id_customer:null,
      id_establishment:0,
      structure:{
        created_on:null,
        dni:null,
        estado:null
      }
    }
    this.callData = {
      "idb": "424"     
    }
    localStorage.setItem('MyQrPage','true');
  }

  ngOnInit(): void {
    this.getDataCustomer();
    

    // this.callData = {
    //   "idb":"424"    
    // }
    //console.log('Codigo', this.callData);

    // this.respondentService.CallDataBlockChain(this.callData).subscribe(res => {
    //   console.log('respuesta del call',res);
    //   if(res[2]=='Negativo'){
    //     // //console.log('Negativo');
    //     this.diagnosticText = 'Negativo'
    //     this.iconShow = this.dataCustomer.Aproved.icon;  
    //     this.message = this.dataCustomer.Aproved.message;      
    //   }else{
    //     // //console.log('Positivo');
    //     this.diagnosticText = 'Positivo'
    //     this.iconShow = this.dataCustomer.Reject.icon ;
    //     this.message = this.dataCustomer.Reject.message;         
    //   }
    // });
    // var encodedData = window.btoa(this.url);     


  
  }

  public scanSuccessHandler($event: any){
    
    // console.log('Codigo leído', $event);
    let value = $event;
    value.toString(); 
    if($event != ''){
      this.camScan = false;
    }

    this.customerService.getByIdCustHash(value).subscribe(response =>{    
      const d: Date = new Date();
      // d.setTime(response.resp[0].created_on);
      const n = d.toLocaleDateString();
      console.log('data Map', d.getDate());
      this.created_on = n;
      var f = new Date();
       let dateActual = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();
       console.log('data Map2', dateActual); 
       let dif= f.getDate()-d.getDate()
       console.log('resta de dias', dif);
       if(  dif >= 2){
        this.QRExpired= true;
       }     
   
      console.log('IdDetect',response);
      let dataCall = response.resp[0].dfh_id;
      this.CallDataAlastria(value,response.resp[0].respondents_id);     
      // this.getDataByHash(dataCall.toString());
    });

    //console.log('Codigo', this.callData);
    // this.list = true;
  }

  CallDataAlastria(value,respondets_id){

    this.callData = {
      "hash": value     
    }
    console.log('se envia en la cosulta', this.callData);

      this.respondentService.CallDataBlockChain(this.callData).subscribe(res => {
      console.log('respuesta del call',res);
      if(res.field3=='Negativo'){
        console.log('Negativo');
        this.diagnosticText = 'Negativo';
        this.iconShow = this.dataCustomer.Reject.icon;
        this.message = this.dataCustomer.Reject.message; 
        this.getDataUseryId(respondets_id);           
      }else{
        console.log('Positivo');
        this.diagnosticText = 'Positivo';
        this.iconShow = this.dataCustomer.Aproved.icon;  
        this.message = this.dataCustomer.Aproved.message; 
        this.getDataUseryId(respondets_id);       
      }
    });
  }

  getDataCustomer(){
    this.customerService.getByIdCustForm(this.dataSesion.customerId).subscribe(res=>{     
      console.log('res.resp[0].weight_values.values_weith', res.resp[0].weight_values.values_weith);     
      this.dataCustomer = res.resp[0].weight_values.values_weith;
    }),err => {}//console.log('error en getDataCUstomer',err)
  }

  getDataUseryId(id){
    this.userService.getByIdUser(id).subscribe(res=>{
      console.log('respuesta getDataUseryId',res)
      this.cedula = res.resp[0].doc_id
      this.dataValidation.id_user_control = parseInt(this.dataSesion.id) ;
      this.dataValidation.id_user_validate = parseInt(id) ;
      this.dataValidation.id_customer = parseInt(this.dataSesion.customerId);
      this.dataValidation.structure.created_on =  this.created_on;
      this.dataValidation.structure.dni = this.cedula;
      this.dataValidation.structure.estado = this.message;   
      this.setValidation();    
   
    })
  }

  setValidation(){
    // this.dataValidation.structure = JSON.stringify(this.dataValidation)
  
    this.dataValidation.structure.estado = this.message; 
    console.log('datos a validationPasssport',this.dataValidation);    
    this.userService.setDataValidationPassport(this.dataValidation).subscribe(res=>{
      console.log('setValidation',res)
    })
  }
  reload(){
    location.reload(); 
  }

  signOut(): void {
    this.authService.signOut();
  }
  // getDataByHash(data){
  //   this.customerService.getByIdCustHash(data).subscribe(res=>{
  //     console.log('datagetDataByHash',res)
  //   })
  // }


}
