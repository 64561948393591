<p class="titel_form">{{titel}}</p>

<section class="sectionCreateForm">
  <div class="form-group">
    <label  class="titel_seg">Nombre:</label>
    <input type="text" class="" #name="ngModel" [(ngModel)]="form.name">
  </div>

  <div class="form-group">
    <label  class="titel_seg">Descripción:</label>
    <textarea class="" #description="ngModel" [(ngModel)]="form.description" rows="3"></textarea>
  </div>
</section>

<section class="sectionCreateForm">
  <label class="titel_seg">Segmentos:</label>
  <fieldset id="segments" #divSegments></fieldset>
  <button type="button" class="btn btn-info btn-sm" (click)="addSegment()"><i class="fa fa-plus-circle"></i> Segmento</button>
</section>

<section class="sectionCreateForm">
  <label class="titel_seg" style="display: block">Paginas:</label>
  <input type="number" class="col-md-1" id="pages" >
</section>

<section class="sectionCreateForm">
  <label class="titel_seg">Preguntas:</label>
  <fieldset id="questions" #divQuestions></fieldset>
  <button type="button" class="btn btn-info btn-sm" (click)="addQuestion()"><i class="fa fa-plus-circle"></i> Pregunta</button>
</section>

<div class="row justify-content-md-center btn_record">
  <button type="submit" class="col-md-2 btn btn-primary btn-sm btn-block" (click)="onSubmit()">Guardar</button>
</div>
