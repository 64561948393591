import { Component, OnInit } from '@angular/core';
import { FomrsService } from '../../services/fomrs.service';
import { Form } from '../../models/forms.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-list-forms',
  templateUrl: './list-forms.component.html',
  styleUrls: ['./list-forms.component.css']
})
export class ListFormsComponent implements OnInit {

  public dataSesion: any;
  public formData: Form[];

  constructor(private formsServices: FomrsService, private authService: AuthService) {
    this.dataSesion = this.authService.getDataSesion();
  }

  ngOnInit(): void {
    this.getForms();
    this.validateSessionApp();
  }

  getForms(): void {
    this.formsServices.getForms().subscribe(
      response => {
        //console.log(response.resp);
        if (response.correct) {
          this.formData = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }

}
