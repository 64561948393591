
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { CustomersService } from '../../../services/customers.service';
import { Component, OnInit, Output, EventEmitter, Input,Inject, HostListener  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-mdl-prvplc',
  templateUrl: './modal-privacy-policies.component.html',
  styleUrls: ['./modal-privacy-policies.component.css']
})
export class ModalPrivacyPoliciesComponent implements OnInit {
  public chckAcceptPlc: boolean;
  public modalPrvPlc: any;
  public url;
  public politics;

  @Output() PrivacyPolicies = new EventEmitter();
  @Output() CloseModalPP = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    switch (event.target.id) {
      case 'chck_acceptPlc':
          const chck_acceptPlc: any = document.getElementById('chck_acceptPlc');
          this.chckAcceptPlc = chck_acceptPlc.checked;
          this.acceptPolicy();
        break;
    }
  }

  constructor(private modalService: NgbModal,
              private authService: AuthService,
              private customer: CustomersService,
              public dialogRef: MatDialogRef <ModalPrivacyPoliciesComponent>,@Inject(MAT_DIALOG_DATA)public message:string) {
    this.chckAcceptPlc = false;
    
  }

  ngOnInit(): void {
    this.url = window.location.href;
    this.customer.getPolitics().subscribe(res => {
      //console.log('respuesta customers', res.resp)
      if(this.url==='http://test.pasaportecovid.online:4200/login'){
        let data = res.resp.filter(element => element.id_customer==2);        
        this.politics = data[0].politics;       
      }
      else{
        let data = res.resp.filter(element => element.id_customer==1);       
        this.politics = data[0].politics;
      }      
    });
   
    
  }

  ngAfterViewChecked(): void {
    this.acceptPolicy();
  }

  acceptPolicy(): void {
    const btn_accept: any = document.getElementById('btn_accept');
    if (!this.chckAcceptPlc) {
      btn_accept.setAttribute('disabled', 'false');
    } else {
      btn_accept.removeAttribute('disabled');
      btn_accept.focus();
    }
  }

  closeModal(){
    this.dialogRef.close();
   }

  privacyPolicies(event, acceptPlc): void {
 
    //console.log('acceptPlc',acceptPlc)
    this.dialogRef.close(acceptPlc);
  } 

}
