<!-- <div id="mdlConfMeeting" #modalPrvPlc class="mdlConfMeeting modalSize" >

  <div class="modal-header">
    <h3 class="modal-title modal_title" id="mdl-Meeting">Confirmar Cita</h3>
    <button type="button" class="close" aria-label="Close" (click)="closeMdlConfMeeting()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <div class="container">
        <p class="msgConfMeeting" id="msgConfMeeting" #msgConfMeeting>
          {{Text}}<br>
          {{textFecha}}<br>
          {{textHora}}
        </p>
        <div *ngIf="Text!='Cita aceptada ✔️'" >
          <button type="button" id="btn_conf_meenting" class="btn btn-sml btn_meenting" (click)="confirmMeeting('confirmed')">CONFIRMAR</button>
        </div>
        <br>
        <div  *ngIf="Text!='Cita aceptada ✔️'" >
          <button type="button" id="btn_conf_meenting" class="btn btn-sml btn_meenting" (click)="confirmMeeting('expired')">RECHAZAR</button>
        </div>
      </div>
  </div>
</div> -->

<div mat-dialog-content>{{message}}</div>
<div mat-dialog-actions>
  <div class="modal-body">
    <div class="container">
      <p class="msgConfMeeting" id="msgConfMeeting" #msgConfMeeting>
        {{Text}}<br>
        {{textFecha}}<br>
        {{textHora}} ({{timeZone}})
      </p>
      <!-- <div *ngIf="Text!='Cita aceptada ✔️'" >
        <button type="button" id="btn_conf_meenting" class="btn btn-sml btn_meenting" (click)="confirmMeeting('confirmed')">CONFIRMAR</button>
      </div> -->
      <div *ngIf="Text!='Cita aceptada ✔️'" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="confirmMeeting('confirmed')">Confirmar</button>
              <!-- (click)="confirmMeeting('expired') -->
      </div> 
      <div *ngIf="Text!='Cita aceptada ✔️'" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" style="margin-top: 5%;">
        <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="confirmMeeting('expired')">Rechazar</button>      
              <!-- (click)="confirmMeeting('expired') -->
      </div> 
      
    </div>
</div>
</div>
