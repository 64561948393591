import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';
​
@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  TabData;
  userRescheduled

  Date;
  Time;
  Zone;

  statusMeet;

​
  constructor( private http: HttpClient ) { }
​
  getMeetings(): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.meeting}`);
  }
​
  getByIdMeeting(id): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getMeeting}/${id}`);
  }
​
  getByKeyMeeting(key): Observable<any> {    
    return  this.http.get(`${globalData.urls.rootURI}${globalData.urls.getKeyMeeting}/${key}`);  
  }
​
  confirmMeeting(data): Observable<any> {
    const body: any = JSON.stringify(data);
    //console.log('body'+ body)
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(`${globalData.urls.rootURI}${globalData.urls.updMeeting}`, body, {headers: headers});
  }
  
​
  createMeeting(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtMeeting}`, body, {headers: headers});
  }

  sendMailToDoctor(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.sendMailToDoctor}`, body, {headers: headers});
  }
  sendMailCredentialsToUser(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.sendCredentialsMailUser}`, body, {headers: headers});
  }

  rescheduledMeeting(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(`${globalData.urls.rootURI}${globalData.urls.resMeeting}`, body, {headers: headers});
  }
  
  TabValue(valor){
    this.TabData=valor;
  }​
  userRescheduledFuntion(value){
    this.userRescheduled=value
  }

  GetDate(fecha,hora,zone){
    this.Date= fecha;
    this.Time = hora;
    this.Zone = zone;
  }

  StatusMeeting(data){
     this.statusMeet=data
  }

 

}