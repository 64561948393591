import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { AlarmSimple } from '../../../models/alarm';
import { AlarmsService } from '../../../services/alarms.service';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-alarmas-forms',
  templateUrl: './alarmas-forms.component.html',
  styleUrls: ['./alarmas-forms.component.css']
})
export class AlarmasFormsComponent implements OnInit {

  private alarms: AlarmSimple[] = [];
  pendingAlarms: AlarmSimple[] = [];
  resolvedAlarms: AlarmSimple[] = [];

  constructor(
    private loadingService: SpinnerService,
    private alarmsService: AlarmsService,
    private userService: UsersService,
  ) {
    this.loadingService.updateSpinnerState(true);
    this.userService.setOnCreatePatient(true);
    this.alarmsService.list().subscribe(
      response => {
        console.log(response.resp);
        this.alarms = response.resp;
        console.log(this.alarms);
        this.pendingAlarms = this.alarms.filter(alarm => alarm.status == 'Pendiente');
        this.resolvedAlarms = this.alarms.filter(alarm => alarm.status == 'Resuelta').sort((a,b) => b.checked_date - a.checked_date);
        this.loadingService.updateSpinnerState(false);
      }, error => {
        this.loadingService.updateSpinnerState(false);
      }
    );
  }

  ngOnInit(): void {
  }

}
