<p class="titel_form">Edit forms number {{formData}}!</p>
<!--
<div *ngFor="let structure of formData.structure">

  {{segment.name}}

  <ul>
    <li *ngFor="let question of segment.questions">

      <ul [ngSwitch]="question.type">
        <li *ngSwitchCase="'Text'">
            <app-text
              [dtaQuestions]="question"
            ></app-text>
        </li>
        <li *ngSwitchCase="'Textarea'">
          Textarea
        </li>
        <li *ngSwitchCase="'Radio'">
          Radio
        </li>
        <li *ngSwitchCase="'Checkbox'">
          Checkbox
        </li>
        <li *ngSwitchCase="'Combobox'">
          Combobox
        </li>
      </ul>

    </li>
  </ul>

</div> -->
