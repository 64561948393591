<div mat-dialog-actions id="mdlMeeting">
    <div *ngIf="Sendbtn" class="alert alert alert-success alert-dismissible fade show" style="padding: 2%; width: 100%;" role="alert">
        Agendando cita ...
        <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div *ngIf="!Sendbtn" style="width: 110%;">
        <div class="modal-header">
            <h3 class="modal-title modal_title" id="mdl-Meeting">Datos de cita</h3>
            <button type="button" class="close" aria-label="Close" (click)=" closeDialog()">
               <span aria-hidden="true">&times;</span>
               </button>
        </div>
        <div class="modal-body">
            <form class="frmMeeting">
                <div class="container">
                    <div class="row">
                        <label for="example-datetime-local-input" class="col-4 col-form-label">Fecha</label>
                        <div class="col-8">
                            <input placeholder="dd/mm/aaaa" type="date" name="date" id='date' #date_time="ngModel" [(ngModel)]="meetingForm.date" [min]="minDate" [max]="maxDate">
                        </div>
                    </div>
                    <div class="row">
                        <label for="example-datetime-local-input" class="col-4 col-form-label">Hora</label>
                        <div class="col-8">
                            <input type="time" name="time" id='time' #time="ngModel" [(ngModel)]="meetingForm.time" [min]="minHour">
                        </div>
                    </div>
                    <div *ngIf="msgError" class="modal-header">
                        <h3 class="modal-title modal_title" id="mdl-Meeting" style="color: rgb(179, 7, 7);">
                            La fecha y hora seleccionada ya ha sido agendada en otra cita
                            <span class="material-icons">              
              </span></h3>
                    </div>
                    <div class="row">
                        <div class="col-sm-10">
                            <div class="form-group">
                                <label for="pre-diagnosis" style="padding-top: 20px;">Tipo de cita:</label>
                                <div class="form-check ">
                                    <div style="display: inline-flex;">
                                        <input class="form-check-input check_input" type="radio" name="appointment" id='appointment' #appointment="ngModel" [(ngModel)]="meetingForm.appointment" value="zoom">
                                        <label class="form-check-label check_label" for="exampleRadios1">Zoom</label>
                                    </div>
                                </div>
                                <div class="form-check ">
                                    <div style="display: inline-flex;">
                                        <input class="form-check-input check_input" type="radio" name="appointment" id='appointment' #appointment="ngModel" [(ngModel)]="meetingForm.appointment" value="face-to-face">
                                        <label class="form-check-label check_label" for="exampleRadios2">Presencial</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <p class="msgform" id="msgform" #msgform></p>
            </form>
        </div>
        <div *ngIf="!btnReag" class="modal-footer">
            <button mat-button class="btn btn-sml btn_meenting" (click)="sendMeeting($event)">Guardar cita</button>
        </div>
        <div *ngIf="btnReag" class="modal-footer">
            <button mat-button class="btn btn-sml btn_meenting" (click)="sendMeetingRescheduled($event)">Reagendar</button>
        </div>
    </div>

</div>