import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PingMiddlewareComponent } from './components/ping-middleware/ping-middleware.component';
import { ListFormsComponent } from './components/list-forms/list-forms.component';
import { PreviewFormsComponent } from './components/preview-forms/preview-forms.component';
import { CreateFormsComponent } from './components/create-forms/create-forms.component';
import { EditFormsComponent } from './components/edit-forms/edit-forms.component';
import { ListSettingFormsComponent } from './components/list-setting-forms/list-setting-forms.component';
import { CreateSettingFormComponent } from './components/create-setting-form/create-setting-form.component';
import { EditSettingFormComponent } from './components/edit-setting-form/edit-setting-form.component';
import { ListCustomerFormsComponent } from './components/list-customer-forms/list-customer-forms.component';
import { CreateCustomerFormComponent } from './components/create-customer-form/create-customer-form.component';
import { EditCustomerFormComponent } from './components/edit-customer-form/edit-customer-form.component';
import { ListRespondentFormsComponent } from './components/list-respondent-forms/list-respondent-forms.component';
import { CreateRespondentFormComponent } from './components/create-respondent-form/create-respondent-form.component';
import { EditRespondentFormComponent } from './components/edit-respondent-form/edit-respondent-form.component';
import { MedicalDashboardComponent } from './components/medical-dashboard/medical-dashboard.component';
import { ZoomMeetingComponent } from './components/zoom-meeting/zoom-meeting.component';
import { TeamComponent } from './components/team/team.component';
import { PrivacyPoliciesComponent } from './components/privacy-policies/privacy-policies.component';
import { MeetingConfirmationComponent } from './components/meeting-confirmation/meeting-confirmation.component';

import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { NotAuthGuard } from './guard/not-auth.guard';
import { AdminGuard } from './guard/admin.guard';
import { DoctorGuard } from './guard/doctor.guard';
import { CustomerGuard } from './guard/customer.guard';
import { DiagnostedComponent } from './components/diagnosted/diagnosted.component';
import { MyQrComponent } from './components/my-qr/my-qr.component';
import { GuardPageComponent } from './components/guard-page/guard-page.component';
import { FormRespodentComponent } from './components/form-respodent/form-respodent.component';
import { FinalizarPageComponent } from './components/finalizar-page/finalizar-page.component';
import { PatientConsultsComponent } from './components/patient-consults/patient-consults.component';
import { CreatePatientComponent } from './components/patient/create-patient/create-patient.component';
import { ExternalAuthenticationComponent } from './components/external-authentication/external-authentication.component';
import { AlarmasFormsComponent } from './components/alarmas/alarmas-forms/alarmas-forms.component';
import { AlarmViewComponent } from './components/alarmas/alarm-view/alarm-view.component';
import { CreateSurgiaiPatientComponent } from './components/patient/create-surgiai-patient/create-surgiai-patient.component';
import { ListSpecialistFormsComponent } from './components/list-specialist-forms/list-specialist-forms.component';

​
const appRoutes: Routes = [
  { path: 'list-specialist-forms', component: ListSpecialistFormsComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'ping-middleware', component: PingMiddlewareComponent },
  { path: 'my-qr', component: MyQrComponent, canActivate: [AuthGuard] },
  { path: 'guard-page', component: GuardPageComponent, canActivate: [DoctorGuard] },
  // { path: '', component: ListRespondentFormsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'list-forms', component: ListFormsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'preview-forms/:id', component: PreviewFormsComponent },
  { path: 'create-forms', component: CreateFormsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'edit-forms/:id', component: EditFormsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'list-setting-form', component: ListSettingFormsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'create-setting-form', component: CreateSettingFormComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'edit-setting-form/:id', component: EditSettingFormComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'list-customer-form', component: ListCustomerFormsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'create-customer-form', component: CreateCustomerFormComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'edit-customer-form/:id', component: EditCustomerFormComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'list-respondent-form', component: ListRespondentFormsComponent, canActivate: [AuthGuard] },
  { path: 'create-respondent-form', component: CreateRespondentFormComponent, canActivate: [AuthGuard, CustomerGuard] },
  { path: 'edit-respondent-form/:id', component: EditRespondentFormComponent, canActivate: [AuthGuard] },
  { path: 'medical-dashboard', component: MedicalDashboardComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'patient-consults', component: PatientConsultsComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'patient/new', component: CreatePatientComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'patient/new-surgiai', component: CreateSurgiaiPatientComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'finalizar-page', component: FinalizarPageComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'zoom-meeting/:id', component: ZoomMeetingComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'diagnosted/:id', component: DiagnostedComponent, canActivate: [AuthGuard, DoctorGuard] },
  { path: 'form-respodent/:id', component:  FormRespodentComponent },
  { path: 'team', component: TeamComponent, canActivate: [AuthGuard] },
  { path: 'privacy-policies', component: PrivacyPoliciesComponent, canActivate: [AuthGuard] },
  { path: 'meeting-confirmation/:key', component: MeetingConfirmationComponent },
  { path: 'login', component: LoginComponent, canActivate: [NotAuthGuard] },
  { path: 'cxe/login', component: LoginComponent, canActivate: [NotAuthGuard] },
  { path: 'cas/login', component: LoginComponent, canActivate: [NotAuthGuard]},
  { path: 'caf/login', component: LoginComponent, canActivate: [NotAuthGuard] },
  { path: 'crs/login', component: LoginComponent, canActivate: [NotAuthGuard]},
  { path: 'a/:user/:pass/:id_customer', component: ExternalAuthenticationComponent },
  { path: 'alarmas', component: AlarmasFormsComponent, canActivate: [AuthGuard] },
  { path: 'alarmas/:id', component: AlarmViewComponent, canActivate: [AuthGuard] },
  { path: '**', component: ListRespondentFormsComponent, canActivate: [AuthGuard]},
];
​
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy'} )],
  exports: [RouterModule]
})
export class AppRoutingModule {}
