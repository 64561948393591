import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';

@Injectable({
  providedIn: 'root'
})
export class SettingFomrsService {

  constructor( private http: HttpClient ) { }

  getSetForms(): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.setFrm}`);
  }

  getByIdSetForm(id): Observable<any> {
      return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getSetFrm}/${id}`);
  }

  createSetForm(data): Observable<any> {
    //console.log(data);
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtSetFrm}`, body, {headers: headers});
  }

  // update generic
  // udate status
  // delete
}
