import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { globalData } from '../../../global/global_data';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {
  public maxFileSize: number;
  public allowedExt: any;
  private sizeFile: number;
  private extFile: string;
  @Input() public dtaQuestions: any;
  @Output() FileMessage = new EventEmitter();

  constructor() {
    this.maxFileSize = globalData.sizeFile;
    this.allowedExt = globalData.allowedExt;
  }

  ngOnInit(): void { }

  validityExt(extFile): boolean {
    let valid = false;
    const validExt = this.allowedExt.includes(extFile);
    if (validExt !== undefined) {
      valid = true;
    }
    return valid;
  }

  fileMessage(msg): void {
    this.FileMessage.emit({
      msg
    });
  }

  onFileChange(event): void {
    console.log('onFileChange',event);
    let msg = '';
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const typeFile = file.type.split('/')[1];
      console.log('typeFile',typeFile);
      if(typeFile == 'pdf'){
        localStorage.setItem('pdfReady','true');
      }else{
        alert('Debe subir un archivo PDF para continuar');
        msg = `Tipo de archivo '${typeFile}' no permitido. Debe ser: .pdf`;
      }
      
      

      if (file.size > this.maxFileSize) {
        msg = 'El archivo supera el máximo permitido de 5Mb';
      }

      const fileExt = this.allowedExt.find(element => element === 'pdf');
      if (fileExt === undefined) {
        msg = `Tipo de archivo '${typeFile}' no permitido. Debe ser: .pdf`;
      }
      this.fileMessage(msg);
    }
  }

}
