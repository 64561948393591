<div class="">

  <label style="color: rgb(119, 118, 118);" class="lbl-question">{{dtaQuestions.name}}</label>

  <div class="form-group">
    <input type="file" class="presenter form-control-file" id="lbl-{{dtaQuestions.name}}" name="{{dtaQuestions.name}}" value="" (change)="onFileChange($event)">
    <samp id="fileMessage" style="color: orangered;"></samp>
  </div>

</div>
