<div id="mdlMessage" #modalMessage class="mdlMessage">

  <div class="modal-header">
    <h3 class="modal-title modal_title" id="mdl-Meeting">{{titleMessage}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="closeMdlMessage()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form class="frmMeeting">
      <div class="container">
        <p class="msg" id="msg" #msg>{{message}}</p>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sml btn_message" (click)="closeMdlMessage()">Cerrar</button>
  </div>

</div>
