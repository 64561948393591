import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RespondentForm } from 'src/app/models/respondent_form.model';
import { AuthService } from 'src/app/services/auth.service';
import { RespondentFormsService } from 'src/app/services/respondent-forms.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UsersService } from 'src/app/services/users.service';
import { FormRespodentComponent } from '../form-respodent/form-respodent.component';
import { LoaderComponent } from '../_presenters/loader/loader.component';
import { CustomersFormsService } from 'src/app/services/customers-forms.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-list-specialist-forms',
  templateUrl: './list-specialist-forms.component.html',
  styleUrls: ['./list-specialist-forms.component.css']
})
export class ListSpecialistFormsComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);

  public dataSesion: any;
  public respForms: RespondentForm[];
  public title: string;
  public userName: string;
  public fecha: string;
  public dataSend : any;
  public header:any;
  public customerForm: any;
  public respondentForms: any;
  public formToSendData:any
  public dataFormonhold: any;
  public dataFormRespondent: any;
  public filterAndChangeCreateOnhold:any;
  public filterAndChangeCreateRespondent:any

  constructor(
    private respondentFormsService: RespondentFormsService,
    private authService: AuthService,
    private router: Router,
    private userServi: UsersService,
    private customerService: CustomersFormsService,
    private generalService: GeneralService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog
    ) 
  {

    localStorage.setItem('MyQrPage','false');
    this.dataSesion = this.authService.getDataSesion();
    console.log('this.dataSesion',this.dataSesion);
    this.userName = localStorage.getItem('name');
    localStorage.setItem('EditMode','false');
    
    this.dataSend = {
      patient: 1003484092,
      json: {
        
      }
    }   

    this.formToSendData= {
      nameForm: null,//'hola',
      description:  null,//'hola',
      customer_id: null,//2,
      created_on : null,
      // strStruc:  null,//'hola',
      structure: null,//'hola',
      answered_check : null,
      respondents_id: null,//229,
      custf_id: null,//3,
      status: 'onhold' ,
      specialist: null               
    }
  }

  ngOnInit(): void {
    console.log('datos de usuario',this.dataSesion)
    // if((this.dataSesion.role == 'doctor') && this.dataSesion.logical_medical !=' urology'){
    //   console.log('entro a if')
    //   this.getRespodentFormsByCustomer()
    // }else{
    //   console.log('OTRO ===================');
      this.getRespForms();
    // }
    
    this.getDataCustomer();
    this.generalService.refreshLoginState();
    this.spinnerService.updateSpinnerState(false);
  }

  openSAlert(): void {
    const dialogRef = this.dialog.open( LoaderComponent,{
      width:'350px',
      data:'Generando nuevos formularios...',
      disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{      

    })    
   }

  previewFormRespondentDialog(id): void {
   const dialogRef = this.dialog.open(FormRespodentComponent,{
     width:'1000px',height:'500px',
     data:id,
     // disableClose: false
   });    
   dialogRef.afterClosed().subscribe(res=>{})    
  }
 getRespodentFormsByCustomer(){
   this.respondentFormsService.getRespForms(this.dataSesion.customerId).subscribe(
    resp => {
      console.log('respuesta forms',resp.resp)
      this.respondentForms = resp.resp
      if (resp.correct) {
        switch (this.dataSesion.role) {
        
          case 'specialist':
              this.respForms = resp.resp.filter(element => element.specialist_id ==this.dataSesion.id);
              console.log('this.respForms',this.respForms)
              let dataFilter = this.respForms.filter(element => element.status =='answered');
              this.dataFormRespondent = this.respForms;
              console.log('this.respFormsaadsadasdasd',this.dataFormRespondent)
              this.filterAndChangeCreateRespondent = this.dataFormRespondent.map(data=>{
                console.log('data Map', data);                                      
                let dataMap =data;
                const d: Date = new Date();
                d.setTime(dataMap.created_on);
                const n = d.toLocaleDateString();
                console.log('data Map fecha respondent', n);
                dataMap.created_on=n    
                return dataMap;          
              }
              );
              console.log('data Map', this.filterAndChangeCreateRespondent);
            break;
        }
      }
    },
    error => {
      console.log(error);
    }
   );
 }

  getRespForms(): void {// switch previo, paginar, si el
    console.log(this.dataSesion.id);
    this.respondentFormsService.getRespForms(this.dataSesion.customerId).subscribe(
      resp => {
        console.log('respuesta forms',resp.resp)
        this.respondentForms = resp.resp
        if (resp.correct) {
          switch (this.dataSesion.role) {
            case 'admin':
            case 'customer':
              break
            case 'specialist':
                console.log('entro a specialist');
                this.respForms = resp.resp;
                this.dataFormonhold =  this.respForms;
                break;
            case 'doctor':
            case 'patient':
            case 'respondent':
              this.respForms = resp.resp.filter(element => element.specialist_id ==this.dataSesion.id);
                console.log('this.respForms',this.respForms)
                let dataFilter = this.respForms.filter(element => element.status =='answered' || element.status =='diagnosed' || element.status == 'aforementioned'|| element.status == 'expired'|| element.status == 'answered/onhold'||element.status == 'rescheduled');
                this.dataFormRespondent = dataFilter;
                let dataOnhold = this.respForms.filter(element => element.status =='onhold' ||  element.status == 'onhold/onhold' || element.status == 'onhold/answered');
                this.dataFormonhold =  dataOnhold;
                console.log('dataFormonhold ',this.dataFormonhold);
                this.filterAndChangeCreateRespondent = this.dataFormRespondent.map(data=>{
                  // console.log('data Map', data.activation_date);                                      
                  // let dataMap = data;
                  // const fechaParsed: Date = new Date(dataMap.activation_date * 1000);
                  // console.log('data Map fecha respondent', fechaParsed);
                  // dataMap.activation_date = fechaParsed; 

                  return data;          
                }
                );
                console.log('data Map', this.filterAndChangeCreateRespondent); 

                this.filterAndChangeCreateOnhold = this.dataFormonhold.map(data=>{
                  // console.log('data Map onhold', data);                                      
                  // let dataMap =data;
                  // const fechaParsed: Date = new Date(dataMap.activation_date * 1000);
                  // console.log('data Map', fechaParsed); 
                  // dataMap.activation_date = fechaParsed;    
                  return data;
                }
                );


              break;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }
  prueba(){
    this.userServi.dataSend(this.dataSend).subscribe(res=>{
      console.log('resuesta alastria',res)
    })
  }

  getDataCustomer(){
    this.customerService.getByIdCustForm(this.dataSesion.customerId).subscribe(res=>{ // poner id de canal this.dataSesion.customerId
      // console.log('Parse periodic',res.resp[0].periodic);
      // console.log('Customer_forms', res.resp)  
      console.log('*******************primero ingresa al getDataCustomer*****************') 
      this.customerForm = res.resp;
      if(this.dataSesion.logical_medical!='urology' && this.dataSesion.role =='respondent'){
        // this.createFormByPeriodic()
        // this.createFormByMultiPeriodic();
      }
  
    }),err => console.log('error en getDataCUstomer',err)
  }

}
