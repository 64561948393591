export const dataConfig = {
  srvMiddl: {
      host: 'api-pre-001.eprocessmed.work',
      port: 3000
  },
  srvMiddlTest: {
    host: '173.249.10.18',
    port: 3000
  },
  srvMiddlDev: {
    host: 'localhost',
    port: 3000
  },
  filePro:{
    url:'api-bck-001.e-processmed.com:16630'
  },
  fileMiddle:{
    url:'http://173.249.10.18:6630'
  },
  fileDev:{
    url:'http://173.249.10.18:6630'
  },
  http: 'http://',
  https: 'https://',
  environment: 'Production', // Development || Testing || Production
  ssl: false  // false || true 
};
    
