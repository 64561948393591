<!-- <mat-toolbar style="background:#00669A;">
    <div class="pointer" style="color: #00669A; background: rgb(255, 255, 255); border-radius: 50mm; width: 3%;" [routerLink]="['/']">
        <span style="margin-left: 20%; margin-top: 20%;" routerLinkActive="router-link-active" class="material-icons">
      keyboard_backspace
      </span>
    </div>
    <div class="pointer" [routerLink]="['/']" style="margin-left: 1%; color: rgb(255, 255, 255);">
        Regresar al tablero médico
    </div>

</mat-toolbar> -->
<!-- <div style="font-size: 25px; color: #00669A; margin-top: 5%; margin-bottom: 5%;">
    Paciente: <b>{{actualPatient}}</b>
   </div> -->
<div style="position: absolute; width: 85%; height: 100%;background: #98242400; z-index: 2; opacity: 0.3; margin-left: 0%;">
</div>
<div class="container-fluid">
    <div class="container-fluid">

        <div class="row">
            <div *ngIf="dataSesion.logical_medical=='urology'" class="card col-sm-12" >
                <div >
                   
                    <label   for="diagnosis" style="padding-top: 20px;">Puntuación total IPSS:</label>   
                   
                    <br>     
                    <label *ngIf="resultPatient=='' || resultPatient == 'NaN-undefined' || resultPatient == 'null'" style="font-size: 18px;"> No existe información del formulario IPSS</label>
                     <label *ngIf="resultPatient!=''&& resultPatient != 'NaN-undefined' && resultPatient != 'null'" style="font-size: 40px;"> {{resultPatient}}</label>
                </div>
            </div>

            <div class="card col-sm-12">

                <div class="overflow-auto card-body">
                    <p class="titel_form">{{respondentForm.name}}</p>

                    <form class="respondent_form" disabled="true" id="editRespondentForm" #editRespondentForm="ngForm" (ngSubmit)="onSubmit()">

                        <section *ngFor="let segment of structure; let i = index;">

                            <div *ngFor="let page of segment.pages; let j = index;">

                                <div *ngIf="i == 0 && j == 0; then firstDisplay else followingDisplay"></div>

                                <ng-template #firstDisplay>
                                    <div class="tab tab0">
                                        <p class="desc_form">{{respondentForm.description}}</p>
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>

                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question" [index]="j"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <app-checkbox [dtaQuestions]="question"></app-checkbox>
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'">
                                                        <!-- <pdf-viewer src="https://vadimdez.github.io/ng2-pdf-viewer/pdf-test.pdf"></pdf-viewer>                                                      
                                                        <app-file [dtaQuestions]="question"></app-file> -->
                                                        <label style="color: rgb(119, 118, 118);">
                                                            {{question.name}}                                                        
                                                        </label>
                                                        <!-- <iframe id="pdfViewer" frameborder="0" width="800"></iframe> -->
                                                          <button style="z-index: 999999;" class="btn " style="background-color: #00669A; color: #fff;" type="button"
                                                            (click)="open(content,question.answers)">ver pdf</button>
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">
                                                        <app-date [dtaQuestions]="question"></app-date>
                                                    </li>
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>

                                                </ul>

                                            </li>

                                        </ul>

                                    </div>

                                </ng-template>

                                <ng-template #followingDisplay>
                                    <div class="tab">
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>

                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <app-checkbox [dtaQuestions]="question"></app-checkbox>
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'" >
                                                        <!-- <pdf-viewer src="https://vadimdez.github.io/ng2-pdf-viewer/pdf-test.pdf"></pdf-viewer>
                                                      
                                                        <app-file [dtaQuestions]="question"></app-file> -->
                                                        <label style="color: rgb(119, 118, 118);">
                                                            PDF adjunto:                                                        
                                                        </label>
                                                        <br>
                                                        <!-- <iframe id="pdfViewer" frameborder="0" width="800"></iframe> -->
                                                          <button style="z-index: 999999;" class="btn " style="position: absolute; z-index: 1000;    background-color: #00669A; color: #fff;" type="button"
                                                            (click)="open(content,question.answers)">ver pdf</button>

                                                            <div style="margin-top: 15%;">

                                                            </div>
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">
                                                        <app-date [dtaQuestions]="question"></app-date>
                                                        <!-- <label id="lbl-{{question.name}}" class="lbl lbl-question"for="{{question.name}}">{{question.name}}</label>
                                                        <input placeholder="dd/mm/aaaa" type="date" name="date" id='date' [(ngModel)]="date">                                                         -->
                                                    </li>
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Subtitle'" style="margin-top: 5%;">
                                                        <h1 style="color: #00669A;">{{question.name}} </h1>
                                                    </li>
                                                </ul>

                                            </li>

                                        </ul>

                                    </div>
                                    <!-- <div class="card col-sm-12" *ngIf="dataSesion.logical_medical=='urology'">
              
                                        <div class="">
                                            <label id="lbl" class="lbl lbl-question">Comentarios</label>
                                                                <textarea placeholder="{{Presuncion}}" disabled [(ngModel)]="diagnosis" class="form-control" id="diagnosis" rows="3"></textarea>
                        
                                          </div>
                                          
                                    </div> -->
                                </ng-template>
                                

                            </div>
                            

                        </section>

                        <div style="text-align:center;margin-top:40px;">
                            <span class="step" *ngFor="let pag of pages;"></span>
                        </div>

                    </form>
                </div>

            </div>


            <div class="card col-sm-12">
                <div class="form-group">
                    <label *ngIf="dataSesion.logical_medical!='urology'" for="diagnosis" style="padding-top: 20px;">Anamnesis:</label>
                    <label *ngIf="dataSesion.logical_medical=='urology'" for="diagnosis" style="padding-top: 20px;">Observaciones</label>
                    <textarea placeholder="{{diagnosisText}}" disabled [(ngModel)]="diagnosis" class="form-control" id="diagnosis" rows="8"></textarea>
                </div>
            </div>


            <div *ngIf="dataSesion.logical_medical!='urology'" class="card col-sm-12">
                <div class="form-group">
                    <label for="presumption" style="padding-top: 20px;">Presunción diagnóstica:</label>
                    <textarea placeholder="{{Presuncion}}" disabled [(ngModel)]="diagnosis" class="form-control" id="diagnosis" rows="3"></textarea>
                    <div *ngIf="warnin">
                        <label style="color: red;"> Eliga una opcion*</label>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>


<div class="container-fluid">
    <div class="container-fluid">

        <div class="row">

            <div class="card col-sm-12">

                <div class="overflow-auto card-body">
                    <p class="titel_form">{{aditionalForm.name}}</p>

                    <form class="respondent_form" disabled="true" id="editRespondentForm" #editRespondentForm="ngForm" (ngSubmit)="onSubmit()">

                        <section *ngFor="let segment of structureFormAditional; let i = index;">
                            <div *ngIf="segment.pages != null">
                                <div *ngFor="let page of segment.pages; let j = index;">

                                    <div *ngIf="i == 0 && j == 0; then firstDisplay else followingDisplay"></div>
    
                                    <ng-template #firstDisplay>
                                        <div class="tab tab0">
                                            <p class="desc_form">{{respondentForm.description}}</p>
                                            <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>
    
                                            <ul>
                                                <li class="li_question" *ngFor="let question of page;">
    
                                                    <ul [ngSwitch]="question.type">
                                                        <li *ngSwitchCase="'Text'">
                                                            <app-text-number [dtaQuestions]="question" [index]="j"></app-text-number>
                                                        </li>
                                                        <li *ngSwitchCase="'Number'">
                                                            <app-text-number [dtaQuestions]="question"></app-text-number>
                                                        </li>
                                                        <li *ngSwitchCase="'Textarea'">
                                                            <app-textarea [dtaQuestions]="question"></app-textarea>
                                                        </li>
                                                        <li *ngSwitchCase="'Radio'">
                                                            <app-radio [dtaQuestions]="question"></app-radio>
                                                        </li>
                                                        <li *ngSwitchCase="'Checkbox'">
                                                            <app-checkbox [dtaQuestions]="question"></app-checkbox>
                                                        </li>
                                                        <li *ngSwitchCase="'Combobox'">
                                                            <app-combobox [dtaQuestions]="question"></app-combobox>
                                                        </li>
                                                        <li *ngSwitchCase="'Email'">
                                                            <app-email [dtaQuestions]="question"></app-email>
                                                        </li>
                                                        <li *ngSwitchCase="'File'">
                                                           
                                                            <label style="color: rgb(119, 118, 118);">
                                                                {{question.name}}                                                        
                                                            </label>
                                                           
                                                              <button style="z-index: 999999;" class="btn " style="background-color: #00669A; color: #fff;" type="button"
                                                                (click)="open(content,question.answers)">ver pdf</button>
                                                        </li>
                                                        <li *ngSwitchCase="'Tel'">
                                                            <app-tel [dtaQuestions]="question"></app-tel>
                                                        </li>
                                                        <li *ngSwitchCase="'Datepicker'">
                                                            <app-date [dtaQuestions]="question"></app-date>
                                                        </li>
                                                        <li *ngSwitchCase="'Image'">
                                                            <app-image [dtaQuestions]="question"></app-image>
                                                        </li>
                                                        <li *ngSwitchCase="'Radio-complement'">
                                                            <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                        </li>
                                                        <li *ngSwitchCase="'Checkbox-complement'">
                                                            <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                        </li>
                                                        <li *ngSwitchCase="'Subtitle'" style="margin-top: 5%;">
                                                            <h1 style="color: #00669A;">{{question.name}} </h1>
                                                        </li>
    
                                                    </ul>
    
                                                </li>
    
                                            </ul>
    
                                        </div>
    
                                    </ng-template>
    
                                    
    
                                </div>
                            </div>
                            

                        </section>

                    

                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #content let-modal class="modal" >
    <div class="modal-header" >  
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cerrar')">Cerrar</button>

    </div>
    <div >
      <pdf-viewer
      src="{{urlPDF}}"
      [rotation]="0"
        [fit-to-page]="true"
        [original-size]="true"
        [show-all]="true"
        [zoom]="1.5"
        style="display: block;"
    ></pdf-viewer>
    </div>
    <div class="modal-footer" style="margin-top: 100%;">
     
    </div>
  </ng-template>