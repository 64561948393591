import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomersFormsService } from '../../../services/customers-forms.service';
import { LoaderComponent } from '../loader/loader.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-modal-edit-pereodic-forms',
  templateUrl: './modal-edit-pereodic-forms.component.html',
  styleUrls: ['./modal-edit-pereodic-forms.component.css']
})
export class ModalEditPereodicFormsComponent implements OnInit {

  public minDate
  public maxDate;
  public formProgram: any;
  public PereodicBoolP
  public PereodicBoolS
  public toogleCheckedP = false;
  public toogleCheckedS = true;
  public structure = []
  public checks
  // public days: Array<Object> = [
  //   { nombre: "lunes", checked: false },
  //   { nombre: "martes", checked: false },
  //   { nombre: "miércoles", checked: false },
  //   { nombre: "jueves", checked: false },
  //   { nombre: "viernes", checked: false },
  //   { nombre: "sábado", checked: false },
  //   { nombre: "domingo", checked: false }
  // ];
  

  constructor(public dialogRef: MatDialogRef <ModalEditPereodicFormsComponent>,@Inject(MAT_DIALOG_DATA)public message:string,
              public cutomerService:CustomersFormsService,
              public dialog: MatDialog,
              public router: Router) { 
    const currentYear = new Date().getFullYear();
    const currentDay = new Date().getDate();
    const currentDayMax = new Date().getDate()+30;   
    const currentMonth = new Date().getMonth();  
    const currentHour = new Date().getHours();
    this.minDate = currentYear + '-' + currentMonth + '-' + currentDay;
    this.maxDate = currentYear + '-' + currentMonth + '-' + currentDayMax;  
    this.formProgram = {
      type: 'pereodic',
      id: 2,
      newStructure: [{
        dateInit:  null,
        dateEnd: null ,
        OneTime: true,
        // time_zone: null,
        days:[
          {check: false},//semanal
          {check: false},//lunes
          {check: false},//martes
          {check: false},//miercoles
          {check: false},//jueves
          {check: false},//viernes
          {check: false},//sabado
          {check: false}//domingo
        ]           
      }],
    
    } 

    
  }

  ngOnInit(): void {
    this.formProgram.id= this.message;
  }

  openModalLoader(): void {
    const dialogRef = this.dialog.open(LoaderComponent,{
      width:'800px',
      // data:data,
    });   
  } 

  closeModal(){
    this.dialogRef.close();
  }

  toggleCheckPeriodic(event){
    //console.log('toggle',event);
    this.PereodicBoolP = event.checked;  
    this.formProgram.OneTime = false;  
    if(this.PereodicBoolP==true){
      this.formProgram.newStructure[0].days[0].check=true;
      this.toogleCheckedP = true
      this.toogleCheckedS = false
      this.PereodicBoolS = false
    }
  }
  toggleCheckNoPeriodic(event){
    console.log('toggle',event);
    this.PereodicBoolS = event.checked;
    this.formProgram.OneTime = true;   
    if(this.PereodicBoolS==true){
      this.formProgram.newStructure[0].days[0].check=false;
      this.toogleCheckedS = true
      this.toogleCheckedP = false      
      this.PereodicBoolP = false
    }
  }
  sendData(){
  //  this.openModalLoader()
    console.log('this.formProgram',this.formProgram);
    this.cutomerService.updatePeriodic(this.formProgram).subscribe(response=>{
      console.log('response',response);     
    },error=>console.log('error',error)
    );
    this.dialogRef.close();  
  }
  allChecks(event){
    console.log('evento',event.target.checked);    
     this.checks = event.target.checked;
  }
  //Solucion, agregar['nombre de variable a modificar']= value
 

}
