<p class="titel_form">Crear formulario paciente:</p>

<form #crtCustForm="ngForm" (ngSubmit)="onSubmit()">

  <div class="form-group">
    <label for="respondent_id">Cliente</label>
    <select class="" id="respondent_id" name="respondent_id" #respondents_id="ngModel" [(ngModel)]="respForms.respondents_id" placeholder="Cliente...">
      <option disabled></option>
      <option *ngFor="let respondent of respondents" [value]="respondent.usr_id">{{respondent.name}}</option>
    </select>
  </div>

  <div class="form-group">
    <label for="custf_id">Formulario</label>
    <select class="" id="custf_id" name="custf_id" placeholder="Formulario...">
      <option *ngFor="let custForm of customerForms; index as i" [value]="i">{{custForm.name}}</option>
    </select>
  </div>

  <div class="form-group row">
    <div class="col-sm-10">
      <button type="submit" class="btn btn-primary">Guardar</button>
    </div>
  </div>

</form>
