import { Component, OnInit,Inject  } from '@angular/core';
import { MeetingService } from '../../services/meeting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalMeetConfComponent } from '../_presenters/modal-meet-conf/modal-meet-conf.component';
import { LoaderComponent } from '../_presenters/loader/loader.component';
import { ModalAlertComponent } from '../_presenters/modal-alert/modal-alert.component';
import { CustomersService } from '../../services/customers.service';
import { ParametersService } from '../../services/parameters.service';
import { element } from 'protractor';





@Component({
  selector: 'app-meeting-confirmation',
  templateUrl: './meeting-confirmation.component.html',
  styleUrls: ['./meeting-confirmation.component.css'],
})
export class MeetingConfirmationComponent implements OnInit {
  private key: any;
  public dataConfMeet: any;
  public alert: boolean;
  public message: string;
  public ErrorMsg;
  public dialogRefLoader;
  public url;
  public Customer;
  public active;
  public customerIdfromUrl  
  public timeZone :any;
  public zone;
  constructor(public dialog: MatDialog,private meetingService: MeetingService, private rutaActiva: ActivatedRoute, private router: Router,
              private customerService: CustomersService,private paramsService: ParametersService) {
    this.dataConfMeet = {
      type: 'status_key',
      key: '',
      status: ''
    };
    this.alert = false;
  }

  ngOnInit(): void {
    this.url = window.location.href;   
    this.key = this.rutaActiva.snapshot.params;
    //console.log(this.rutaActiva.snapshot.params)
    //console.log('this.key ',this.key )
    this.getParams()
    if(this.key!== undefined){
      this.getByKeyMeeting();
      this.getUrl()
    }   
    //console.log('key::::::::::::::::::', this.key.key);  
  }
  getParams(){
    this.paramsService.getParameters().subscribe(res=>{
      //console.log('getParams',res.resp[1].params[0].data)
      this.timeZone = res.resp[1].params[0].data     
    })
  }
  filterByTimeZone(value){
    let dataFilter = this.timeZone.filter(element => element.code==value);
    //console.log('dataFilter',dataFilter[0].timeZone)
    this.zone = dataFilter[0].timeZone
    
  }
  openDialog(): void {
   const dialogRef = this.dialog.open(ModalMeetConfComponent,{
     width:'350px',
     data:'Confirmar cita',
     disableClose: true
   });
   
   dialogRef.afterClosed().subscribe(res=>{    
     //console.log(res);
     if(res==='Aceptado'){
       //console.log('Aceptado')
       this.confirmMeeting()
       this.Loader()
     }
   })
  }
  openAlertModal(): void {
    const dialogRef = this.dialog.open(ModalAlertComponent,{
      width:'350px',
      data:  this.message,
      // disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{    
      // //console.log(res);
      // this.openDialog()
      // this.router.navigate(['/login'])

    })    
   }
  Loader(): void {
      this.dialogRefLoader = this.dialog.open(LoaderComponent,{
      width:'350px',
      data:'Cargando',
      disableClose: true
    });
  }

  getByKeyMeeting(): void {
    this.meetingService.getByKeyMeeting(this.key.key).subscribe(
      resp => {       
        // //console.log('respuesta-customerID',resp.resp[0].request.intPhoneCode) 
        this.filterByTimeZone(resp.resp[0].request.intPhoneCode);       
        this.Customer = resp.resp[0].customer_id;        
          if (resp.correct && this.Customer === this.customerIdfromUrl) {
            try{
              const status = resp.resp[0].status;
              //console.log('status'+ JSON.stringify(resp.resp[0].status)) 
              switch(status){
                case 'onhold':
                    // modal confirmacion confirmar
                    this.dataConfMeet.key = this.key.key;
                    this.openDialog()
                    this.meetingService.GetDate(JSON.stringify(resp.resp[0].request.date),JSON.stringify(resp.resp[0].request.time),this.zone)
                  break;
                case 'confirmed':
                    // mensaje de que ya esta confirmado
                    // this.alert = true;                
                    this.message='Cita confirmada'
                    this.openAlertModal()
                    setTimeout(() => {
                      this.router.navigate(['/login']);
                      this.dialogRefLoader.close()
                    }, 3000);
                   break;
                   case 'expired':
                    // mensaje de que ya esta confirmado
                    // this.alert = true;                  
                    this.message='Cita Rechazada'
                    this.openAlertModal()
                    setTimeout(() => {
                      this.router.navigate(['/login']);
                      this.dialogRefLoader.close()
                    }, 3000);
                  break;
                case 'rescheduled':
                    // mensaje de que ya esta confirmado // mensaje de que ya esta confirmado
                  //  this.alert = true;                
                   this.message='Le han asignado un nuevo link de confirmación.'
                   this.openAlertModal()
                   setTimeout(() => {
                     this.router.navigate(['/login']);
                     this.dialogRefLoader.close()
                   }, 3000);
                   break;               
              }
            }catch(Erro){
              this.alert = true;
              this.message='Link de confirmación incorrecto'
              setTimeout(() => {
                this.router.navigate(['/login']);
              }, 3000);
            }         
          }        
        else{
          //console.log('No exite reunion')
          // this. openAlertModal()
          this.message='No existe reunión'
        }
      }   
    );
  }
//Descomentar
  confirmMeeting(): void {
    this.dataConfMeet.status=this.meetingService.statusMeet
    //console.log('this.dataConfMeet',this.meetingService.statusMeet)
    this.meetingService.confirmMeeting(this.dataConfMeet).subscribe(
      resp => {
        if (resp.correct) {
          
          this.alert = true;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        }
      },
      error => {
        //console.log(error);
      }
    );
  }
  getDateMeeting(): void {
    this.meetingService.confirmMeeting(this.dataConfMeet).subscribe(
      resp => {
        if (resp.correct) {
          
          this.alert = true;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  openMdlConfMeeting(): void {
    const mdlConfMeeting = document.getElementById('mdlConfMeeting');
    mdlConfMeeting.style.display = 'block';
  }

  closeMdlConfMeeting(): void {
    const mdlConfMeeting = document.getElementById('mdlConfMeeting');
    mdlConfMeeting.style.display = 'none';
  }

  getIdByDomain(data){
    this.customerService.getByDomain(data).subscribe(resp=>{
      //console.log('respuesta getByIdDomain',resp);
      this.customerIdfromUrl = resp.resp[0].id_customer;
      //console.log('getIdByDomain',resp.resp[0].id_customer)
      this.getByKeyMeeting()
    })
  } 
  getUrl(){
      //obtenemos dominio de la pagina actual     
      let urlMeeting = this.url;
      let posUrl = urlMeeting.lastIndexOf("/");
      let posNum = posUrl-20;
      let newUrl= this.url.slice(0,posNum);
      //console.log('NewUrl', newUrl);
      var encodedData = window.btoa(newUrl+'login');  
      this.getIdByDomain(encodedData);
  }




}



