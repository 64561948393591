<!-- <div *ngIf formData>


</div> -->

<p>Previsialización de formulario: {{formData.name}}</p>

  <!-- <div *ngFor="let structure of formData.structure">

    {{segment.name}}

    <ul>
      <li *ngFor="let question of segment.questions">

        <ul [ngSwitch]="question.type">
          <li *ngSwitchCase="'Text'">
              <app-text
                [dtaQuestions]="question"
              ></app-text>
          </li>
          <li *ngSwitchCase="'Textarea'">
            Textarea
          </li>
          <li *ngSwitchCase="'Radio'">
            Radio
          </li>
          <li *ngSwitchCase="'Checkbox'">
            Checkbox
          </li>
          <li *ngSwitchCase="'Combobox'">
            Combobox
          </li>
        </ul>

      </li>
    </ul>

  </div> -->

<!-- Al finalizar pagina cerrar sep -->

<!-- En la cargada de datos: Imprimir solo 1 mas al segmento que este lleno completamente y si corresponde al usuario -->


<form>

  <div class="">


      <div class="col-sm">

        <div class="form-group">
          <label for="exampleInputEmail1">Email address</label>
          <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
        </div>

      </div>

      <button type="submit" class="btn btn-primary">Submit</button>

  </div>


</form>
