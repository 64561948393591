import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ModalCreateUSerComponent } from '../_presenters/modal-create-user/modal-create-user.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmDataComponent } from '../_presenters/modal-confirm-data/modal-confirm-data.component';
import { ModalSelectMyQRComponent } from '../_presenters/modal-select-my-qr/modal-select-my-qr.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public dataSesion: any;
  public nameAPP: string;
  public titleListFrms: string;
  public mobilUI;

  constructor(private authService: AuthService,public router:Router,
              public dialog: MatDialog) {                
                if (screen.width < 768 && localStorage.getItem('MyQr')=='true') {
                  this.mobilUI = true;
                }else{
                  this.mobilUI = false;
                }
    this.nameAPP = 'Smart Consent';
    this.titleListFrms = 'Formularios Dinámicos';
    this.dataSesion = this.authService.getDataSesion();
    //console.log('dataSession',this.dataSesion);
    // this.viewChange();
  }

  ngOnInit(): void {
    this.viewChange();
  }

  viewChange(){
    let width = screen.width;
    setTimeout(()=>{
       if( localStorage.getItem('MyQrPage')=='false'){         
        this.mobilUI = false;      
       }
      if( localStorage.getItem('MyQrPage')=='true' && width <= 768){        
        this.mobilUI = true;     
           
       }
       this.viewChange();          
    }, 100);      
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalCreateUSerComponent,{
      width:'500px',
      data:'Confirmar cita',
      disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{       
      if(res){    
        ////console.log(res.userName);  
      }
    })    
   }

   openSelectForm(): void {
    const dialogRef = this.dialog.open(ModalSelectMyQRComponent,{
      width:'350px',
      data:'Confirmar cita',
      disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{       
      if(res){    
        //console.log('openSectData',res);  
      }
    });    
   }

   openDialogConfirmData(): void {
    const dialogRef = this.dialog.open(ModalConfirmDataComponent,{
      width:'350px',
      data:'¿Seguro que desea abandonar el formulario actual?',
      // disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{
    });    
   }

   openModal(){
     //console.log('llego a open Modal');
    if(localStorage.getItem('EditMode')=='false'){
     this.openDialog()
    }
    if(localStorage.getItem('EditMode')=='true'){
     this.openDialogConfirmData()
   }
  }

  

  reloadPage(){
    
    location.reload();
  }




}
