<p class="titel_form">Formularios</p>

<a class="btn btn-info" role="button" [routerLink]="['/create-forms']" >Crear</a>

<div class="table-responsive">

  <table class="table">
    <thead class="" style="background: #00669A;">
      <tr style="color: aliceblue;">
        <th>Id Formulario</th>
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Estructura</th>
        <th>Estatus</th>
        <th>Creación</th>
        <th>Acciones</th>
        <th>Periodicidad</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let form of formData">
        <th>{{form.form_id}}</th>
        <td>{{form.name}}</td>
        <td>{{form.description}}</td>
        <td>{{form.structure}}</td>
        <td>{{form.status}}</td>
        <td>{{form.created_on}}</td>
        <td><a class="btn btn-info" role="button" [routerLink]="['/edit-forms', form.form_id]">Editar</a></td>       
      </tr>
    </tbody>
  </table>

</div>
