import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routes';
import { AuthGuard } from './guard/auth.guard';
import { NotAuthGuard } from './guard/not-auth.guard';
import { AppComponent } from './app.component';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PreviewFormsComponent } from './components/preview-forms/preview-forms.component';
import { TextNumberComponent } from './components/_presenters/text-number/text-number.component';
import { TextareaComponent } from './components/_presenters/textarea/textarea.component';
import { ComboboxComponent } from './components/_presenters/combobox/combobox.component';
import { RadioComponent } from './components/_presenters/radio/radio.component';
import { CheckboxComponent } from './components/_presenters/checkbox/checkbox.component';
import { RadioComplementComponent } from './components/_presenters/radio-complement/radio-complement.component';
import { CheckboxComplementComponent } from './components/_presenters/checkbox-complement/checkbox-complement.component';
import { EmailComponent } from './components/_presenters/email/email.component';
import { TelComponent } from './components/_presenters/tel/tel.component';
import { ImageComponent } from './components/_presenters/image/image.component';
import { DateComponent } from './components/_presenters/date/date.component';
import { FileComponent } from './components/_presenters/file/file.component';
import { FormBuilderDirective } from './directives/form_builder.directive';
import { ListFormsComponent } from './components/list-forms/list-forms.component';
import { CreateFormsComponent } from './components/create-forms/create-forms.component';
import { EditFormsComponent } from './components/edit-forms/edit-forms.component';
import { ListSettingFormsComponent } from './components/list-setting-forms/list-setting-forms.component';
import { CreateSettingFormComponent } from './components/create-setting-form/create-setting-form.component';
import { EditSettingFormComponent } from './components/edit-setting-form/edit-setting-form.component';
import { ListCustomerFormsComponent } from './components/list-customer-forms/list-customer-forms.component';
import { CreateCustomerFormComponent } from './components/create-customer-form/create-customer-form.component';
import { EditCustomerFormComponent } from './components/edit-customer-form/edit-customer-form.component';
import { ListRespondentFormsComponent } from './components/list-respondent-forms/list-respondent-forms.component';
import { CreateRespondentFormComponent } from './components/create-respondent-form/create-respondent-form.component';
import { EditRespondentFormComponent } from './components/edit-respondent-form/edit-respondent-form.component';
import { ListParametersComponent } from './components/list-parameters/list-parameters.component';
import { CreateParametersComponent } from './components/create-parameters/create-parameters.component';
import { EditParametersComponent } from './components/edit-parameters/edit-parameters.component';
import { LoginComponent } from './components/login/login.component';
import { ModalComponent } from './components/_presenters/modal-otp/modal.otp.component';
import { GenericComboboxComponent } from './components/_presenters/generic-combobox/generic-combobox.component';
import { PingMiddlewareComponent } from './components/ping-middleware/ping-middleware.component';
import { ModalPrivacyPoliciesComponent } from './components/_presenters/modal-privacy-policies/modal-privacy-policies.component';
import { TeamComponent } from './components/team/team.component';
import { PrivacyPoliciesComponent } from './components/privacy-policies/privacy-policies.component';
import { MedicalDashboardComponent } from './components/medical-dashboard/medical-dashboard.component';
import { ZoomMeetingComponent } from './components/zoom-meeting/zoom-meeting.component';
import { ModalMeetingsComponent } from './components/_presenters/modal-meetings/modal-meetings.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { ModalFarewellComponent } from './components/_presenters/modal-farewell/modal-farewell.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { FooterComponent } from './components/footer/footer.component';
import { ExternalServicesComponent } from './components/external-services/external-services.component';
import { MeetingConfirmationComponent } from './components/meeting-confirmation/meeting-confirmation.component';
import { ModalMeetConfComponent } from './components/_presenters/modal-meet-conf/modal-meet-conf.component';
import { ModalMessageComponent } from './components/_presenters/modal-message/modal-message.component';
import { MatTabsModule} from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DiagnostedComponent } from './components/diagnosted/diagnosted.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModalLoginComponent } from './components/_presenters/modal-login/modal-login.component';
import { ModalAlertComponent } from './components/_presenters/modal-alert/modal-alert.component';
import { ModalOtpCheckComponent } from './components/_presenters/modal-otp-check/modal-otp-check.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { ModalEditPereodicFormsComponent } from './components/_presenters/modal-edit-pereodic-forms/modal-edit-pereodic-forms.component';
import { MatListModule} from '@angular/material/list'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ModalCreateUSerComponent } from './components/_presenters/modal-create-user/modal-create-user.component';
import { MyQrComponent } from './components/my-qr/my-qr.component';
// import { ModelEditPeriodicFormsComponent } from './global/components/_presenters/model-edit-periodic-forms/model-edit-periodic-forms.component';
import { QRCodeModule } from 'angularx-qrcode';
import { GuardPageComponent } from './components/guard-page/guard-page.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import {MatSortModule} from '@angular/material/sort';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import { ModalConfirmDataComponent } from './components/_presenters/modal-confirm-data/modal-confirm-data.component';
import {MatSelectModule} from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { ModalSelectMyQRComponent } from './components/_presenters/modal-select-my-qr/modal-select-my-qr.component';
import {MatCardModule} from '@angular/material/card';
import { ModalPreviewFormRespondentComponent } from './components/_presenters/modal-preview-form-respondent/modal-preview-form-respondent.component';
import { FormRespodentComponent } from './components/form-respodent/form-respodent.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalPdfViewComponent } from './components/_presenters/modal-pdf-view/modal-pdf-view.component';
import { NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { FinalizarPageComponent } from './components/finalizar-page/finalizar-page.component';
import { PatientConsultsComponent } from './components/patient-consults/patient-consults.component';
import { ChartsModule } from "ng2-charts";
import { GoogleChartsModule } from 'angular-google-charts';
import { CreatePatientComponent } from './components/patient/create-patient/create-patient.component';
import { FrequencyPipe } from './pipes/frequency.pipe';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ExternalAuthenticationComponent } from './components/external-authentication/external-authentication.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { AlarmasFormsComponent } from './components/alarmas/alarmas-forms/alarmas-forms.component';
import { AlarmCardComponent } from './components/alarmas/alarm-card/alarm-card.component';
import { AlarmViewComponent } from './components/alarmas/alarm-view/alarm-view.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CreateSurgiaiPatientComponent } from './components/patient/create-surgiai-patient/create-surgiai-patient.component';
import { ListSpecialistFormsComponent } from './components/list-specialist-forms/list-specialist-forms.component';
import { AudioDataViewComponent } from './components/_presenters/audio-data-view/audio-data-view.component';


@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        NavbarComponent,
        PreviewFormsComponent,
        TextNumberComponent,
        TextareaComponent,
        ComboboxComponent,
        RadioComponent,
        CheckboxComponent,
        RadioComplementComponent,
        CheckboxComplementComponent,
        EmailComponent,
        TelComponent,
        ImageComponent,
        DateComponent,
        FileComponent,
        FormBuilderDirective,
        ListFormsComponent,
        CreateFormsComponent,
        EditFormsComponent,
        ListSettingFormsComponent,
        CreateSettingFormComponent,
        EditSettingFormComponent,
        ListCustomerFormsComponent,
        CreateCustomerFormComponent,
        EditCustomerFormComponent,
        ListRespondentFormsComponent,
        CreateRespondentFormComponent,
        EditRespondentFormComponent,
        ListParametersComponent,
        CreateParametersComponent,
        EditParametersComponent,
        LoginComponent,
        ModalComponent,
        GenericComboboxComponent,
        PingMiddlewareComponent,
        ModalPrivacyPoliciesComponent,
        TeamComponent,
        PrivacyPoliciesComponent,
        MedicalDashboardComponent,
        ZoomMeetingComponent,
        ModalMeetingsComponent,
        FormatDatePipe,
        ModalFarewellComponent,
        FooterComponent,
        ExternalServicesComponent,
        MeetingConfirmationComponent,
        ModalMeetConfComponent,
        ModalMessageComponent,
        DiagnostedComponent,
        ModalLoginComponent,
        ModalAlertComponent,
        ModalOtpCheckComponent,
        ModalEditPereodicFormsComponent,
        ModalCreateUSerComponent,
        MyQrComponent,
        GuardPageComponent,
        ModalConfirmDataComponent,
        ModalSelectMyQRComponent,
        ModalPreviewFormRespondentComponent,
        FormRespodentComponent,
        ModalPdfViewComponent,
        FinalizarPageComponent,
        PatientConsultsComponent,
        CreatePatientComponent,
        FrequencyPipe,
        ExternalAuthenticationComponent,
        LoadingScreenComponent,
        AlarmasFormsComponent,
        AlarmCardComponent,
        AlarmViewComponent,
        CreateSurgiaiPatientComponent,
        ListSpecialistFormsComponent,
        AudioDataViewComponent,
        // ModelEditPeriodicFormsComponent,    
    ],
    imports: [
        GoogleChartsModule,
        ChartsModule,
        NgxExtendedPdfViewerModule,
        PdfViewerModule,
        MatCardModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatSortModule,
        MatRadioModule,
        ZXingScannerModule,
        QRCodeModule,
        TextFieldModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatMenuModule,
        MatListModule,
        MatSnackBarModule,
        NgOtpInputModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatButtonModule,
        MatDialogModule,
        MatGridListModule,
        MatToolbarModule,
        MatTabsModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DragDropModule,
        NgbModule, MatDatepickerModule, MatNativeDateModule, MatRippleModule,
        AgGridModule.withComponents([]),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule
    ],
    providers: [
        AuthGuard,
        NotAuthGuard,
        DatePipe
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
