<div class="imgBackGround"  >
    <img  src="../../../assets/img/logo.png" alt="logo">
  </div>
  
  <p class="titel_form">Formularios de pacientes</p>
  <!-- <div>
    <button class="button" (click)="prueba()"> prueba </button>
  </div> -->
  
  <a class="btn btn-info" role="button" [routerLink]="['/create-respondent-form']" *ngIf="dataSesion.role==='admin'">Crear</a>
  
  
  
  <!-- <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    
  </table> -->
  
  <mat-tab-group>
    <mat-tab label="Nuevos formularios" *ngIf="dataSesion.logical_medical != 'cardiology'">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Paciente</th>
              <th>Formulario</th>
              <th>Fecha</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let form of filterAndChangeCreateOnhold">
              <td>{{form.doc_id}}</td>
              <td>{{form.name}}</td>
              <td>{{ form.activation_date * 1000 | date: 'dd/MM/YYYY'}}</td>    
              <!-- <td *ngIf="!form.answered_check; then answerForm else formFilled"></td> -->
              <!-- <ng-template #answerForm><td><a class="btn btn-info btn_ansFin" role="button" [routerLink]="['/edit-respondent-form', form.respondents_id]">Responder</a></td></ng-template>
              <ng-template #formFilled><td><a class="btn btn-info btn_ansFin disabled" role="button" [routerLink]="['/edit-respondent-form', form.respondents_id]" style="background: #00669A;">Terminado</a></td></ng-template> -->
              
              <div *ngIf="dataSesion.logical_medical !='urology'">
                <div *ngIf="form.status!='onhold'" ><td><a class="btn btn-info btn_ansFin" role="button" [routerLink]="['/edit-respondent-form', form.dfh_id]">Responder</a></td></div>
                <div *ngIf="form.status=='onhold'"><td><a class="btn btn-info btn_ansFin disabled" role="button" [routerLink]="['/edit-respondent-form', form.respondents_id]" style="background: #00669A;">Terminado</a></td></div>
              </div>
  
              <div *ngIf="dataSesion.logical_medical =='urology'" class="text-center">
                <div *ngIf="form.status=='onhold/onhold'|| form.status=='onhold/answered'" ><td><a class="btn btn-info btn_ansFin" role="button" [routerLink]="['/edit-respondent-form', form.dfh_id]">Responder</a></td></div>
                <!-- <div *ngIf="form.status!='onhold/onhold' || form.status!='onhold/answered'"><td><a class="btn btn-info btn_ansFin disabled" role="button" [routerLink]="['/edit-respondent-form', form.respondents_id]" style="background: #00669A;">Terminado</a></td></div> -->
              </div>
            
      
            </tr>
          </tbody>
        </table>
      </div>    
    </mat-tab>
    <mat-tab label="Contestados"  *ngIf="dataSesion.logical_medical != 'cardiology'">
      <div class="table-responsive">    
        <table class="table">
          <thead>
            <tr>
              <th>Paciente</th>
              <th>Formulario</th>
              <th>Fecha</th>
              <th>Visualizar</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let form of filterAndChangeCreateRespondent">
              <td>{{form.doc_id}}</td>
              <td>{{form.name}}</td>
              <td>{{ form.activation_date * 1000 | date: 'dd/MM/YYYY'  }}</td>        
                <div *ngIf="form.status=='answered'"><td><a class="btn btn-info btn_ansFin disabled" role="button" [routerLink]="['/edit-respondent-form', form.respondents_id]" style="background: #00669A;">Terminado</a></td></div>
                <td><a class="btn btn-info btn_ansFin " (click)="previewFormRespondentDialog(form.dfh_id)" role="button" style="color: #ffffff;">Ver formulario</a></td>   
            </tr>
          </tbody>
        </table>
      </div>    
    </mat-tab>

  </mat-tab-group>
  