<mat-card [ngClass]="{'completed': this.alarm.status == 'Resuelta'}" [class]="this.alarm.calification">

    <div class="info-patient-alert">
        <h2>{{ this.alarm?.pat_name != 'undefined' ? this.alarm?.pat_name : this.alarm?.pat_name_code}}</h2>
        <p>{{ ((this.alarm.checked_date.toString() != 'null') ? this.alarm.checked_date : this.alarm.date) | date: 'dd/MM/YYYY HH:mm'}}</p>
    </div>

    <div class="detail-section-alarm">
        <div class="keywords-alarm">{{this.alarm.keywords}}</div>
        <div class="form-name-alarm">Formulario {{this.alarm.form_name}}</div>
    </div>

    <div class="action-section-alarm">
        <a [routerLink]="['/alarmas',this.alarm.id]" mat-icon-button><mat-icon>arrow_forward</mat-icon></a>
    </div>

</mat-card>