<div *ngIf="mobilUI" style="background: #053890; width: 110%; height: 110%; position: absolute; z-index: -11111;"></div>    

<nav class="sidebar_collapse" id="sidebar">
  <div class="sidebar-header">    
      <img src="../../../assets/img/logo.png" alt="logo" style="width: 100px; margin-left: 50px;">
  </div>

  <ul class="list-unstyled components">

      <li [routerLinkActive]="['active']" *ngIf="dataSesion.role === 'admin'">
          <a mat-flat-button [routerLink]="['list-forms']">Formularios</a>
      </li>

      <li [routerLinkActive]="['active']" *ngIf="dataSesion.role === 'admin'">
          <a mat-flat-button [routerLink]="['list-setting-form']">Configuración Form.</a>
      </li>

      <li [routerLinkActive]="['active']" *ngIf="dataSesion.role === 'admin'">
          <a mat-flat-button [routerLink]="['list-customer-form']">Formularios clientes</a>
      </li>

      <li *ngIf="dataSesion.role === 'specialist' && dataSesion.logical_medical != 'urology'  && dataSesion.logical_medical != 'preconsult'">
        <a mat-flat-button (click)="openModal()">Crear un nuevo formulario</a>       
      </li>

      <li *ngIf="(dataSesion.role === 'specialist' && dataSesion.logical_medical == 'urology') || (dataSesion.role === 'doctor' && dataSesion.logical_medical == 'urology') || (dataSesion.role === 'doctor' && dataSesion.logical_medical != 'urology' && dataSesion.logical_medical != 'surgiai')" >
        <a mat-flat-button routerLinkActive="menu-item-selected" [routerLink]="['/medical-dashboard']">Tablero General</a>
      </li>
      
      <li *ngIf="dataSesion.logical_medical == 'surgiai'" >
        <a mat-flat-button routerLinkActive="menu-item-selected" [routerLink]="['/list-specialist-forms']">Tablero General</a>
      </li>

      <li *ngIf="(dataSesion.role === 'specialist' || dataSesion.role === 'doctor') && (dataSesion.logical_medical == 'preconsult')" >
        <a mat-flat-button routerLinkActive="menu-item-selected" [routerLink]="['/patient','new']">Crear un nuevo paciente</a>       
      </li>

      <li *ngIf="(dataSesion.role === 'specialist' || dataSesion.role === 'doctor') && (dataSesion.logical_medical == 'surgiai')" >
        <a mat-flat-button routerLinkActive="menu-item-selected" [routerLink]="['/patient','new-surgiai']">Crear un nuevo paciente</a>       
      </li>
       
      <li  *ngIf="(dataSesion.role === 'specialist' || dataSesion.role === 'doctor') && dataSesion.logical_medical == 'urology'">
        <a (click)="openModal()">Crear un nuevo paciente</a>       
      </li>  


      <li *ngIf="(dataSesion.role === 'specialist' || dataSesion.role === 'doctor') && (dataSesion.logical_medical == 'preconsult' || dataSesion.logical_medical == 'surgiai')" >
        <a mat-flat-button routerLinkActive="menu-item-selected" [routerLink]="['/alarmas']">Alarmas Formularios</a>       
      </li>
      
      <li  *ngIf="dataSesion.role === 'specialist' && dataSesion.logical_medical == 'urology' ||dataSesion.role === 'doctor' && dataSesion.logical_medical == 'urology'">
        <a mat-flat-button [routerLink]="['/patient-consults']">Consulta pacientes</a>       
      </li>


      <li *ngIf="(dataSesion.role === 'doctor' || dataSesion.role === 'specialist') && dataSesion.logical_medical == 'urology'">
        <a mat-flat-button [routerLink]="['/finalizar-page']">Finalizados</a>       
      </li>

      <li [routerLinkActive]="['active']"  *ngIf="dataSesion.role === 'customer' || dataSesion.role === 'respondent' ">
        <a mat-flat-button [routerLink]="['list-respondent-form']">Formularios pacientes</a>
      </li>

      <li *ngIf="dataSesion.role === 'customer' || dataSesion.role === 'respondent' ">
        <a mat-flat-button [routerLink]="['/my-qr']">Mi código QR <mat-icon>qr_code</mat-icon></a>
      </li>

      <!-- <li *ngIf="dataSesion.role != 'doctor' && dataSesion.logical_medical != 'urology'" [routerLink]="['/team']" routerLinkActive="router-link-active" >
        <a  style="display: block;">Conoce a nuestro equipo</a>
      </li> -->
  </ul>

  <label>v1.1.12</label>  

</nav>
