import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  updates: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  updateSpinnerState(state: boolean) : void {
    this.updates.emit(state);
  }

}
