

import { ActivatedRoute, Router } from '@angular/router';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { Component, OnInit, Output, EventEmitter, HostListener,Inject  } from '@angular/core';
import { FomrsService } from '../../services/fomrs.service';
import { ModalAlertComponent } from '../_presenters/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { MeetingService } from '../../services/meeting.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-form-respodent',
  templateUrl: './form-respodent.component.html',
  styleUrls: ['./form-respodent.component.css']
})
export class FormRespodentComponent implements OnInit {
  private form: any;
  private currentTab: number;
  private tabs: any;
  private id: number;
  public diagnosis: string;
  public pages: any;
  public respondentForm:any;
  public structure: any;
  public segments: string[];
  public mdlMessage: any;
  public titleMessage: string;
  public message: string;
  public select = null;
  public warnin =false ;
  public counter: any;
  public value ;
  public dataConfMeet: any;
  public date;
  public minDate: Date;
  public maxDate: Date;
  public resultWeight = '';
  public dataSesion;
  
  
  @Output() AddRmvAnswered = new EventEmitter();

  @HostListener('change', ['$event'])
  onChange(event: any): void {    
    ////console.log('eventto',event);
    // ////console.log('clickCheckDate', this.date); 
    if(event.target.id=='anamnesis'){
      this.diagnosis = event.target.value
      ////console.log('entro y cambio ngmoel diagnosis')
    }
   this.structure.map(function(dato){
     ////console.log('DatosMapeados',dato)
    for (let id = 0; id < dato.pages.length; id++){
      let data = dato.pages[id] 
      let filterByData = data.filter(element => element.type == 'Number'|| element.type == 'Email' || element.type == 'Text'|| element.type == 'Textarea' ); 
     
      // ////console.log('filterByNumber ', filterByNumber );      
      for (let i = 0; i < filterByData.length; i++) {
        if( filterByData[i].name === event.path[0].name){
          //////console.log('For-answer', filterByRadio[i].answers[0])            
           if(filterByData[i].answers[0] !== event.target.value){
            filterByData[i].answers[0] = event.target.value;
            // ////console.log('Se agrego',event.target.value) 
           }
        }
      }       
      let filterByCheckBox = data.filter(element => element.type == 'Checkbox')
      //  ////console.log('filterByCheckBox ', filterByCheckBox )
       for (let i = 0; i < filterByCheckBox.length; i++) {
        if( filterByCheckBox[i].name === event.path[0].name){ 
          let found = filterByCheckBox[i].answers.findIndex(element => element == event.path[0].defaultValue);
          // ////console.log('index que coincide', found);
          if(found == -1 ){
            if(event.path[0].defaultValue==='Ninguno'|| event.path[0].defaultValue==='Ninguna' && event.target.checked==true){                 
              filterByCheckBox[i].answers.splice(0, filterByCheckBox[i].answers.length)
              // ////console.log('Nunguna',filterByCheckBox[i]);              
            }else{
              filterByCheckBox[i].answers.push(event.path[0].defaultValue)
              // ////console.log('ValueFilterCheck',filterByCheckBox[i]);
            }                
          }
          else{                
            // ////console.log('ValueFilterCheck',filterByCheckBox[i].answers[found],found);
            let idValue
            if(found=== 0){
              idValue = 0
            }else{
              idValue = found - 1;
            }
            filterByCheckBox[i].answers.splice(idValue,1) 
            event.target.checked = false               
          }          
        }       
     } 

     let filterByFile = data.filter(element =>  element.type == 'File'); 
     ////console.log('filterByFile',filterByFile)
    }
    return dato;
   });
  }

  @HostListener('click', ['$event'])
  onClick(event: any): void {     
    // ////console.log('clickCheck', event); 
    this.structure.map(function(dato){ // pasar valor a comparar 
      //  ////console.log('array', dato.pages[0])
      //  ////console.log('Clickeado', event)
       for (let id = 0; id < dato.pages.length; id++){
        let data = dato.pages[id]
        let filterByRadio = data.filter(element => element.type == 'Radio') 
        // ////console.log('filterByRadio', filterByRadio)  
           for (let i = 0; i < filterByRadio.length; i++) {
              if( filterByRadio[i].name === event.path[0].name){
                //////console.log('For-answer', filterByRadio[i].answers[0])            
                 if(filterByRadio[i].answers[0] !== event.path[0].defaultValue){
                  filterByRadio[i].answers[0] =  event.path[0].defaultValue;
                  // ////console.log('Se agrego',event.path[0].defaultValue,'por', filterByRadio[i].answers[0]) 
                 }
              }       
           }             
       }     
     return dato;
  }); 
  // ////console.log(' this.structure nueva', this.structure[2].pages[0][0]) para pruebas con checkboxlocalho
  ////console.log(' this.structure nueva',  this.structure[5].pages[0][2])
  }

  constructor(
    public dialogRef: MatDialogRef <FormRespodentComponent>,
    @Inject(MAT_DIALOG_DATA)public data:any,
    private fomrsService:FomrsService,
    private respondentFormsService: RespondentFormsService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public meetingsService: MeetingService,
    private authService: AuthService
  ) 
  {
    this.dataSesion = this.authService.getDataSesion()
    this.minDate = new Date(2020, 0, 1);
    this.maxDate = new Date();

    this.dataConfMeet = {
      type: 'status_diagnosed',
      id: '',
      status: 'attended'
    };           
    
    this.form = document.getElementById('editRespondentForm');
    this.tabs = document.getElementsByClassName('tab');
    this.pages = [];
    this.currentTab = 0;
    this.respondentForm = {
      dfh_id: null,
      name: null,
      description: null,
      customer_id: null,
      structure: null,
      answered_check: false,
      respondents_id: null,
      custf_id: null,
      created_on: null,
      diagnostic_detail: null, 
      status: null,
      total_weight_patient:'',
      hash_patient:'',
      result_patient:''     
    };
    this.titleMessage = '';
    this.message = '';
  }

  addRmvAnswered(counter: number, lblName: string): void {
    let answered = false;

    if (counter !== 0) {
      answered = true;
    }

    this.AddRmvAnswered.emit({
      question: lblName,
      answered
    });
    ////console.log( 'this.AddRmvAnswered', this.AddRmvAnswered)
  }

  ngOnInit(): void {
    // const { id } = this.rutaActiva.snapshot.params;
    this.id = this.data;
    this.getByIdRespForm(this.id);
    this.validateSessionApp();
    // this.dataConfMeet.id = this.rutaActiva.snapshot.params;
    // this.getMeet();
   
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }
  


  getMeet(){
    this.meetingsService.getMeetings().subscribe(resp=>{
      ////console.log('respuestaGetMeet',this.rutaActiva.snapshot.params);  
      let id = this.rutaActiva.snapshot.params;
      let data= resp.resp
      let dataFilter = data.filter(element => element.dfh_id == id.id && element.status != 'rescheduled')
      ////console.log('respuestaGetMeet',dataFilter[0].mt_id);  
      this.dataConfMeet.id = dataFilter[0].mt_id 
    }),erro=>{}////console.log('error',erro);
    
  }

  getByIdRespForm(id): any {
    this.respondentFormsService.getByIdRespForm(id).subscribe(
      resp => {
        if (resp) {
          this.respondentForm = resp.resp[0];
          this.structure = this.respondentForm.structure;      
          console.log(' this.respondentForm ',  this.respondentForm )    
          this.calculatorByAnswer(this.structure);
          if(this.dataSesion.logical_medical=='urology') {
            this.calculatorByAnswer(this.structure);
          } 
          //Guardar cambios en este array  
        }
      },
      error => {
        ////console.log(error);
      }
    );
  }

    updateRespForm(): void {
      this.respondentForm['diagnosis'] = this.diagnosis;
      this.respondentForm['diagnostic_detail'] = JSON.stringify(this.structure);
      // this.respondentForm['type']= 'diagnosted'
      //console.log('this.respondentForm',this.respondentForm)      
      this.respondentFormsService.updateRespForm(this.respondentForm,'diagnosed').subscribe(
        resp => { 
          ////console.log(':::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::')       
          ////console.log(resp);         
          if (resp.correct) {
            this.updateMeeting()
            //console.log(resp.resp);
            this.titleMessage = 'Diagnóstico';
            this.message = 'Diagnóstico guardado';
            this.openAlertModal()
          }
        },
        error => {
          ////console.log(error);
        }
      );     
    }

    updateMeeting(){   
      this.meetingsService.confirmMeeting(this.dataConfMeet).subscribe(resp => {
        ////console.log('resp', resp)
      });
    }

    onSubmit(): any {
    
      if(this.select== null){
        
          this.warnin=true;      
      }else{
       
        this.warnin=false;
        let answerNum: number = 0;
        this.form = document.getElementsByClassName('presenter');  
        // ////console.log('this.form',this.form )
        this.structure.forEach( async (est: any, i) => {
          est.pages.forEach((pg, j) => {
            pg.forEach( async (q, n) => {
              let arrAux: any = [];
              let lengthQ = 1;  
              switch (q.type) {
                case 'Radio':
                case 'Checkbox':
                    lengthQ = q.items.length;
                    // arrAux = this.answerBuilder(lengthQ, answerNum);
                  break;
                default:
                    // this.diagnosis = this.form[answerNum].value;
                    // arrAux.push(this.diagnosis);
              }  
              // this.structure[i].pages[j][n]['diagnosis'] = arrAux;
              answerNum = answerNum + lengthQ;
            });
          });
        });  
        // const diagnosis: any = document.getElementById('diagnosis');
        const presumption: any = document.getElementById('presumption');
  
        this.respondentForm.custf_id = this.id;
        this.respondentForm.structure = this.structure;
        this.respondentForm['presumption'] = presumption.value;
        this.respondentForm['diagnosis'] = this.diagnosis;
        this.respondentForm['status'] = 'diagnosed';  
        this.updateRespForm();
      }
    }

    openMdlMeeting(): void {
      this.upTop();
      this.mdlMessage = document.getElementById('mdlMessage');
      this.mdlMessage.style.display = 'block';
    }

    closeMdlMessage(): void {
      this.mdlMessage = document.getElementById('mdlMessage');
      this.mdlMessage.style.display = 'none';
      this.router.navigate(['medical-dashboard']);
    }

    upTop(): void {
      window.scrollTo(0, 0);
    }

    openAlertModal(): void {
      const dialogRef = this.dialog.open(ModalAlertComponent,{
        width:'350px',
        data:  this.message,
        disableClose: true
      });    
      dialogRef.afterClosed().subscribe(res=>{    
        ////console.log(res);
        this.router.navigate(['medical-dashboard']);                  
      })    
     }

     SeleccionDeDataPicker(even){
       ////console.log('this.date',even) //para recuperar el valor recuperado del dataPicker.
       ////console.log('this.id',even.targetElement.id) //para identificar el nombre de la pregunta.

       this.structure.map(function(dato){
        for (let id = 0; id < dato.pages.length; id++){
          let data = dato.pages[id] 
          let filterByDatapiker = data.filter(element => element.type == 'Datepicker'); 
          for (let i = 0; i < filterByDatapiker.length; i++) {
            if( filterByDatapiker[i].name == even.targetElement.id){
              //////console.log('For-answer', filterByRadio[i].answers[0])            
               if(filterByDatapiker[i].answers[0] !== even.targetElement.value){
                filterByDatapiker[i].answers[0] = even.targetElement.value
                 ////console.log('Se agrego', even.targetElement.value) 
               }
            }
          }
        }
        return dato
      })

     }
     closeModal(){
       this.dialogRef.close();
     }

     calculatorByAnswer(dataa){
      let array =[];
      let id8Value;
      
      dataa.map(function(dato){
        let filterByRolPatient = dataa.filter(element => element.role== "respondent" || element.role== "3")
        console.log('DatosMapeados',filterByRolPatient);        
        for (let id = 0; id < filterByRolPatient[0].pages.length; id++){
          let data = filterByRolPatient[0].pages[id];         
          console.log('for de pages',data);
          for(let i=0;i < data.length; i++){
          let id8answers = data[7].answers[0];         
            let indexAnswers = data[i];    
            let dataAnswerExtract =indexAnswers.answers;       
            let items = indexAnswers.items
            let weight =    indexAnswers.weight;
            //saco el valor de id8
            let finIndex7= data[7].items.findIndex(element=>element==id8answers);
            //console.log('finIndex7',finIndex7)
            id8Value = data[7].weight[finIndex7];
            for(let id = 0; id < dataAnswerExtract.length; id++) {
              let dataAnswer = dataAnswerExtract[id];
              let findByIndex = items.findIndex(element=>element == dataAnswer );
              //console.log('respuestaIndex', findByIndex);
              if(weight[findByIndex] != undefined && findByIndex != -1){         
                                    
                    array.push(parseInt(weight[findByIndex]));
                    
                    //console.log('Datos en array', array);  
                                      
                                    
              }
            }      
          
          }
        //  let filterByData = data.filter(element => element.type == 'Number'|| element.type == 'Email' || element.type == 'Text'|| element.type == 'Textarea' );             

        }
      
        return dato;
      });   
      
     let total=0;
     for(let i of array) total+=i;  
     
     let val8=parseInt(id8Value);
     let totoal=total/2 - val8;   
     this.resultWeight = totoal+ '-' +id8Value;
     //console.log('*********totalPesos',this.resultWeight);
     this.respondentForm['result_patient']=this.resultWeight;
    }
}
